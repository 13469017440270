import React from "react";
import { connect } from "react-redux";
import { Route, Redirect, Switch } from "react-router-dom";
import { PrivateRoute } from "components";
import {
	Dashboard,
	Login,
	RecoverPassword,
	Staff,
	StaffLogs,
	Logs,
	Settings,
	AppSettings,
	AcceptInvite,
	Pages,
	EmailTemplates,
	PageDetail,
	EmailDetail,
	Error500,
	Banners,
	BannerDetail,
	Users,
	UserDetail,
	Categories,
	CategoryDetail,
	Partners,
	PartnerDetail,
	Shippings,
	Zones,
	Taxes,
	Faqs,
	FaqDetail,
	PartnerFaqs,
	PartnerFiles,
	Segments,
	SegmentDetail,
	ShippingDetail,
	ShippingMethod,
	Products,
	ProductDetail,
	Crm,
	CrmDetail,
	HighlightedSections,
	HighlightedSectionDetail,
	Files,
	FileDetail,
	PartnerTree,
	Orders,
	OrderDetail,
	OrderNew,
	Bonuses,
	SuccessLadder,
	PartnerKits,
	BinaryTree,
	Commissions,
	PartnerCommissions,
	CommissionDetail,
	Manuals,
	ManualDetail,
	PartnerManuals,
	Ethics,
	EthicDetail,
	PartnerEthics,
	Incentives,
	PartnerWallet,
	Wallet,
	CommissionSales,
	CommissionPartners,
	CommissionsRepass,
	CommissionPerformance,
	PartnersCommissionPerformance,
	CommissionBinary,
	PartnersCommissionBinary,
	CommissionDepth,
	CommissionIncentives,
	PartnersIncentives,
	CommissionAdjustments,
	PartnersAdjustments,
	CommissionSpecial,
	PartnersSpecial,
	PartnersCommissionSales,
	PartnersCommissionPartners,
	PartnersCommissionRepass,
	PartnersCommissionDepth,
	PartnerPendingRegistration
} from "screens";

export const offlinePages = ["/login", "/register", "/accept-invite", "/recover-password", "/confirm"];
export const localType: "admin" | "distributor" = "distributor";
export const adminSubDomains = ["admindev", "admin", "bodev", "bo"];
export const distributorSubDomains = ["partnerdev", "partner"];

export class Routes extends React.Component<any, any> {
	shouldComponentUpdate(nextProps: any) {
		const { user, token } = this.props;
		const isLoggedIn = Boolean(user && token);
		const willBeLoggedIn = Boolean(nextProps.user && nextProps.token);

		return isLoggedIn !== willBeLoggedIn || JSON.stringify(user) !== JSON.stringify(nextProps.user);
	}

	componentDidUpdate() {
		const elem = document.getElementById("app_content");

		if (elem) {
			elem.scrollTop = 0;
		}
	}

	render() {
		const { token, user } = this.props;
		const hostname = window.location.hostname?.split(".")?.[0];
		const isSysAdmin = user?.role === "sysadmin";
		const userIsAdmin = user?.role === "admin" || user?.role === "sysadmin" || false;
		const isAdmins =
			((hostname === "localhost" || window.location.hostname === "192.168.1.36") && localType === "admin") ||
			adminSubDomains.includes(hostname);
		const isDistributors =
			((hostname === "localhost" || window.location.hostname === "192.168.1.36") && localType === "distributor") ||
			distributorSubDomains.includes(hostname);

		const adminRoutes = [
			{ path: "/accept-invite/:id/:code", component: AcceptInvite },
			{ path: "/banners", component: Banners },
			{ path: "/banners/:id", component: BannerDetail },
			{ path: "/highlighted-sections", component: HighlightedSections },
			{ path: "/highlighted-sections/:id", component: HighlightedSectionDetail },
			{ path: "/categories", component: Categories },
			{ path: "/categories/:id", component: CategoryDetail },
			{ path: "/commissions", component: Commissions },
			{ path: "/commissions/view/:id", component: CommissionDetail },
			{ path: "/commissions/sales", component: CommissionSales },
			{ path: "/commissions/partners", component: CommissionPartners },
			{ path: "/commissions/repass", component: CommissionsRepass },
			{ path: "/commissions/annual", component: CommissionPerformance },
			{ path: "/commissions/binary", component: CommissionBinary },
			{ path: "/commissions/depth", component: CommissionDepth },
			{ path: "/commissions/special", component: CommissionSpecial },
			{ path: "/commissions/incentives", component: CommissionIncentives },
			{ path: "/commissions/adjuncts", component: CommissionAdjustments },
			{ path: "/clients", component: Users },
			{ path: "/clients/:id", component: UserDetail },
			{ path: "/dashboard", component: Dashboard },
			{ path: "/crm", component: Crm },
			{ path: "/crm/:id", component: CrmDetail },
			{ path: "/orders", component: Orders },
			{ path: "/orders/new", component: OrderNew },
			{ path: "/orders/:id", component: OrderDetail },
			{ path: "/partners", component: Partners },
			{ path: "/products", component: Products },
			{ path: "/products/:id", component: ProductDetail },
			{ path: "/segments", component: Segments },
			{ path: "/segments/:id", component: SegmentDetail },
			{ path: "/sponsor-tree", component: PartnerTree },
			{ path: "/genealogical-tree", component: BinaryTree },
			{ path: "/partners/:id", component: PartnerDetail },
			{ path: "/logs", component: Logs, sysadminOnly: true },
			{ path: "/settings", component: Settings },
			{ path: "/settings/app-settings", component: AppSettings, sysadminOnly: true },
			{ path: "/settings/bonuses", component: Bonuses },
			{ path: "/settings/email-templates", component: EmailTemplates },
			{ path: "/settings/email-templates/:id", component: EmailDetail },
			{ path: "/settings/ethics", component: Ethics },
			{ path: "/settings/ethics/:id", component: EthicDetail },
			{ path: "/settings/faqs", component: Faqs },
			{ path: "/settings/faqs/:id", component: FaqDetail },
			{ path: "/settings/files", component: Files },
			{ path: "/settings/files/:id", component: FileDetail },
			{ path: "/settings/incentives", component: Incentives },
			{ path: "/settings/manuals", component: Manuals },
			{ path: "/settings/manuals/:id", component: ManualDetail },
			{ path: "/settings/pages", component: Pages },
			{ path: "/settings/pages/:id", component: PageDetail },
			{ path: "/settings/shippings", component: Shippings },
			{ path: "/settings/shippings/:id", component: ShippingDetail },
			{ path: "/settings/shippings/:id/methods/:methodId", component: ShippingMethod },
			{ path: "/settings/success-ladder", component: SuccessLadder },
			{ path: "/settings/taxes", component: Taxes },
			{ path: "/settings/zones", component: Zones },
			{ path: "/staff/list", component: Staff, adminOnly: true },
			{ path: "/staff/logs", component: StaffLogs, adminOnly: true },
			{ path: "/wallets", component: Wallet },
			{ path: "/500", component: Error500 }
		];

		const partnerRoutes = [
			{ path: "/accept-invite/:id/:code", component: AcceptInvite },
			{ path: "/partner-clients", component: (props: any) => <Users {...props} partners /> },
			{ path: "/partner-clients/:id", component: (props: any) => <UserDetail {...props} partners /> },
			{ path: "/crm", component: (props: any) => <Crm {...props} partners /> },
			{ path: "/crm/:id", component: (props: any) => <CrmDetail {...props} partners /> },
			{ path: "/partner-commissions", component: PartnerCommissions },
			{ path: "/partner-commissions/sales", component: PartnersCommissionSales },
			{ path: "/partner-commissions/partners", component: PartnersCommissionPartners },
			{ path: "/partner-commissions/repass", component: PartnersCommissionRepass },
			{ path: "/partner-commissions/annual", component: PartnersCommissionPerformance },
			{ path: "/partner-commissions/binary", component: PartnersCommissionBinary },
			{ path: "/partner-commissions/depth", component: PartnersCommissionDepth },
			{ path: "/partner-commissions/special", component: PartnersSpecial },
			{ path: "/partner-commissions/incentives", component: PartnersIncentives },
			{ path: "/partner-commissions/adjuncts", component: PartnersAdjustments },
			{ path: "/partner-dashboard", component: (props: any) => <Dashboard {...props} partners /> },
			{ path: "/partner-ethics", component: PartnerEthics },
			{ path: "/partner-faqs", component: PartnerFaqs },
			{ path: "/partner-files", component: PartnerFiles },
			{ path: "/partner-manuals", component: PartnerManuals },
			{ path: "/partner-orders", component: (props: any) => <Orders {...props} partners /> },
			{ path: "/partner-orders/new", component: (props: any) => <OrderNew {...props} partners /> },
			{ path: "/partner-orders/:id", component: (props: any) => <OrderDetail {...props} partners /> },
			{ path: "/partner-partners", component: (props: any) => <Partners {...props} partners /> },
			{ path: "/partner-partners/:id", component: (props: any) => <PartnerDetail {...props} partners /> },
			{ path: "/partner-sponsor-tree", component: (props: any) => <PartnerTree {...props} partners /> },
			{ path: "/partner-genealogical-tree", component: (props: any) => <BinaryTree {...props} partners /> },
			{ path: "/partner-wallet", component: PartnerWallet },
			{ path: "/500", component: Error500 }
		];

		const authRoutes = [
			{ path: "/accept-invite/:id/:code", component: AcceptInvite },
			{ path: "/login", component: Login },
			{ path: "/recover-password", component: RecoverPassword },
			{ path: "/recover-password/:id/:code", component: RecoverPassword },
			{ path: "/500", component: Error500 }
		];

		if (isAdmins && token) {
			return (
				<Switch>
					{adminRoutes.map((route) => {
						if (route.adminOnly && !userIsAdmin && !isSysAdmin) return null;
						if (route.sysadminOnly && !isSysAdmin) return null;

						return <PrivateRoute key={route.path} exact path={route.path} component={route.component} />;
					})}

					<Redirect to="/dashboard" />
				</Switch>
			);
		} else if (isDistributors && token) {
			if (user.partner?.confirmed && !user.partner?.inactive) {
				return (
					<Switch>
						{partnerRoutes.map((route) => (
							<PrivateRoute key={route.path} exact path={route.path} component={route.component} />
						))}
						<Redirect to="/partner-dashboard" />
					</Switch>
				);
			}

			if (user.partner?.pendingRegistration) {
				return (
					<Switch>
						<PrivateRoute exact path="/partner-pending-registration" component={PartnerPendingRegistration} />
						<PrivateRoute exact path="/500" component={Error500} />
						<Redirect to="/partner-pending-registration" />
					</Switch>
				);
			}

			return (
				<Switch>
					<PrivateRoute exact path="/partner-kits" component={PartnerKits} />
					<PrivateRoute exact path="/500" component={Error500} />
					<Redirect to="/partner-kits" />
				</Switch>
			);
		} else {
			return (
				<Switch>
					{authRoutes.map((route) => (
						<Route key={route.path} exact path={route.path} component={route.component} />
					))}
					<Redirect to="/login" />
				</Switch>
			);
		}
	}
}

const mapStateToProps = (state: any) => ({
	router: state.router,
	user: state.user,
	token: state.token
});

export default connect(mapStateToProps)(Routes);
