// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root{--primary-color: #00b5b0;--light-primary: #19f1eb;--secondary-color: #22a8dd;--light-secondary: #b8e4f0;--app-background-color: whitesmoke;--logo-container-background: #151515;--text-color: #333;--gradient: linear-gradient(180deg, #00b5b0 1.04%, #19f1eb 100%);--gradient-border: linear-gradient(90deg, #00b5b0 1.04%, #19f1eb60 100%)}.Error500_Meanwhile_Container{display:flex;flex-direction:column;align-items:center;margin-top:20px}.Error500_Meanwhile_Container div:last-child{margin-top:10px}.Error500_Meanwhile_Container .Error500_Option{display:flex;align-items:center;cursor:pointer}.Error500_Meanwhile_Container .Error500_Option:hover{color:#00b5b0}.Error500_Meanwhile_Container .Error500_Option span,.Error500_Meanwhile_Container .Error500_Option em{font-weight:700}@media screen and (max-width: 768px){.ant-result{padding:0 !important}}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#00b5b0",
	"lightPrimary": "#19f1eb",
	"secondaryColor": "#22a8dd",
	"lightSecondary": "#b8e4f0"
};
module.exports = exports;
