import React, { Component } from "react";
import { Table, notification, TableColumnsType } from "antd";
import { ContentWrapper, Icon } from "components";
import { DateTime } from "luxon";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { delayedDispatch, setBreadcrumb, setLoader, setTitle, updateCrumb } from "store/actions";
import { API, Endpoints } from "utils/api";
import strings from "utils/strings";
import { formatPrice, xlsxSettings } from "utils/utils";
import { push } from "connected-react-router";
import xlsx from "json-as-xlsx";
import moment from "moment";

class Adjustments extends Component<any, any> {
	searchTimeout: NodeJS.Timeout | undefined;

	constructor(props: any) {
		super(props);

		this.state = {
			data: [],
			startDate: moment().add({ months: -5 }).startOf("month"),
			endDate: moment().endOf("month"),
			partner: undefined,
			partnerOptions: [],
			partnerSearch: ""
		};
	}

	async componentDidMount(): Promise<void> {
		const { dispatch } = this.props;

		dispatch(setTitle(""));

		await this.getData();
		this.breadcrumb();
	}

	componentDidUpdate(): void {
		const { dispatch } = this.props;
		dispatch(updateCrumb());
	}

	async getData() {
		const { startDate, endDate, partner } = this.state;
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		const body = {
			startDate: startDate.toISOString(),
			endDate: endDate.toISOString(),
			type: "adjustments",
			partner
		};

		try {
			const response = await API.post({
				url: Endpoints.uriCommissions("search-by-type"),
				data: body
			});

			if (response.ok) {
				const { movements = [] } = response.data.results;
				this.setState({ movements });
			} else {
				notification.error({
					message: strings.commissions.adjuncts,
					description: response?.data?.message || strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: strings.serverErrors.title,
				description: err as string,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	handleSearch(partner: any) {
		this.setState({ partnerSearch: partner });

		if (partner.length < 3) return;

		if (this.searchTimeout) clearTimeout(this.searchTimeout);

		this.searchTimeout = setTimeout(async () => {
			this.getUsers();
		}, 500);
	}

	async getUsers() {
		const { partnerSearch } = this.state;

		try {
			const body = { search: partnerSearch, page: 0, perPage: 10 };

			const response = await API.post({
				url: Endpoints.uriPartners("search"),
				data: body
			});

			if (response.ok) {
				const { users = [] } = response.data.results || {};
				this.setState({ partnerOptions: users });
			} else {
				notification.error({
					message: strings.commissions.adjuncts,
					description: response?.data?.message || strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: strings.serverErrors.title,
				description: err as string,
				placement: "bottomRight",
				duration: 5
			});
		}
	}

	breadcrumb() {
		delayedDispatch(
			setBreadcrumb(() => {
				const { startDate, endDate, partnerOptions, partner } = this.state;

				return {
					locations: [
						{
							text: strings.commissions.adjuncts,
							icon: "organization"
						}
					],
					actions: [
						{
							type: "select",
							text: strings.fields.partner,
							placeholder: strings.generic.searchByEmailOrName,
							options: partnerOptions.map((partner: any) => ({
								value: partner._id,
								text: `${partner.name} (${partner.email})`
							})),
							onChange: (partner: any) => {
								this.setState({ partner }, () => this.getData());
							},
							value: this.state.partner,
							showSearch: true,
							onSearch: (value: string) => this.handleSearch(value),
							separator: "right",
							style: { width: 300, height: 40 },
							allowClear: true
						},
						{
							type: "datePicker",
							text: strings.dashboard.period,
							dates: [startDate, endDate],
							monthly: true,
							onChange: this.handleSelect,
							className: "fixedPicker",
							disabled: partner
						}
					]
				};
			})
		);
	}

	handleSelect = (dates: any) => {
		const newDates = [];
		if (dates && dates[0]) {
			newDates.push(moment(dates[0]).startOf("day"));
		}

		if (dates && dates[1]) {
			newDates.push(moment(dates[1]).endOf("day"));
		}

		this.setState(
			{
				startDate: newDates[0],
				endDate: newDates[1]
			},
			() => this.getData()
		);
	};

	exportData = () => {
		const { movements = [], partner, partnerOptions } = this.state;

		const columns = [
			{ label: "Data", value: (row: any) => DateTime.fromISO(row.date).toFormat("dd/MM/yyyy HH:mm") },
			{ label: "Notas", value: "notes" },
			{ label: "Valor", value: (row: any) => formatPrice(row.balance) }
		];

		if (!partner) {
			columns.splice(1, 0, { label: "Parceiro", value: (row: any) => `${row.partner.name} (#${row.partner.userCode})` });
		}

		const data = [
			{
				sheet: "Aditamentos",
				columns,
				content: movements
			}
		];

		let name = "Aditamentos";
		if (partner) {
			const partnerData = partnerOptions.find((elem: any) => elem._id === partner);
			if (partnerData) name = partnerData.name;
		}

		xlsx(data, xlsxSettings(name));
	};

	renderMovements() {
		const { movements = [], partner } = this.state;
		const { countries, dispatch } = this.props;

		const columns: TableColumnsType<any> = [
			{
				title: strings.fields.date,
				dataIndex: "date",
				key: "date",
				render: (date: string) => DateTime.fromISO(date).toFormat("dd/MM/yyyy HH:mm"),
				defaultSortOrder: "descend",
				sorter: (a, b) => DateTime.fromISO(a.date).toMillis() - DateTime.fromISO(b.date).toMillis(),
				width: 150
			},
			{
				title: strings.products.notes,
				dataIndex: "notes",
				key: "notes",
				render: (notes: string) => notes || "-",
				sorter: (a, b) => a.notes?.localeCompare(b.notes)
			},
			{
				title: strings.settings.value,
				dataIndex: "balance",
				key: "balance",
				render: (balance: number) => <span className="CommissionValueRed">{formatPrice(balance)}</span>,
				sorter: (a, b) => a.balance - b.balance,
				width: 150
			}
		];

		if (!partner) {
			columns.splice(1, 0, {
				title: strings.fields.partner,
				dataIndex: "partner",
				key: "partner",
				render: (partner: any) => {
					if (!partner) return "-";
					const countryCallingCode = partner.phone?.substring(0, 4).replace("+", "");
					let country;

					if (countryCallingCode) {
						country = countries.find((elem: any) => elem.callingCodes.includes(countryCallingCode))?.alpha2Code?.toLowerCase();
					}

					return (
						<span className="TableRowAligned">
							{country ? (
								<div className="react-tel-input">
									<div className="selected-flag">
										<span className={`flag ${country}`} />
									</div>
								</div>
							) : null}
							{partner.name}
							{Boolean(partner?.userCode) ? ` (#${partner.userCode})` : ""}
						</span>
					);
				},
				sorter: (a, b) => a.partner.name?.localeCompare(b.partner.name)
			});
		}

		return (
			<ContentWrapper>
				<div className="ScreenHeader --multi">
					<div className="ScreenHeaderLeft">
						<Icon name="diagram" />
						<h2>{strings.commissions.adjuncts}</h2>
					</div>
					<div className="ScreenHeaderRight">
						<button onClick={this.exportData} className="EthicAddButton">
							<Icon name="print" />
						</button>
						<button onClick={() => dispatch(push("/commissions/view/new"))} className="EthicAddButton">
							{strings.commissions.createNew}
						</button>
					</div>
				</div>
				<Table style={{ marginTop: 20 }} columns={columns} dataSource={movements} rowKey={(record) => record._id} />
			</ContentWrapper>
		);
	}

	render() {
		return (
			<div className="CommissionAdjuncts">
				<Helmet>
					<title>{strings.commissions.adjuncts}</title>
					<meta name="description" content="Description of adjuncts" />
				</Helmet>
				{this.renderMovements()}
			</div>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language,
	countries: state.countries
});

export default connect(mapStateToProps)(Adjustments);
