import React from "react";
import { notification } from "antd";
import Strings from "utils/strings";
import { delayedDispatch, setBreadcrumb, setLoader, setTitle, updateCrumb } from "store/actions";
import { API, Endpoints } from "utils/api";
import { Helmet } from "react-helmet";
import { Table } from "components";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { translate } from "utils/utils";

export class Shipping extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			shippings: [],
		};

		this.toggleProvider = this.toggleProvider.bind(this);
		this.deleteShipping = this.deleteShipping.bind(this);
	}

	componentDidMount() {
		const { dispatch } = this.props;
		this.getData();
		this.breadcrumb();
		dispatch(setTitle(""));
	}

	breadcrumb() {
		delayedDispatch(
			setBreadcrumb(() => {
				return {
					locations: [
						{
							icon: 'preferences',
							text: Strings.sidebar.settings,
							route: "/settings",
						},
						{
							icon: 'delivery-truck',
							text: Strings.settings.shippings,
						},
					],
				};
			})
		);
	}

	componentDidUpdate() {
		const { dispatch } = this.props;
		dispatch(updateCrumb());
	}

	async getData() {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.get({
				url: Endpoints.uriShipping(),
			});

			if (response?.ok) {
				const { shippings = [] } = response.data.results || {};
				this.setState({ shippings });
			} else {
				notification.error({
					message: Strings.serverErrors.title,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: 'bottomRight',
					duration: 5,
				});
			}
		} catch (err: unknown) {
			console.log('Error: ', err as string);
			notification.error({
				message: Strings.serverErrors.title,
				description: Strings.serverErrors.wentWrong,
				placement: 'bottomRight',
				duration: 5,
			});
		}

		dispatch(setLoader(false));
	}

	async toggleProvider(shipping: any) {
		const { dispatch } = this.props;

		dispatch(setLoader(true));
		try {
			const response = await API.patch({
				url: Endpoints.uriShipping(shipping._id),
				data: {
					isActive: !shipping.isActive,
				}
			});

			if (response?.ok) {
				const { shippings = [] } = response.data.results || {};
				this.setState({ shippings });

				notification.success({
					message: Strings.settings.shippings,
					description: response?.data?.message,
					placement: 'bottomRight',
					duration: 5,
				});
			} else {
				notification.error({
					message: Strings.serverErrors.title,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: 'bottomRight',
					duration: 5,
				});
			}
		} catch (err: unknown) {
			console.log('Error: ', err as string);
			notification.error({
				message: Strings.serverErrors.title,
				description: Strings.serverErrors.wentWrong,
				placement: 'bottomRight',
				duration: 5,
			});
		}

		dispatch(setLoader(false));
	}

	async deleteShipping(id: string) {
		const { dispatch } = this.props;

		dispatch(setLoader(true));
		try {
			const response = await API.delete({
				url: Endpoints.uriShipping(id),
			});

			if (response?.ok) {
				const { shippings = [] } = response.data.results || {};
				this.setState({ shippings });

				notification.success({
					message: Strings.settings.shippings,
					description: response?.data?.message,
					placement: 'bottomRight',
					duration: 5,
				});
			} else {
				notification.error({
					message: Strings.serverErrors.title,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: 'bottomRight',
					duration: 5,
				});
			}
		} catch (err: unknown) {
			console.log('Error: ', err as string);
			notification.error({
				message: Strings.serverErrors.title,
				description: Strings.serverErrors.wentWrong,
				placement: 'bottomRight',
				duration: 5,
			});
		}

		dispatch(setLoader(false));
	}

	render() {
		const { shippings } = this.state;
		const { dispatch } = this.props;

		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.settings.shippings}</title>
					<meta name="description" content="Shippings list" />
				</Helmet>
				<Table
					title={{
						icon: "golf",
						title: Strings.settings.shippings,
					}}
					data={shippings}
					columns={[
						{
							Header: Strings.fields.image,
							id: "image",
							accessor: (row: any) => row.image,
							type: "image",
							maxWidth: 65,
						},
						{
							Header: Strings.fields.name,
							id: "name",
							accessor: (row: any) => row.name,
						},
						{
							Header: Strings.fields.link,
							id: "link",
							accessor: (row: any) => row.link || "-",
						},
						{
							Header: Strings.fields.tax,
							id: "tax",
							accessor: (row: any) => translate(row.tax) || "-",
						},
					]}
					filterable
					fullPage
					isSinglePage
					add={{
						tooltip: Strings.settings.addShipping,
						onClick: () => dispatch(push('/settings/shippings/new')),
					}}
					actions={{
						edit: (obj: any) => ({
							onClick: () => dispatch(push(`/settings/shippings/${obj._id}`)),
						}),
						remove: (obj: any) => ({
							onClick: () => this.deleteShipping(obj._id),
						}),
						toggle: (obj: any) => ({
							value: obj.isActive,
							onChange: () => this.toggleProvider(obj),
						})
					}}
				/>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language,
});

export default connect(mapStateToProps)(Shipping);