import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { translate } from 'utils/utils';
import { ContentWrapper, Icon } from 'components';
import moment from 'moment';
import { setBreadcrumb, setLoader, setTitle, updateCrumb } from 'store/actions';
import { API, Endpoints } from 'utils/api';
import { Button, Checkbox, Col, Input, Modal, Row, Select, notification } from 'antd';
import Strings from 'utils/strings';
import './styles.scss';

const ZoneOption = ({ name, disabled, isDefault, isSelected, onClick, onDelete }: any) => (
	<div
		onClick={disabled ? () => { } : onClick}
		className={`zones-list-item${isSelected ? ' selected' : ''}${disabled ? ' disabled' : ''}`}
	>
		{name}
		{!isDefault && <em className="moon-trash" onClick={onDelete} />}
	</div>
);

export class Zones extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			zones: [],
			defaultZones: [],
			selectedZone: null,

			continents: [],
			countries: [],
			selectedCountry: null,

			// modal
			showCountriesModal: false,
			showStatesModal: false,

			// search helpers
			searchCountry: '',
			searchState: '',

			// temporary
			tempCountry: null,
			tempCountries: [],

			loading: true,
		};

		this.onAddNewZone = this.onAddNewZone.bind(this);
		this.onCancelNewZone = this.onCancelNewZone.bind(this);
		this.onAddCountry = this.onAddCountry.bind(this);
		this.onAddState = this.onAddState.bind(this);
		this.onAddCountries = this.onAddCountries.bind(this);
		this.onModalClose = this.onModalClose.bind(this);

		this.sortSelectedCountries = this.sortSelectedCountries.bind(this);

		this.renderCountry = this.renderCountry.bind(this);
		this.renderState = this.renderState.bind(this);
		this.renderContinentCountries = this.renderContinentCountries.bind(this);
	}

	componentDidMount() {
		const { dispatch } = this.props;
		dispatch(setTitle(""));

		this.getData();
	}

	async getData() {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const [resultZones, resultContinents] = await Promise.all([
				API.get({
					url: Endpoints.uriZones(),
				}),
				API.get({
					url: Endpoints.uriCountries('?continents=true'),
				}),
			]);

			let defaultZones = [],
				zones = [],
				selectedZone = null,
				selectedCountry = null,
				continents = [];

			if (resultZones?.ok && resultZones?.ok) {
				zones = resultZones.data?.results?.zones || {};
				continents = resultContinents.data?.results?.continents || {};

				selectedZone = zones[0];

				if (zones.length > 0 && selectedZone && Array.isArray(selectedZone.countries))
					selectedCountry = selectedZone.countries[0];

				defaultZones = JSON.parse(JSON.stringify(zones));
			}

			this.setState({ defaultZones, zones, selectedZone, selectedCountry, continents });
		} catch (error: unknown) {
			console.log('Error: ', error as any);
			notification.error({
				message: Strings.serverErrors.title,
				description: Strings.serverErrors.wentWrong,
				placement: 'bottomRight',
				duration: 5,
			});
		}

		dispatch(setLoader(false));
		dispatch(setBreadcrumb(() => ({
			title: Strings.zones.title,
			locations: [
				{
					text: Strings.sidebar.settings,
					route: "/settings",
					icon: "preferences",
				},
				{
					text: Strings.settings.zones,
					icon: "golf",
				},
			]
		})))
	}

	componentDidUpdate() {
		const { dispatch } = this.props;
		dispatch(updateCrumb());
	}

	compare = (a: object, b: object) => JSON.stringify(a) === JSON.stringify(b);

	onAddNewZone = () => {
		const { selectedZone, defaultZones } = this.state;

		const originalZone = defaultZones.find((z: any) => z._id === selectedZone._id);

		const isEqual = this.compare(selectedZone, originalZone);

		if (!isEqual) {
			this.setState({ showConfirmationModal: true });
		} else {
			this.createZone();
		}
	}

	onAddCountry() {
		const { selectedZone } = this.state;
		if (!selectedZone) {
			notification.warning({
				message: Strings.zones.title,
				description: Strings.zones.addANewZoneFirst,
				placement: 'bottomRight',
				duration: 5,
			});
		} else {
			this.setState({
				showCountriesModal: true,
				tempCountries: JSON.parse(JSON.stringify(selectedZone.countries)) || [],
			});
		}
	}

	onAddState() {
		const { selectedZone } = this.state;

		if (!selectedZone) {
			notification.warning({
				message: Strings.zones.title,
				description: Strings.zones.addANewZoneFirst,
				placement: 'bottomRight',
				duration: 5,
			});
		} else {
			this.setState({ showStatesModal: true });
		}
	}

	onAddCountries() {
		const { tempCountries, zones, selectedZone } = this.state;

		if (!selectedZone) return;

		let selectedNewCountry = !selectedZone.countries.length;

		let exists = -1;
		tempCountries.forEach((c: any) => {
			exists = selectedZone.countries.findIndex((cnt: any) => cnt.alpha3Code === c.alpha3Code);
			c.allStates = true;

			if (exists !== -1) {
				selectedZone.countries[exists] = c;
			} else {
				selectedZone.countries.push(c);
			}
		});

		if (selectedNewCountry) {
			this.getFirstCountryStates(selectedZone);
		}

		this.setState({
			zones,
			selectedZone,
			tempCountries: [],
			selectedContinent: null,
			countries: [],
			showCountriesModal: false,
			hasUnsavedFields: true,
		});
	}

	onAddStates() {
		const { zones, selectedZone, tempCountry, countries } = this.state;

		const originalCountry = countries.find((c: any) => c.alpha3Code === tempCountry.alpha3Code);

		const copyCountry = {
			allStates: false,
			alpha3Code: tempCountry.alpha3Code,
			name: translate(tempCountry.translations),
			states: tempCountry.states,
			translations: tempCountry.translations,
		};

		if (tempCountry.states && tempCountry.states.length > 0) {
			const countryIndex = selectedZone.countries.findIndex(
				(country: any) => country.alpha3Code === tempCountry.alpha3Code,
			);

			if (countryIndex === -1) {
				const hasAll = originalCountry.stateCount === copyCountry.states.length;
				copyCountry.allStates = hasAll;
				selectedZone.countries.push(copyCountry);
			} else {
				const newStates = selectedZone.countries[countryIndex].states.concat(copyCountry.states);
				selectedZone.countries[countryIndex].states = newStates;

				const hasAll = originalCountry.stateCount === newStates.length;

				selectedZone.countries[countryIndex].allStates = hasAll;
				copyCountry.allStates = hasAll;
			}

			this.setState({
				zones,
				selectedZone,
				tempCountry: null,
				selectedContinent: null,
				countries: [],
				selectedCountry: copyCountry,
				selectedAllTemp: false,
				showStatesModal: false,
				hasUnsavedFields: true,
			});
		} else {
			this.setState({
				showStatesModal: false,
				selectedContinent: null,
				selectedAllTemp: false,
				hasUnsavedFields: true,
			});
		}
	}

	onAddAllCountries() {
		const { tempCountries, countries, selectedZone } = this.state;

		if (!selectedZone) return;

		let allSelected = false;
		let selectedCountries = [] as any;

		if (Array.isArray(countries) && Array.isArray(tempCountries)) {
			selectedCountries = tempCountries.filter(cnt => {
				const find = countries.find(country => country.alpha3Code === cnt.alpha3Code);
				return !!find;
			});

			allSelected = selectedCountries.length && selectedCountries.length === countries.length;
		}

		let temp = [];

		if (allSelected) {
			temp = tempCountries.filter((cnt: any) => {
				const find = countries.find((country: any) => country.alpha3Code === cnt.alpha3Code);
				return !find;
			});
		} else {
			const missingCountries = countries.filter((cnt: any) => {
				const find = selectedCountries.find((country: any) => country.alpha3Code === cnt.alpha3Code);
				return !find;
			});

			temp = [...tempCountries, ...missingCountries];
		}

		this.setState({ tempCountries: temp, selectedZone });
	}

	onSelectZone(e: any, zone: any) {
		const { selectedZone, zones, defaultZones, hasUnsavedFields } = this.state;
		e.stopPropagation();

		if (zone._id === selectedZone._id) return;

		if (hasUnsavedFields) {
			if (selectedZone._id) {
				this.setState({
					zones: JSON.parse(JSON.stringify(defaultZones)),
					selectedZone: zone,
					selectedCountry: (Array.isArray(zone.countries) && zone.countries[0]) || {},
					hasUnsavedFields: false,
				});
			} else {
				zones.pop();
				this.setState({ zones }, () =>
					this.setState({
						selectedZone: zone,
						selectedCountry: (Array.isArray(zone.countries) && zone.countries[0]) || {},
						hasUnsavedFields: false,
					}),
				);
			}
		} else if (!selectedZone._id) {
			zones.pop();
			this.setState({
				selectedZone: zone,
				selectedCountry: (Array.isArray(zone.countries) && zone.countries[0]) || {},
				hasUnsavedFields: false,
			});
		} else {
			this.setState({
				selectedZone: zone,
				selectedCountry: (Array.isArray(zone.countries) && zone.countries[0]) || {},
				hasUnsavedFields: false,
			});
		}
	}

	onCancelNewZone() {
		const { zones } = this.state;
		this.setState({ selectedZone: zones?.[0], selectedCountry: zones?.[0]?.countries?.[0], hasUnsavedFields: false });
	}

	async onDeleteZone(e: any, zone: any) {
		const { zones } = this.state;
		const { dispatch } = this.props;

		e.stopPropagation();

		if (zone._id) {
			dispatch(setLoader(true));

			try {
				const response = await API.delete({
					url: Endpoints.uriZones(zone._id),
				});

				if (response?.ok) {
					const { zones = [] } = response.data.results || {};

					this.setState({
						defaultZones: JSON.parse(JSON.stringify(zones)),
						zones,
						selectedZone: zones.length > 0 ? zones[0] : {},
						selectedCountry:
							(zones.length > 0 && Array.isArray(zones[0].countries) && zones[0].countries[0]) ||
							{},
						hasUnsavedFields: false,
					});

					notification.success({
						message: Strings.zones.title,
						description: response?.data?.message,
						placement: 'bottomRight',
						duration: 5,
					});
				}
			} catch (error: unknown) {
				console.log('error', error);
				notification.error({
					message: Strings.serverErrors.title,
					description: Strings.serverErrors.wentWrong,
					placement: 'bottomRight',
					duration: 5,
				});
			}

			dispatch(setLoader(false));
		} else {
			zones.pop();

			this.setState({
				zones,
				selectedZone: zones[0] || {},
				selectedCountry:
					(zones.length > 0 && Array.isArray(zones[0].countries) && zones[0].countries[0]) || {},
				hasUnsavedFields: false,
				loading: false,
			});

			notification.success({
				message: Strings.zones.title,
				description: Strings.zones.deleted,
				placement: 'bottomRight',
				duration: 5,
			});
		}
	}

	async onSelectCountry(e: any, country: any) {
		e.stopPropagation();

		if (!Array.isArray(country.states) || !country.states.length) {
			const { selectedZone } = this.state;

			if (!selectedZone) return null;

			const index = selectedZone.countries.findIndex((c: any) => c.alpha3Code === country.alpha3Code);

			const countriesResult = await API.get({ url: Endpoints.uriCountries(`/alpha/${country.alpha3Code}`) });

			if (countriesResult?.ok) {
				const { country } = countriesResult.data.results || {};
				const { states = [] } = country || {};
				selectedZone.countries[index].states = states;

				this.setState({ selectedCountry: country });
			}
		} else {
			this.setState({ selectedCountry: country });
		}
	}

	onDeleteCountry(e: any, country: any) {
		e.stopPropagation();

		const { selectedZone } = this.state;

		if (!!selectedZone) {
			const countryIndex = selectedZone.countries.findIndex((cnt: any) => cnt.alpha3Code === country.alpha3Code);
			selectedZone.countries.splice(countryIndex, 1);

			this.setState({ selectedZone, hasUnsavedFields: true });
		}
	}

	onDeleteState(_: any, state: any) {
		const { selectedZone, selectedCountry: country } = this.state;

		const countryIndex = selectedZone.countries.findIndex((cnt: any) => cnt.alpha3Code === country.alpha3Code);
		const stateIndex = selectedZone.countries[countryIndex].states.findIndex((stt: any) => stt.name === state.name);

		if (country.states.length === 1) {
			selectedZone.countries.splice(countryIndex, 1);
			this.setState({ selectedZone, hasUnsavedFields: true });
		} else {
			selectedZone.countries[countryIndex].states.splice(stateIndex, 1);
			selectedZone.countries[countryIndex].allStates = false;
			this.setState({ selectedZone, hasUnsavedFields: true });
		}
	}

	validateZone() {
		const { selectedZone } = this.state;

		if (!selectedZone.countries || selectedZone.countries.length === 0) return false;
		return true;
	}

	async onUpdateZone() {
		const { dispatch } = this.props;
		const { selectedZone } = this.state;

		if (!this.validateZone()) {
			notification.error({
				message: Strings.zones.title,
				description: Strings.zones.fillCountry,
				placement: 'bottomRight',
				duration: 5
			});

			return;
		}

		const newZone = {
			name: selectedZone.name,
			partner: selectedZone.partner,
			countries: [] as any,
		};

		selectedZone.countries.forEach((country: any) => {
			const states = country.states || [];

			newZone.countries.push({
				alpha3Code: country.alpha3Code,
				name: translate(country.translations),
				states: states.map((s: any) => ({ name: s.name })),
				allStates: country.allStates,
			});
		});

		const zoneCopy = JSON.parse(JSON.stringify(newZone));
		zoneCopy.countries = zoneCopy.countries && zoneCopy.countries.filter((cnt: any) => {
			if (cnt.allStates) { cnt.states = [] }
			return cnt;
		});

		dispatch(setLoader(true));

		try {
			const request = selectedZone._id ? API.put : API.post;
			const response = await request({ url: Endpoints.uriZones(selectedZone._id ? selectedZone._id : ''), data: zoneCopy });

			if (response?.ok) {
				notification.success({
					message: Strings.zones.title,
					description: response?.data?.message,
					placement: 'bottomRight',
					duration: 5
				});

				const { zones = [] } = response.data.results || {};

				let selected = {} as any;
				let country = {};

				if (zones.length > 0) {
					if (selectedZone._id) {
						selected = zones.find((z: any) => z._id === selectedZone._id);
					} else {
						selected = zones[zones.length - 1];
					}
				}

				if (Array.isArray(selected.countries)) {
					country = selected.countries[0] || {};
				}

				this.setState({
					defaultZones: JSON.parse(JSON.stringify(zones)),
					zones,
					selectedZone: selected,
					selectedCountry: country,
					hasUnsavedFields: false,
					loading: false,
				});
			}
		} catch (error: unknown) {
			notification.error({
				message: Strings.zones.title,
				description: error as string,
				placement: 'bottomRight',
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	onModalClose() {
		this.setState({
			showCountriesModal: false,
			showStatesModal: false,
			selectedContinent: null,
			tempCountries: [],
			countries: [],
			tempCountry: null,
		});
	}

	createZone() {
		const selectedZone = {
			id: moment().valueOf(),
			name: Strings.zones.newZoneName,
			countries: [],
		};

		this.setState({
			selectedZone,
			selectedCountry: null,
			hasUnsavedFields: true,
			showConfirmationModal: false,
		});
	}

	getSelectedCountry() {
		const { selectedZone, selectedCountry } = this.state;

		if (!selectedZone || !selectedCountry || !Array.isArray(selectedZone.countries)) return null;

		const selected = selectedZone.countries.find((cnt: any) => cnt.alpha3Code === selectedCountry.alpha3Code);

		if (!selected) return null;

		if (!Array.isArray(selected.states)) selected.states = [];

		return selected;
	}

	async getCountries(continent: string) {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const endpoint = continent === 'all' ? Endpoints.uriCountries() : Endpoints.uriCountries(`?continent=${continent}`);

			const response = await API.get({ url: endpoint });
			if (response?.ok) {
				const { countries } = response.data.results || {};
				this.setState({ countries });
			}
		} catch (error: unknown) {
			notification.error({
				message: Strings.zones.title,
				description: error as string,
				placement: 'bottomRight',
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async getStates(country: any) {
		const { countries } = this.state;
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.get({
				url: Endpoints.uriCountries(`alpha/${country.alpha3Code}`),
			});

			if (response?.ok) {
				const newCountry = response.data.results.country;
				const index = countries.findIndex((cnt: any) => cnt.alpha3Code === country.alpha3Code);
				if (!Array.isArray(newCountry.states)) {
					newCountry.states = [];
				}
				countries[index] = newCountry;
				country.states = [];

				this.setState({ countries, tempCountry: country, selectedAllTemp: false });
			}
		} catch (error: unknown) {
			notification.error({
				message: Strings.zones.title,
				description: error as string,
				placement: 'bottomRight',
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async getFirstCountryStates(zone: any) {
		const { selectedZone } = this.state;
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.get({
				url: Endpoints.uriCountries(`/alpha/${zone.countries[0].alpha3Code}`),
			});

			if (response?.ok) {
				const { country } = response.data.results || {};
				selectedZone.countries[0].states = country.states || [];
				this.setState({ selectedZone });
			}
		} catch (error: unknown) {
			notification.error({
				message: Strings.zones.title,
				description: error as string,
				placement: 'bottomRight',
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async getCountriesForStatesModal(continent: any) {
		const { selectedZone, continents } = this.state;
		const { dispatch } = this.props;

		const selectedContinent = continents.find((cnt: any) => cnt._id === continent);
		if (!selectedContinent) return;

		dispatch(setLoader(true));

		try {
			const response = await API.get({
				url: Endpoints.uriCountries(`?continent=${selectedContinent.translations.en}`),
			});

			if (response?.ok) {
				const countries = selectedZone.countries || [];
				const apiCountries = response.data.results.countries.filter((country: any) => {
					const selected = countries.find((c: any) => c.alpha3Code === country.alpha3Code);
					if (!selected) return true;

					if (selected && !selected.allStates) return true;

					return false;
				});

				this.setState({
					countries: apiCountries,
					tempCountries: [],
					selectedAllTemp: false,
				});
			}
		} catch (error: unknown) {
			notification.error({
				message: Strings.zones.title,
				description: error as string,
				placement: 'bottomRight',
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	renderNoResults(type: any) {
		const { selectedCountry: country, selectedZone: zone } = this.state;

		const hasCountries = zone && Array.isArray(zone.countries) && !!zone.countries.length;

		let message = '';

		if (hasCountries) {
			if ((!country || !Array.isArray(country.states)) && type === 'state') {
				message = Strings.generic.noItemsSelected;
			}
		} else {
			if (type === 'country') {
				message = Strings.generic.noItemsSelected;
			}

			message = Strings.generic.noItemsSelected;
		}

		return !!message && <div className="AllEntriesSelected"><Icon name="golf" /><p>{message}</p></div>;
	}

	sortSelectedCountries(a: any, b: any) {
		const { language } = this.props;

		try {
			if (a.translations[language] && b.translations[language]) {
				return a.translations[language] > b.translations[language];
			}
			return a.translations[language] > b.translations[language];
		} catch (err) {
			return a.translations[language] > b.translations[language];
		}
	}

	renderCountry(country: any) {
		const { language } = this.props;
		const { selectedCountry: selected } = this.state;

		const isSelected = selected?.alpha3Code === country.alpha3Code;

		const name = country.translations[language];

		return (
			<div
				key={`country_${country.alpha3Code}_${selected?.alpha3Code}`}
				className={`zones-list-item${isSelected ? ' country-selected-border' : ''}`}
				onClick={e => this.onSelectCountry(e, country)}
			>
				<div className={`zones-list-item-name${isSelected ? ' country-selected' : ''}`}>
					{`${name}${!country.allStates && country.states ? ` (${Strings.zones.customZone})` : ''}`}
				</div>
				<div className="zones-list-item-options">
					<em className="moon-trash" onClick={e => this.onDeleteCountry(e, country)} />
				</div>
			</div>
		);
	}

	renderState(state: any, index: number) {
		return (
			<div key={`state_${state.name}_${index}`} className="zones-list-item">
				<div className="zones-list-item-name">{state.name}</div>
				<div className="zones-list-item-options">
					<em className="moon-trash" onClick={e => this.onDeleteState(e, state)} />
				</div>
			</div>
		);
	}

	renderSelectedStates() {
		const { searchState } = this.state;

		const selected = this.getSelectedCountry();

		if (!selected || !selected.states) return null;

		return selected.states
			.filter((stt: any) => !searchState || stt.name.toLowerCase().includes(searchState.toLowerCase().trim()))
			.map(this.renderState);
	}

	renderZoneOptionButton() {
		const { selectedZone } = this.state;

		const isNew = selectedZone?.id || false;

		const func = !isNew ? this.onAddNewZone : this.onCancelNewZone;

		return (
			<button className="ZoneAddButton" onClick={() => func()}>
				<span className="zones-button-container">
					{!isNew ? <Icon name="plus" /> : <Icon name="trash" />}
					<span className="zones-smaller-button">
						{!isNew ? Strings.zones.addZone : Strings.generic.cancel}
					</span>
				</span>
			</button>
		);
	}

	renderZoneOptions() {
		const { zones, selectedZone } = this.state;

		return (
			<ContentWrapper extraStyle={{ maxHeight: 650, height: "100%" }}>
				<div className="title-toggle">
					<div className="GenericTitleContainer --title-multi-container">
						<div className="GenericTitle">
							<Icon name="golf" />
							<h1>{Strings.zones.zonesList}</h1>
						</div>
						{this.renderZoneOptionButton()}
					</div>
				</div>
				<span className="ZonesInfo">
					{Strings.zones.zonesListHelper}
				</span>
				<div className="ZonesList">
					{zones.map((zone: any) => (
						<ZoneOption
							key={zone._id}
							name={zone.name}
							disabled={!!selectedZone.id}
							isSelected={selectedZone && selectedZone._id === zone._id}
							isDefault={zone.isDefault}
							onClick={(e: any) => this.onSelectZone(e, zone)}
							onDelete={(e: any) => this.onDeleteZone(e, zone)}
						/>
					))}
				</div>
			</ContentWrapper>
		);
	}

	renderSelectedCountries() {
		const { language } = this.props;
		const { selectedZone: zone, searchCountry } = this.state;

		const countries = zone && Array.isArray(zone.countries) ? zone.countries : [];

		return countries
			.filter((cnt: any) => {
				if (!searchCountry) return true;

				const name = cnt.translations[language];

				if (!name) return false;

				return name.toLowerCase().includes(searchCountry.toLowerCase().trim());
			})
			.sort(this.sortSelectedCountries)
			.map(this.renderCountry);
	}

	renderDetailCountries() {
		const { selectedZone, searchCountry } = this.state;

		const hasCountries = selectedZone && Array.isArray(selectedZone.countries) && !!selectedZone.countries.length;

		return (
			<div className="zones-list-container">
				<label htmlFor="zone_name" className="InputLabel">{Strings.fields.countries}</label>
				<div className="zones-list">
					<div className="zones-list-header">
						<div style={{ position: 'relative', width: '60%' }}>
							<input
								type="text"
								value={searchCountry || ''}
								disabled={!selectedZone}
								placeholder={Strings.generic.search}
								className="inputSearch"
								onChange={e => this.setState({ searchCountry: e.target.value })}
							/>
							<em className="moon-search search-icon" />
						</div>
						<button className="ZoneAddButtonRounded" onClick={this.onAddCountry}>
							<em className="moon-plus" />
						</button>
					</div>
					<div className="zones-list-itemList-container">
						<div className="zones-list-itemList">
							{this.renderSelectedCountries()}
							{!hasCountries && this.renderNoResults('country')}
						</div>
					</div>
				</div>
			</div>
		);
	}

	renderDetailStates() {
		const { selectedZone, searchState } = this.state;

		const selected = this.getSelectedCountry();

		const hasStates =
			selected && Array.isArray(selected.states) && (!!selected.states.length || selected.allStates);

		return (
			<div className="zones-list-container">
				<label htmlFor="zone_name" className="InputLabel">{Strings.fields.states}</label>
				<div className="zones-list">
					<div className="zones-list-header">
						<div style={{ position: 'relative', width: '60%' }}>
							<input
								type="text"
								value={searchState || ''}
								disabled={!selectedZone}
								placeholder={Strings.generic.search}
								className="inputSearch"
								onChange={e => this.setState({ searchState: e.target.value })}
							/>
							<em className="moon-search search-icon" />
						</div>
						<button className="ZoneAddButtonRounded" onClick={this.onAddState}>
							<em className="moon-plus" />
						</button>
					</div>
					<div className="zones-list-itemList-container">
						<div className="zones-list-itemList">
							{this.renderSelectedStates()}
							{!hasStates && this.renderNoResults('state')}
						</div>
					</div>
				</div>
			</div>
		);
	}

	renderDetail() {
		const { zones, selectedZone, hasUnsavedFields } = this.state;
		const zoneName = selectedZone?.name || '';

		return (
			<ContentWrapper extraClass="zones-lists-container">
				<Row gutter={[20, 10]}>
					<Col xs={24} md={12}>
						<div className="zones-list-name">
							<label htmlFor="zone_name" className="GenericLabel">
								{Strings.zones.zoneName}
							</label>
							<Input
								id="zone_name"
								className="inputClass"
								value={zoneName}
								disabled={!selectedZone}
								placeholder={Strings.zones.zoneName}
								onChange={(e: any) => {
									this.setState({ selectedZone: { ...(selectedZone || {}), name: e.target.value }, hasUnsavedFields: true });
								}}
								onBlur={() => {
									if (!!selectedZone) {
										const index = zones.findIndex(
											(zone: any) => zone._id === selectedZone._id || (zone.id && zone.id === selectedZone.id),
										);

										if (index !== -1) {
											zones[index] = selectedZone;
											this.setState({ zones });
										}
									}
								}}
							/>
							<div className="zones-list-name-tooltip">
								{Strings.zones.zonesHelper}
							</div>
						</div>
					</Col>
					<Col xs={24} md={12}>
						<div className="ZoneSaveButton">
							<Button disabled={!hasUnsavedFields} className="BreadcrumbSaveButton" onClick={() => this.onUpdateZone()}>
								<Icon name="correct-symbol" />
								{Strings.generic.save}
							</Button>
						</div>
					</Col>
				</Row>
				<div className="zones-list-block">
					{this.renderDetailCountries()}
					{this.renderDetailStates()}
				</div>
			</ContentWrapper>
		);
	}

	selectCountry(country: any) {
		const { tempCountries } = this.state;

		const index = tempCountries.findIndex((cnt: any) => cnt.alpha3Code === country.alpha3Code);
		if (index === -1) {
			tempCountries.push(country);
		} else {
			tempCountries.splice(index, 1);
		}

		this.setState({ tempCountries });
	}

	renderContinentCountries(country: any) {
		const { language } = this.props;
		const { tempCountries } = this.state;

		const isSelected = tempCountries.find((tempCountry: any) => tempCountry.alpha3Code === country.alpha3Code);

		return (
			<button key={country?.alpha3Code} onClick={() => this.selectCountry(country)} className="ZoneCountryItem">
				<Checkbox checked={!!isSelected} onChange={(e) => {
					e.preventDefault();
					e.stopPropagation();
					this.selectCountry(country)
				}}>
					{country.translations[language]}
				</Checkbox>
			</button>
		)
	}

	renderCountriesModalContent() {
		const { language } = this.props;
		const { continents, countries, selectedContinent, tempCountries } = this.state;


		const selectedLabel =
			selectedContinent &&
			selectedContinent.translations &&
			(selectedContinent.translations[language]);

		let allSelected = false;

		if (Array.isArray(countries) && Array.isArray(tempCountries)) {
			const selectedCountries = tempCountries.filter(cnt => {
				const find = countries.find(country => country.alpha3Code === cnt.alpha3Code);
				return !!find;
			});

			allSelected = (selectedCountries.length && selectedCountries.length === countries.length) || false;
		}

		return (
			<div className="zones-modal-container">
				<div className="zones-modal-header">
					<div className="zones-modal-header-back" onClick={this.onModalClose}>
						<em className="moon-close1" />
					</div>
					<div className="zones-modal-header-title">
						{Strings.zones.addCountries}
					</div>
				</div>
				<div className="zones-modal-content">
					<div className="GenericRow">
						<label>{Strings.zones.byContinent}</label>
						<Select
							placeholder={Strings.formatString(Strings.generic.genericSelect, Strings.zones.continent)}
							value={selectedLabel}
							onChange={(value: string) => {
								this.setState({ selectedContinent: value });
								this.getCountries(value);
							}}
							style={{ minWidth: 200 }}
							showSearch
							filterOption={(input: any, option: any) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
						>
							<Select.Option value="all">
								{Strings.generic.all}
							</Select.Option>
							{continents.map((continent: any) => (
								<Select.Option key={continent._id} value={continent.translations.en}>
									{continent.translations.en}
								</Select.Option>
							)
							)}
						</Select>
					</div>
					<div className="zones-modal-options">
						<Checkbox checked={allSelected} onChange={() => this.onAddAllCountries()}>
							{Strings.formatString(Strings.generic.genericSelect, Strings.generic.all)}
						</Checkbox>
						<button
							type="button"
							className="zones-list-header-clear"
							disabled={tempCountries.length === 0}
							onClick={() => this.setState({ tempCountries: [] })}
						>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<em className="moon-eraser zones-smaller-button m10r" />
								<span className="zones-smaller-button">
									{Strings.generic.clearList}
								</span>
							</div>
						</button>
					</div>
					<div className="zones-modal-list-container">
						{Array.isArray(countries) && countries.map(this.renderContinentCountries)}
						{!selectedContinent && (
							<React.Fragment>
								<div className="AllEntriesSelected">
									<Icon name="golf" />
									<p>{Strings.zones.selectAContinent}</p>
								</div>
							</React.Fragment>
						)}
					</div>
				</div>
				<div className="zones-modal-addSelection">
					<Button
						style={{ height: '40px', padding: '10px', margin: 0 }}
						onClick={() => this.onAddCountries()}
					>
						<span className="zones-button-container">
							<em className="moon-plus zones-smaller-button m10r" />
							<span className="zones-smaller-button">
								{Strings.zones.addSelection}
							</span>
						</span>
					</Button>
				</div>
			</div>
		);
	}

	selectState(state: any) {
		const { tempCountry } = this.state;

		const stateIndex = tempCountry.states.findIndex((stt: any) => stt.name === state.name);

		if (stateIndex === -1) {
			tempCountry.states.push(state);
		} else {
			tempCountry.states.splice(stateIndex, 1);
		}

		this.setState({ tempCountry });
	}

	renderStates() {
		const { countries, tempCountry, selectedZone } = this.state;

		const zoneCountries = selectedZone?.countries || [];
		const pickerCountry = countries.find((c: any) => tempCountry && c.alpha3Code === tempCountry.alpha3Code);

		let selected = zoneCountries.find((c: any) => tempCountry && c.alpha3Code === tempCountry.alpha3Code);

		if (!selected) selected = { states: [] };

		if (!pickerCountry) {
			return (
				<React.Fragment>
					<div className="AllEntriesSelected">
						<Icon name="golf" />
						<p>{Strings.zones.selectACountry}</p>
					</div>
				</React.Fragment>
			)
		}

		const availableStates = pickerCountry.states.filter((state: any) => !selected.states.find((s: any) => s.name === state.name));

		if (!availableStates.length) {
			return (
				<React.Fragment>
					<div className="AllEntriesSelected">
						<Icon name="golf" />
						<p>{Strings.zones.allStatesSelected}</p>
					</div>
				</React.Fragment>
			)
		};

		return availableStates.map((state: any) => {
			const isSelected = tempCountry?.states.findIndex((tempState: any) => tempState.name === state.name) !== -1;
			return (
				<button key={`country-${state.name}`} onClick={() => this.selectState(state)} className={`ZoneCountryItem${!!isSelected ? ' --selected' : ''}`}>
					<Checkbox checked={!!isSelected} onChange={(e) => {
						e.preventDefault();
						e.stopPropagation();
						this.selectState(state)
					}}>
						{state.name}
					</Checkbox>
				</button>
			)
		});
	}

	renderStatesModalContent() {
		const {
			selectedContinent,
			countries,
			continents,
			tempCountry,
			selectedCountry,
			selectedZone,
		} = this.state;

		const selectedStates = tempCountry?.states || [];
		const zoneCountries = selectedZone?.countries || [];
		const pickerCountry = countries.find((c: any) => tempCountry && c.alpha3Code === tempCountry.alpha3Code);
		const selected = zoneCountries.find((c: any) => c.alpha3Code === selectedCountry?.alpha3Code);

		let isSelectedAll = false;
		let availableStates = [] as any;

		try {
			if (selected && pickerCountry) {
				availableStates = pickerCountry.states.filter(
					(state: any) => !selected.states.find((s: any) => s.name === state.name),
				);

				isSelectedAll =
					Array.isArray(selectedStates) &&
					selectedStates.length === availableStates.length &&
					availableStates.length !== 0;
			} else if (!selected && pickerCountry) {
				availableStates = [...pickerCountry.states];
				isSelectedAll =
					Array.isArray(selectedStates) &&
					selectedStates.length === availableStates.length &&
					availableStates.length !== 0;
			}
		} catch (err) {
			console.log(err);
		}

		return (
			<div className="zones-modal-container">
				<div className="zones-modal-header">
					<div className="zones-modal-header-back" onClick={this.onModalClose}>
						<em className="moon-close1" />
					</div>
					<div className="zones-modal-header-title">
						{Strings.formatString(Strings.generic.addGeneric, Strings.fields.states)}
					</div>
				</div>
				<div className="zones-modal-content">
					<Row gutter={[20, 10]}>
						<Col xs={24} md={12}>
							<label htmlFor="continent_list" className="InputLabel">
								{Strings.zones.continent}
							</label>
							<Select
								id="continent_list"
								style={{ minWidth: "100%" }}
								placeholder={Strings.formatString(Strings.generic.genericSelect, Strings.zones.continent) as string}
								value={selectedContinent}
								onChange={(value) => {
									this.setState({ selectedContinent: value }, () =>
										this.getCountriesForStatesModal(value),
									);
								}}
								showSearch
								filterOption={(input: any, option: any) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
							>
								{continents.map((continent: any) => (
									<Select.Option key={continent._id} value={continent._id}>
										{continent.translations.en}
									</Select.Option>
								))}
							</Select>
						</Col>
						<Col xs={24} md={12}>
							<label htmlFor="country_list" className="InputLabel">
								{Strings.fields.country}
							</label>
							<Select
								id="country_list"
								style={{ minWidth: "100%" }}
								placeholder={Strings.formatString(Strings.generic.genericSelect, Strings.zones.continent)}
								value={tempCountry?.alpha3Code}
								onChange={(alpha) => {
									if (!alpha || tempCountry?.alpha3Code !== alpha) {
										const country = countries.find((c: any) => c.alpha3Code === alpha);
										this.getStates(country);
									}
								}}
								showSearch
								filterOption={(input: any, option: any) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
							>
								{countries.map((country: any) => (
									<Select.Option key={country._id} value={country.alpha3Code}>
										{country.translations.en}
									</Select.Option>
								))}
							</Select>
						</Col>
					</Row>
					<div className="zones-modal-options">
						<Checkbox checked={isSelectedAll} onChange={() => {
							if (tempCountry) {
								if (isSelectedAll) {
									tempCountry.states = [];
								} else {
									tempCountry.states = availableStates;
								}

								this.setState({ tempCountry });
							}
						}}>
							{Strings.formatString(Strings.generic.genericSelect, Strings.generic.all)}
						</Checkbox>
						<button
							type="button"
							className="zones-list-header-clear"
							disabled={
								!tempCountry || !Array.isArray(tempCountry.states) || tempCountry.states.length === 0
							}
							onClick={() => this.setState({ tempCountry: null })}
						>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<em className="moon-eraser zones-smaller-button m10r" />
								<span className="zones-smaller-button">
									{Strings.generic.clearList}
								</span>
							</div>
						</button>
					</div>
					<div className="zones-modal-list-container">{this.renderStates()}</div>
				</div>
				<div className="zones-modal-addSelection">
					<Button
						style={{ height: '40px', padding: '10px', margin: 0 }}
						onClick={() => this.onAddStates()}
					>
						<span className="zones-button-container">
							<em className="moon-plus zones-smaller-button m10r" />
							<span className="zones-smaller-button">
								{Strings.zones.addSelection}
							</span>
						</span>
					</Button>
				</div>
			</div>
		);
	}

	renderCountriesModal() {
		const { showCountriesModal } = this.state;
		const { mobile } = this.props;

		return (
			<Modal className="ZonesModal" visible={showCountriesModal} footer={null} onCancel={this.onModalClose} closable width={mobile ? "100%" : 800}>
				{this.renderCountriesModalContent()}
			</Modal>
		);
	}

	renderStatesModal() {
		const { showStatesModal } = this.state;
		const { mobile } = this.props;

		return (
			<Modal className="ZonesModal" visible={showStatesModal} footer={null} onCancel={this.onModalClose} closable width={mobile ? "100%" : 800}>
				{this.renderStatesModalContent()}
			</Modal>
		);
	}

	renderConfirmationModal() {
		const { showConfirmationModal } = this.state;

		return (
			<Modal
				title={Strings.zones.addZone}
				visible={showConfirmationModal}
				onOk={() => this.createZone()}
				onCancel={() => this.setState({ showConfirmationModal: false })}
				okText={Strings.generic.yes}
				cancelText={Strings.generic.no}
			>
				<p>Bla bla ...</p>
				<p>Bla bla ...</p>
				<p>Bla bla ...</p>
			</Modal>
		)
	}

	render() {
		return (
			<div className="UserDetailScreen">
				<Helmet>
					<title>{Strings.settings.zones}</title>
					<meta name="description" content="Zones configuration" />
				</Helmet>
				<div className="ZonesScreen">
					<Row gutter={[20, 10]}>
						<Col xs={24} lg={7}>{this.renderZoneOptions()}</Col>
						<Col xs={24} lg={17}>{this.renderDetail()}</Col>
					</Row>
				</div>
				{this.renderCountriesModal()}
				{this.renderStatesModal()}
				{this.renderConfirmationModal()}
			</div>
		);
	}
}

const mapStateToProps = (store: any) => ({
	settings: store.settings,
	language: store.language,
	mobile: store.mobile,
});

export default connect(mapStateToProps)(Zones);
