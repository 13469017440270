/*
 *
 * Dashboard
 *
 */

import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { setTitle, setBreadcrumb, delayedDispatch, updateCrumb, setLoader, setUser } from "store/actions";
import { ContentWrapper, Icon } from "components";
import { Button, Col, Input, notification, Row, Tabs, Tooltip } from "antd";
import { Line } from "@ant-design/charts";
import moment from "moment";

import Strings from "utils/strings";
import "./styles.scss";
import { API, Endpoints } from "utils/api";
import { formatPrice } from "utils/utils";
import { CopyOutlined } from "@ant-design/icons";

export class Dashboard extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			active: false,
			hasUnsavedFields: true,
			inStock: true,
			startDate: moment().startOf("month"),
			endDate: moment().endOf("day"),
			language: "pt",
			data: {
				userStatistics: [],
				uses: []
			},
			graphTab: "1",
			cards: {
				newUsers: {
					title: Strings.dashboard.newUsers,
					icon: "users",
					type: "userStatistics",
					value: 0,
					isMonetary: false
				},
				androidUse: {
					title: Strings.dashboard.androidUse,
					icon: "mobile-app",
					type: "userAndroid",
					value: 0,
					isMonetary: false
				},
				iOSUse: {
					title: Strings.dashboard.iOSUse,
					icon: "mobile-app",
					type: "userIOS",
					value: 0,
					isMonetary: false
				},
				webUse: {
					title: Strings.dashboard.webUse,
					icon: "www",
					type: "userWeb",
					value: 0,
					isMonetary: false
				}
			},
			points: props.user?.partner?.points || 0,
			balance: props.user?.partner?.balance || 0
		};

		this.handleSelect = this.handleSelect.bind(this);
	}

	componentDidMount() {
		const { dispatch } = this.props;

		dispatch(setTitle(Strings.sidebar.dashboard));

		delayedDispatch(
			setBreadcrumb(() => {
				const { startDate, endDate } = this.state;

				return {
					actions: [
						{
							type: "datePicker",
							text: Strings.dashboard.period,
							dates: [startDate, endDate],
							onChange: this.handleSelect,
							className: "fixedPicker"
						}
					]
				};
			})
		);

		this.getData();
	}

	async getData() {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.get({
				url: Endpoints.uriUsers("dashboard")
			});

			if (response?.ok) {
				const { user } = response.data.results || {};
				dispatch(setUser(user));
			} else {
				notification.error({
					message: Strings.sidebar.commissions,
					description: response?.data?.message,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err: unknown) {
			notification.error({
				message: Strings.serverErrors.title,
				description: err as string,
				placement: "bottomRight",
				duration: 5
			});
		} finally {
			dispatch(setLoader(false));
		}
	}

	componentDidUpdate() {
		const { dispatch } = this.props;
		dispatch(updateCrumb());
	}

	handleSelect(dates: any) {
		const newDates = [];
		if (dates && dates[0]) {
			newDates.push(moment(dates[0]).startOf("day"));
		}

		if (dates && dates[1]) {
			newDates.push(moment(dates[1]).endOf("day"));
		}

		this.setState(
			{
				startDate: newDates[0],
				endDate: newDates[1]
			},
			() => this.getData()
		);
	}

	// async getData() {
	// 	const { startDate, endDate, cards } = this.state;
	// 	const { dispatch } = this.props;

	// 	dispatch(setLoader(true));

	// 	try {
	// 		const response = await API.get({
	// 			url: Endpoints.uriAnalytics(`?startDate=${startDate.format("DD/MM/yyyy")}&endDate=${endDate.format("DD/MM/yyyy")}`)
	// 		});

	// 		if (response.ok) {
	// 			const { analytics } = response.data.results || {};

	// 			let diff = endDate.diff(startDate, "days") + 1;

	// 			const format = diff > 90 ? "MM/yyyy" : "DD/MM/yyyy";

	// 			const userStatistics = analytics?.userStatistics?.byDate?.map((elem: number, index: number) => ({
	// 				day: moment(startDate)
	// 					.add(index, diff > 90 ? "months" : "days")
	// 					.format(format),
	// 				name: Strings.dashboard.newUsers,
	// 				value: elem
	// 			}));

	// 			const uses = analytics?.userIOS?.byDate?.map((elem: number, index: number) => ({
	// 				day: moment(startDate)
	// 					.add(index, diff > 90 ? "months" : "days")
	// 					.format(format),
	// 				name: Strings.dashboard.iOSUse,
	// 				value: elem
	// 			}));

	// 			uses?.push(
	// 				...analytics?.userAndroid?.byDate?.map((elem: number, index: number) => ({
	// 					day: moment(startDate)
	// 						.add(index, diff > 90 ? "months" : "days")
	// 						.format(format),
	// 					name: Strings.dashboard.androidUse,
	// 					value: elem
	// 				}))
	// 			);

	// 			uses?.push(
	// 				...analytics?.userWeb?.byDate?.map((elem: number, index: number) => ({
	// 					day: moment(startDate)
	// 						.add(index, diff > 90 ? "months" : "days")
	// 						.format(format),
	// 					name: Strings.dashboard.webUse,
	// 					value: elem
	// 				}))
	// 			);

	// 			const newCards = JSON.parse(JSON.stringify(cards));

	// 			newCards.newUsers.value = analytics?.userStatistics?.value;
	// 			newCards.androidUse.value = analytics?.userAndroid?.value;
	// 			newCards.iOSUse.value = analytics?.userIOS?.value;
	// 			newCards.webUse.value = analytics?.userWeb?.value;

	// 			this.setState({
	// 				data: { userStatistics, uses },
	// 				cards: newCards,
	// 				userStatistics: analytics?.userStatistics?.value,
	// 				userIOS: analytics?.userIOS?.value,
	// 				userAndroid: analytics?.userAndroid?.value,
	// 				userWeb: analytics?.userWeb?.value
	// 			});
	// 		} else {
	// 			notification.error({
	// 				message: Strings.sidebar.dashboard,
	// 				description: response.data?.message || Strings.serverErrors.wentWrong,
	// 				placement: "bottomRight",
	// 				duration: 5
	// 			});
	// 		}
	// 	} catch (err: unknown) {
	// 		notification.error({
	// 			message: Strings.serverErrors.title,
	// 			description: Strings.serverErrors.wentWrong,
	// 			placement: "bottomRight",
	// 			duration: 5
	// 		});
	// 	}

	// 	dispatch(setLoader(false));
	// }

	renderMonthlyCards() {
		const { points } = this.state;

		const CARDS = [
			{ title: Strings.commissions.cp, value: points?.cp || 0 },
			{ title: Strings.commissions.pv, value: points?.pv || 0 },
			{ title: Strings.commissions.tpv, value: points?.tpv || 0 },
			{ title: Strings.commissions.leftSgv, value: points?.leftSgv || 0 },
			{ title: Strings.commissions.rightSgv, value: points?.rightSgv || 0 },
			{ title: Strings.commissions.leftSpillover, value: points?.leftSpillover || 0 },
			{ title: Strings.commissions.rightSpillover, value: points?.rightSpillover || 0 },
			{ title: Strings.commissions.leftTgv, value: points?.leftTgv || 0 },
			{ title: Strings.commissions.rightTgv, value: points?.rightTgv || 0 },
			{ title: Strings.commissions.tgv, value: points?.tgv || 0 }
		];

		return (
			<div className="DashboardMonthlyCards">
				<div className="ScreenHeader">
					<div className="ScreenHeaderLeft">
						<Icon name="points" />
						<h2>{Strings.dashboard.monthly}</h2>
					</div>
				</div>
				<Row gutter={[20, 20]}>
					{CARDS.map((card, index) => (
						<Col xs={24} md={8} xl={6} key={index}>
							<div className="DashboardCardOuterContainer">
								<div className="DashboardCard">
									<div className="DashboardCardHeader">
										<div className="DashboardCardHeaderMask" />
										<div className="DashboardCardType">
											<Icon name="points" />
										</div>
									</div>
									<div className="DashboardCardBody">
										<span className="DashboardCardValue">{card.value}</span>
										<h3 className="DashboardCardDescription">{card.title}</h3>
									</div>
								</div>
							</div>
						</Col>
					))}
				</Row>
			</div>
		);
	}

	renderAnnuallyCards() {
		const { points } = this.state;

		const CARDS = [
			{ title: Strings.commissions.cp, value: points?.annualCp || 0 },
			{ title: Strings.commissions.pv, value: points?.annualPv || 0 },
			{ title: Strings.commissions.tpv, value: points?.annualTpv || 0 },
			{ title: Strings.commissions.tgv, value: points?.annualTgv || 0 }
		];

		return (
			<div className="DashboardAnnualCards">
				<div className="ScreenHeader --mt-20">
					<div className="ScreenHeaderLeft">
						<Icon name="points" />
						<h2>{Strings.dashboard.annually}</h2>
					</div>
				</div>
				<Row gutter={[20, 20]}>
					{CARDS.map((card, index) => (
						<Col xs={24} md={8} xl={6} key={index}>
							<div className="DashboardCardOuterContainer">
								<div className="DashboardCard">
									<div className="DashboardCardHeader">
										<div className="DashboardCardHeaderMask" />
										<div className="DashboardCardType">
											<Icon name="points" />
										</div>
									</div>
									<div className="DashboardCardBody">
										<span className="DashboardCardValue">{card.value}</span>
										<h3 className="DashboardCardDescription">{card.title}</h3>
									</div>
								</div>
							</div>
						</Col>
					))}
				</Row>
			</div>
		);
	}

	renderCards() {
		const { partners } = this.props;

		if (!partners) return null;

		return (
			<Col xs={24}>
				<ContentWrapper extraStyle={{ height: "100%" }}>
					<Row gutter={[20, 20]}>
						<Col xs={24}>{this.renderMonthlyCards()}</Col>
						<Col xs={24}>{this.renderAnnuallyCards()}</Col>
					</Row>
				</ContentWrapper>
			</Col>
		);
	}

	renderStoreLink() {
		const { partners, user } = this.props;

		if (!partners) return null;

		const hostname = window.location.hostname?.split(".")?.[0];
		let storeLink = `http://${user.partner?.store}.essenciadavida.pt`;
		if (hostname === "localhost") {
			storeLink = `http://${user.partner?.store}.edvdev.net`;
		} else {
			const parsedHostname = window.location.hostname.split(".")?.slice(1)?.join(".");
			storeLink = `${window.location.protocol}//${user.partner?.store}.${parsedHostname}`;
		}

		return (
			<React.Fragment>
				<Col xs={12}>
					<ContentWrapper extraStyle={{ height: "100%" }}>
						<div className="ScreenHeader">
							<div className="ScreenHeaderLeft">
								<Icon name="shop" />
								<h2>{Strings.dashboard.yourStoreLink}</h2>
							</div>
						</div>
						<Input.Group compact>
							<Input
								style={{ height: 40, width: "calc(100% - 95px)", fontWeight: 600 }}
								defaultValue={storeLink}
								readOnly
								onClick={(e) => e.currentTarget.select()}
							/>
							<Tooltip title={Strings.dashboard.copyStoreLink}>
								<Button
									icon={<CopyOutlined translate={null} />}
									style={{ width: 95 }}
									onClick={() => {
										navigator.clipboard.writeText(storeLink);
										notification.success({
											message: Strings.sidebar.dashboard,
											description: Strings.dashboard.storeLinkCopied,
											placement: "bottomRight",
											duration: 5
										});
									}}
								>
									{Strings.generic.copy}
								</Button>
							</Tooltip>
						</Input.Group>
					</ContentWrapper>
				</Col>
				<Col xs={12}>
					<ContentWrapper extraStyle={{ height: "100%" }}>
						<div className="ScreenHeader">
							<div className="ScreenHeaderLeft">
								<Icon name="wallet" />
								<h2>{Strings.users.balance}</h2>
							</div>
						</div>
						<p className="DashboardBalance">{formatPrice(user.partner?.balance || 0)}</p>
					</ContentWrapper>
				</Col>
			</React.Fragment>
		);
	}

	render() {
		const { data, graphTab } = this.state;

		const config = (duration: string) => {
			const graphData = duration === "userStatistics" ? data.userStatistics || [] : data.uses || [];

			return {
				data: graphData,
				xField: "day",
				yField: "value",
				seriesField: "name",
				yAxis: {
					label: {
						formatter: function formatter(v: string) {
							return "".concat(v).replace(/\d{1,3}(?=(\d{3})+$)/g, function (s) {
								return "".concat(s, ",");
							});
						}
					},
					visible: true,
					min: 0
				},
				appendPadding: 10,
				smooth: true,
				animation: {
					appear: {
						animation: "path-in",
						duration: 2500
					}
				}
			};
		};

		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.sidebar.dashboard}</title>
					<meta name="description" content="Description of Dashboard" />
				</Helmet>
				<div className="DashboardContent">
					<Row gutter={[20, 20]}>
						{this.renderStoreLink()}
						{this.renderCards()}
						<Col xs={24}>
							<ContentWrapper>
								<div className="DashboardMainChart">
									<div className="DashboardMainChartTitle">
										<Icon name={graphTab === "1" ? "users" : "mobile-app"} />
										<span>{graphTab === "1" ? Strings.dashboard.newUsers : Strings.dashboard.uses}</span>
									</div>
									<Tabs onChange={(key: string) => this.setState({ graphTab: key })} defaultActiveKey="1">
										<Tabs.TabPane tab={Strings.dashboard.newUsers} key="1" />
										<Tabs.TabPane tab={Strings.dashboard.uses} key="2" />
									</Tabs>
									<div className="Dashboard_Data_Charts">
										<div id="data1" className={`Dashboard_Generic_Chart${graphTab === "1" ? " __active" : ""}`}>
											<Line {...config("userStatistics")} />
										</div>
										<div id="data2" className={`Dashboard_Generic_Chart${graphTab === "2" ? " __active" : ""}`}>
											<Line {...config("uses")} />
										</div>
									</div>
								</div>
							</ContentWrapper>
						</Col>
					</Row>
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	selectedBusiness: state.selectedBusiness,
	language: state.language,
	user: state.user
});

export default connect(mapStateToProps)(Dashboard);
