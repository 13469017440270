import React, { Component } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import strings from "utils/strings";
import Icon from "components/Icon";
import { Checkbox } from "antd";

class SelectTags extends Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			value: props.value || [],
			options: props.options || [],
			search: "",
			focused: false
		};

		window.addEventListener("click", (e: any) => {
			const path = e.composedPath ? e.composedPath() : e.path;
			if (!path.includes(document.querySelector(`#SelectTags_${props.id}`))) this.setState({ focused: false });
		});
	}

	componentDidUpdate(prevProps: Readonly<any>): void {
		if (prevProps.value !== this.props.value) this.setState({ value: this.props.value });
		if (prevProps.options !== this.props.options) this.setState({ options: this.props.options });
	}

	componentWillUnmount(): void {
		window.removeEventListener("click", () => {});
	}

	formatTags() {
		const { value, options } = this.state;

		const tags: any[] = [];

		for (let tag of value) {
			const populatedTag = options.find((t: any) => t.value === tag);
			const index = tags.findIndex((t: any) => t.value === tag);
			if (index === -1) tags.push(populatedTag);
		}
	}

	render() {
		const { value, search, focused, options } = this.state;
		const { onChange, onClose, placeholder, label, required, id } = this.props;

		const inputStyles = {} as React.CSSProperties;
		if (!focused) inputStyles.opacity = 0;

		let formattedTags = JSON.parse(JSON.stringify(options)) || [];
		formattedTags = formattedTags.filter((tag: any) => {
			if (!value.includes(tag.value)) return false;
			tag.children = tag.children?.filter((child: any) => value.includes(child.value));
			return true;
		});

		return (
			<React.Fragment>
				{Boolean(label) && (
					<label htmlFor={`SelectTags_${id}`} className={`InputLabel${required ? " --label-required" : ""}`}>
						{label}
					</label>
				)}
				<div
					id={`SelectTags_${id}`}
					className="SelectTags"
					onClick={() => {
						if (!focused) this.setState({ focused: true });
						else if (document.activeElement?.id !== "select_tags_search") {
							this.setState({ focused: false });
						}
					}}
				>
					{!value.length && <span className="SelectTags__placeholder">{placeholder || strings.generic.placeholder}</span>}
					{formattedTags?.map((tag: any) => {
						return (
							<div id={`select_tag_${tag.key}`} key={tag.key} className="SelectTags__tag">
								<span>{tag.title}</span>
								<div className="SelectTags_tags">
									{tag.children?.map((child: any) => {
										return (
											<div
												id={`select_subtag_${child.key}`}
												key={child.key}
												className="SelectTags__tag SelectTags__subtag"
											>
												{child.title}
												<span
													className="SelectTags__close"
													onClick={(e) => {
														e.stopPropagation();

														if (typeof onClose === "function") onClose(child.value);
														onChange(value.filter((v: any) => v !== child.value));
													}}
													onMouseEnter={() => {
														const elem = document.getElementById(`select_subtag_${child.key}`);
														if (elem) elem.classList.add("SelectTag__animation_white");
													}}
													onMouseLeave={() => {
														const elem = document.getElementById(`select_subtag_${child.key}`);
														if (elem) elem.classList.remove("SelectTag__animation_white");
													}}
												>
													<Icon name="close" />
												</span>
											</div>
										);
									})}
								</div>
								<span
									className="SelectTags__close"
									onClick={(e) => {
										e.stopPropagation();

										if (typeof onClose === "function") onClose(tag.value);

										const children = tag.children?.map((child: any) => child.value);
										onChange(value.filter((v: any) => v !== tag.value && !children.includes(v)));
									}}
									onMouseEnter={() => {
										const elem = document.getElementById(`select_tag_${tag.key}`);
										if (elem) elem.classList.add("SelectTag__animation");
									}}
									onMouseLeave={() => {
										const elem = document.getElementById(`select_tag_${tag.key}`);
										if (elem) elem.classList.remove("SelectTag__animation");
									}}
								>
									<Icon name="close" />
								</span>
							</div>
						);
					})}
					{/* <div className="SelectTags__search">
						<input
							id="select_tags_search"
							value={search}
							onChange={(e) => this.setState({ search: e.target.value })}
							autoComplete="off"
							autoFocus={focused}
							type="search"
							style={inputStyles}
						/>
					</div> */}
					{focused && (
						<div className="SelectTags__options" onClick={(e) => e.stopPropagation()}>
							{options.map((option: any, index: number) => {
								return (
									<div className="SelectTags__option" key={index}>
										<Checkbox
											checked={value.includes(option.value)}
											onClick={(e) => {
												if (value.includes(option.value)) {
													const children = option.children?.map((child: any) => child.value);
													onChange(value.filter((v: any) => v !== option.value && !children.includes(v)));
												} else {
													onChange([...value, option.value]);
												}
											}}
										>
											{option.title}
										</Checkbox>
										<div className="SelectTags__children">
											{option.children?.map((child: any, index: number) => {
												return (
													<div className="SelectTags__option" key={index}>
														<Checkbox
															checked={value.includes(child.value)}
															onClick={(e) => {
																if (value.includes(child.value)) {
																	onChange(value.filter((v: any) => v !== child.value));
																} else {
																	if (!value.includes(option.value)) {
																		onChange([...value, option.value, child.value]);
																	} else {
																		onChange([...value, child.value]);
																	}
																}
															}}
														>
															{child.title}
														</Checkbox>
													</div>
												);
											})}
										</div>
									</div>
								);
							})}
						</div>
					)}
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language
});

export default connect(mapStateToProps)(SelectTags);
