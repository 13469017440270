// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root{--primary-color: #00b5b0;--light-primary: #19f1eb;--secondary-color: #22a8dd;--light-secondary: #b8e4f0;--app-background-color: whitesmoke;--logo-container-background: #151515;--text-color: #333;--gradient: linear-gradient(180deg, #00b5b0 1.04%, #19f1eb 100%);--gradient-border: linear-gradient(90deg, #00b5b0 1.04%, #19f1eb60 100%)}.PartnerTree{height:calc(100% + 25px);overflow:hidden;margin:-25px -25px 0;position:relative}.PartnerTree .PartnerSearch{position:absolute;top:5px;right:5px}.PartnerTree .PartnerSearch input{height:40px}.PartnerTree .PartnerEmailSearch{position:absolute;top:5px;right:265px}.PartnerTree .PartnerEmailSearch input{height:40px}.PartnerTree .PartnerNode{display:flex;flex-direction:column;gap:5px;background:#f5f5f5}.PartnerTree .NodeObject{display:flex;flex-direction:column;align-items:center;text-align:center;border-radius:5px;gap:5px}.PartnerTree .NodeObject .NodeInfo{display:flex;flex-direction:column;flex:1}.PartnerTree .NodeObject .NodeInfo span{min-width:130px;padding:5px;border-radius:4px;font-size:14px;font-weight:600;line-height:1.2}.PartnerTree .NodeObject>.NodeImage{border:3px solid #ccc;border-radius:50%}.PartnerTree .NodeObject>.NodeImage img{height:50px;width:50px;border-radius:50%;border:3px solid #fff}.PartnerTree .NodeObject .TreeChildren{display:flex;align-items:center;justify-content:center;line-height:1;font-size:12px;color:#00b5b0}.PartnerTree .NodeObject .NodeImageBlock{justify-content:center;background-image:linear-gradient(180deg, #00b5b0 1.04%, #19f1eb 100%);height:70px}.PartnerTree .NodeObject .NodeImageBlock img{height:65px}.PartnerTree .AdditionalNodes{opacity:0;background-color:#f5f5f5;border:none;cursor:pointer}.PartnerTree .AdditionalNodes .AdditionalNodeIcon svg{font-size:14px;color:#00b5b0}.PartnerTree .AdditionalNodes.--visible{opacity:1}.PartnerTree svg>g>path{stroke:#9e9e9e}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#00b5b0",
	"lightPrimary": "#19f1eb",
	"secondaryColor": "#22a8dd",
	"lightSecondary": "#b8e4f0"
};
module.exports = exports;
