// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root{--primary-color: #00b5b0;--light-primary: #19f1eb;--secondary-color: #22a8dd;--light-secondary: #b8e4f0;--app-background-color: whitesmoke;--logo-container-background: #151515;--text-color: #333;--gradient: linear-gradient(180deg, #00b5b0 1.04%, #19f1eb 100%);--gradient-border: linear-gradient(90deg, #00b5b0 1.04%, #19f1eb60 100%)}.isUserMenu{height:30px !important}.AdjustUserMenu:not(.active){margin-bottom:0 !important}.SidebarUserLogo{position:relative;z-index:2;width:30px;min-width:30px;height:30px;min-height:30px;border-radius:50%;background-position:center;background-size:cover;background-repeat:no-repeat}.SidebarMenuOptions{display:flex;justify-content:space-between;margin:0 10px;transition:.2s;user-select:none}.SidebarMenuOptions.__forceWidth{width:200px}.SidebarMenuOptions.__mobile{flex-direction:column}.SidebarMenuOptions .SidebarMenuOption{display:flex;align-items:center;padding:10px;font-size:20px;border-radius:4px;cursor:pointer;transition:.2s}.SidebarMenuOptions .SidebarMenuOption span{line-height:12px}.SidebarMenuOptions .SidebarMenuOption:hover{background-color:rgba(0,0,0,.3137254902)}.SidebarNavName.__normal{line-height:1.5 !important;display:flex !important;flex-direction:column;text-transform:none;font-weight:700}.SidebarPartnerRow{display:flex;align-items:center;gap:20px;margin-top:5px}.SidebarPartnerRow .SidebarPartnerPoints{display:flex;align-items:center;gap:5px;color:#00b5b0;font-weight:700}.SidebarPartnerRow .SidebarPartnerPoints span{display:block;font-size:10px;font-weight:700;margin-top:4px;line-height:1}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#00b5b0",
	"lightPrimary": "#19f1eb",
	"secondaryColor": "#22a8dd",
	"lightSecondary": "#b8e4f0"
};
module.exports = exports;
