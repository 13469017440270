import React from "react";
import { connect } from "react-redux";
import type { Category, Props, State } from "./types";
import { push, replace } from "connected-react-router";
import { delayedDispatch, setBreadcrumb, setLoader, updateCrumb } from "store/actions";
import { Helmet } from "react-helmet";
import { ContentWrapper, Icon, Table } from "components";
import { Col, Drawer, Input, notification, Row, Select, Switch } from "antd";
import { LANGUAGES, removeDiacritics, translate } from "utils/utils";
import { API, Endpoints } from "utils/api";
import Dropzone from "react-dropzone";
import Strings from "utils/strings";
import "./styles.scss";
import Compressor from "compressorjs";

class CategoryDetail extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			category: null,
			isActive: false,
			name: null,
			image: null,
			pos: 0,
			seo: "",
			language: "pt",
			hasUnsavedFields: false,
			isNew: props.match.params.id === "new",
			openDrawer: false,
			seoUrlLoading: false,
			partnerOnly: false
		};
	}

	componentDidMount() {
		this.getData();
		this.breadcrumb();
	}

	breadcrumb() {
		delayedDispatch(
			setBreadcrumb(() => {
				const { category } = this.state;

				return {
					locations: [
						{
							icon: "support-tickets",
							text: Strings.sidebar.categories,
							route: "/categories"
						},
						{
							icon: category?._id ? "pencil-outline" : "plus",
							text: translate(category?.name) || "new"
						}
					],
					actions: [
						{
							type: "switch",
							text: Strings.generic.active,
							value: this.state.isActive,
							small: {
								switch: true,
								text: true
							},
							separator: "right",
							onClick: (value: boolean) => this.setState({ isActive: value, hasUnsavedFields: true })
						},
						{
							type: "language",
							value: this.state.language,
							showArrow: true,
							showSearch: false,
							separator: "right",
							onChange: (value: string) => this.setState({ language: value })
						},
						{
							type: "button",
							text: Strings.generic.save,
							disabled: !this.state.hasUnsavedFields,
							className: "BreadcrumbSaveButton",
							isSave: true,
							hasIcon: true,
							onClick: () => this.saveCategory()
						}
					]
				};
			})
		);
	}

	componentDidUpdate() {
		const { dispatch } = this.props;
		dispatch(updateCrumb());
	}

	async getData() {
		const { dispatch, match } = this.props;

		if (!match?.params.id || match?.params.id === "new") return;

		dispatch(setLoader(true));

		try {
			const response = await API.get({
				url: Endpoints.uriCategories(match?.params.id)
			});

			if (response.ok) {
				const { category } = response.data.results || {};
				this.setState({ category, ...category });
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});

			dispatch(push("/categories"));
		}

		dispatch(setLoader(false));
	}

	async getSeoUrl(name: string, subCategory?: boolean) {
		this.setState({ seoUrlLoading: true });

		try {
			const response = await API.post({
				url: Endpoints.uriSeoUrl(),
				data: {
					seo: removeDiacritics(name),
					table: "categories"
				}
			});

			if (response?.ok) {
				const { seo } = response.data.results || {};
				if (subCategory) {
					this.setState((prevState) => ({
						tempCategory: { ...prevState.tempCategory, seo }
					}));
				} else {
					this.setState({ seoUrlLoading: false, seo });
				}
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		this.setState({ seoUrlLoading: false });
	}

	async saveCategory() {
		const { isActive, name, image, isNew, seo, partnerOnly } = this.state;
		const { dispatch, match } = this.props;

		if (!this.isCategoryValid()) return;

		dispatch(setLoader(true));

		try {
			const body = new FormData();
			body.append("isActive", String(isActive));
			body.append("name", JSON.stringify(name));
			body.append("seo", JSON.stringify(seo));
			body.append("partnerOnly", String(partnerOnly));

			if (image && Object.keys(image).length > 0) {
				if (image.file) {
					body.append("image", image.file);
				} else {
					body.append("image", image);
				}
			}

			const request = isNew ? API.post : API.put;
			const response = await request({
				url: Endpoints.uriCategories(isNew ? "" : match?.params?.id),
				data: body
			});

			if (response?.ok) {
				const { category } = response.data.results || {};
				if (isNew) {
					dispatch(replace(`/categories/${category._id}`));
					dispatch(setBreadcrumb(null));
					this.breadcrumb();
				}

				await this.getData();
				this.setState({ isNew: false });

				notification.success({
					message: Strings.sidebar.categories,
					description: response?.data?.message || (isNew ? Strings.categories.created : Strings.categories.updated),
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.sidebar.categories,
					description: response.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async toggleSubCategory(category: Category) {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.patch({
				url: Endpoints.uriCategories(`subcategories/${category._id}`),
				data: { isActive: !category.isActive }
			});

			if (response.ok) {
				await this.getData();

				notification.success({
					message: Strings.sidebar.categories,
					description: response?.data?.message,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.sidebar.categories,
					description: response.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async deleteSubCategory(id: string) {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.delete({
				url: Endpoints.uriCategories(`subcategories/${id}`)
			});

			if (response.ok) {
				await this.getData();

				notification.success({
					message: Strings.sidebar.categories,
					description: response?.data?.message,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.sidebar.categories,
					description: response.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async saveSubCategory() {
		const { tempCategory, category } = this.state;
		const { dispatch } = this.props;

		if (!tempCategory) return;

		if (!translate(tempCategory?.name)) {
			notification.error({
				message: Strings.serverErrors.title,
				description: Strings.categories.nameRequired,
				placement: "bottomRight",
				duration: 5
			});
			return;
		}

		dispatch(setLoader(true));

		try {
			tempCategory!.parent = category!._id;
			tempCategory.isActive = true;

			const request = tempCategory._id ? API.put : API.post;
			const response = await request({
				url: Endpoints.uriCategories(`subcategories/${tempCategory._id || ""}`),
				data: tempCategory
			});

			if (response.ok) {
				this.setState({ tempCategory: undefined, openDrawer: false });
				await this.getData();

				notification.success({
					message: Strings.sidebar.categories,
					description: response?.data?.message || Strings.categories.subCategoryCreated,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.sidebar.categories,
					description: response.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	onDrag = async () => {
		const { subCategories, hoverIndex } = this.state;
		const { dispatch } = this.props;

		const item = subCategories![hoverIndex!];
		const itemId = item?._id;

		if (!itemId || item.pos === hoverIndex) return;

		dispatch(setLoader(true));

		try {
			const response = await API.patch({
				url: Endpoints.uriCategories(`subcategories/${itemId}/position`),
				data: { pos: hoverIndex, parent: item.parent }
			});

			if (response.ok) {
				await this.getData();

				notification.success({
					message: Strings.products.categories,
					description: response?.data?.message || Strings.products.categoryUpdated,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.products.categories,
					description: response.data?.message,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	};

	getBase64(file: any) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});
	}

	async onDrop(files: any) {
		try {
			const file = files[files.length - 1];

			new Compressor(file, {
				quality: 0.9,
				maxWidth: 400,
				mimeType: "image/jpeg",
				success: (result: any) => {
					this.getBase64(result).then((res) => {
						this.setState({
							image: {
								file: result,
								preview: res
							},
							hasUnsavedFields: true
						});
					});
				}
			});
		} catch (err) {
			notification.warn({
				message: Strings.errors.unsupportedFile,
				description: Strings.errors.fileNotSupported,
				placement: "bottomRight",
				duration: 5
			});
		}
	}

	isCategoryValid() {
		const { name, seo } = this.state;

		if (!translate(name)) {
			notification.warning({
				message: Strings.errors.invalidFields,
				description: Strings.categories.nameMissing,
				placement: "bottomRight",
				duration: 5
			});

			return false;
		}

		if (!seo?.trim()) {
			notification.warning({
				message: Strings.errors.invalidFields,
				description: Strings.generic.seoMissing,
				placement: "bottomRight",
				duration: 5
			});

			return false;
		}

		return true;
	}

	renderCategoryInformation() {
		const { name, image, language, seo, seoUrlLoading, partnerOnly } = this.state;

		return (
			<ContentWrapper>
				<Row gutter={[20, 20]}>
					<Col xs={24}>
						<div className="ScreenHeader">
							<div className="ScreenHeaderLeft">
								<Icon name="delivery-truck" />
								<h2>{Strings.categories.category}</h2>
							</div>
						</div>
					</Col>
					<Col xs={24} md={12}>
						<div className="CategoryMainImage">
							<Dropzone
								accept="image/jpg, image/jpeg, image/png"
								className="GenericImageUpload"
								onDrop={(files: any) => this.onDrop(files)}
							>
								{image ? (
									<div
										className="CategoryImage"
										style={{
											backgroundImage: `url('${image.preview || image}')` || "none",
											backgroundSize: "contain"
										}}
									/>
								) : (
									<div className={`GenericImageUploadOverlay${!image ? " --visible" : ""}`}>
										<Icon name="frame" />
										<span>{Strings.generic.changeImage}</span>
									</div>
								)}
								{image && (
									<button
										onClick={(e: React.MouseEvent<HTMLElement>) => {
											e.stopPropagation();
											this.setState({ image: null, hasUnsavedFields: true });
										}}
										className="GenericImageDelete"
									>
										<Icon name="close" />
									</button>
								)}
							</Dropzone>
						</div>
					</Col>
					<Col xs={24} md={12}>
						<Row gutter={[20, 20]}>
							<Col xs={24}>
								<label htmlFor="category_name" className="InputLabel --label-required">
									{Strings.fields.name}
								</label>
								<Input
									id="category_name"
									value={name?.[language] || ""}
									placeholder={Strings.fields.name}
									onChange={(event: any) => {
										const value = event.target.value;

										this.setState((prevState: State) => ({
											name: {
												...prevState.name,
												[language]: value
											},
											hasUnsavedFields: true
										}));
									}}
									onBlur={() => {
										if (!seo && translate(name)) {
											const seo = removeDiacritics(name?.["pt"] || translate(name));
											this.getSeoUrl(seo);
										}
									}}
								/>
							</Col>
							<Col xs={24}>
								<label htmlFor="category_seo" className="InputLabel --label-required">
									{Strings.fields.seoUrl}
								</label>
								<Input
									id="category_seo"
									value={seo || ""}
									readOnly={seoUrlLoading}
									placeholder={Strings.fields.seoUrl}
									onChange={(event: any) => {
										const value = event.target.value;
										this.setState({ seo: value, hasUnsavedFields: true });
									}}
									onBlur={() => {
										if (seo) {
											this.getSeoUrl(seo);
										}
									}}
								/>
							</Col>
							<Col xs={24}>
								<div className={`General_ColorFul_Switch ${partnerOnly ? "__active" : ""}`}>
									<span>{Strings.fields.exclusivePartners}</span>
									<Switch
										className={`Switch ${partnerOnly ? "__active" : ""}`}
										checked={partnerOnly || false}
										size="small"
										onChange={(value: any) => this.setState({ partnerOnly: value, hasUnsavedFields: true })}
									/>
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
			</ContentWrapper>
		);
	}

	renderSubCategories() {
		const { isNew, subCategories = [], parent } = this.state;

		if (parent || isNew) return null;

		return (
			<Table
				title={{
					icon: "package",
					title: Strings.categories.subCategories
				}}
				data={subCategories}
				columns={[
					{
						Header: Strings.fields.name,
						id: "name",
						accessor: (row: any) => translate(row.name) || "-"
					}
				]}
				filterable
				paginated={false}
				isSinglePage
				add={{
					tooltip: Strings.products.addCategory,
					onClick: () => this.setState({ openDrawer: true, tempCategory: {} })
				}}
				actions={{
					edit: (obj: any) => ({
						onClick: () =>
							this.setState({
								openDrawer: true,
								tempCategory: JSON.parse(JSON.stringify(obj))
							})
					}),
					toggle: (obj: any) => ({
						value: obj.isActive,
						onChange: () => this.toggleSubCategory(obj)
					}),
					remove: (obj: any) => ({
						onClick: () => this.deleteSubCategory(obj._id)
					})
				}}
				draggable
				onDrag={async (list: any, dragIndex: any, hoverIndex: any) => {
					this.setState({ subCategories: list, dragIndex, hoverIndex });
				}}
				onDragEnd={this.onDrag}
			/>
		);
	}

	renderDrawer() {
		const { tempCategory, openDrawer } = this.state;
		const { mobile } = this.props;

		return (
			<Drawer
				title={
					<div className="SidebarTitleContainer">
						<Icon name="tax-settings" />
						<p>{tempCategory?._id ? Strings.categories.editSubCategory : Strings.categories.addSubCategory}</p>
					</div>
				}
				footer={
					<div className="SidebarFooterContainer">
						<button type="button" className="SidebarFooterButton --button-confirm" onClick={() => this.saveSubCategory()}>
							{Strings.generic.confirm}
						</button>
						<button
							type="button"
							className="SidebarFooterButton --button-cancel"
							onClick={() => this.setState({ openDrawer: false, tempCategory: undefined })}
						>
							{Strings.generic.cancel}
						</button>
					</div>
				}
				placement="right"
				closable={false}
				onClose={() => this.setState({ openDrawer: false, tempCategory: undefined })}
				visible={openDrawer}
				width={mobile ? "100%" : 400}
			>
				{this.renderDrawerContent()}
			</Drawer>
		);
	}

	renderDrawerContent() {
		const { tempCategory, language, seoUrlLoading } = this.state;

		return (
			<Row gutter={[20, 10]}>
				<Col xs={24}>
					<div className="DrawerLanguageWrapper">
						<Select
							style={{ minWidth: 50 }}
							value={language}
							onChange={(elem: any) => {
								this.setState({ language: elem });
							}}
						>
							{LANGUAGES.map((lang: any, index: number) => {
								return (
									<Select.Option key={`select_option_${lang}_${index}`} value={lang.value}>
										{lang.label}
									</Select.Option>
								);
							})}
						</Select>
					</div>
				</Col>
				<Col xs={24}>
					<label htmlFor="sub_category_name" className="InputLabel --label-required">
						{Strings.fields.name}
					</label>
					<Input
						id="sub_category_name"
						value={tempCategory?.name?.[language] || ""}
						placeholder={Strings.fields.name}
						onChange={(event: any) => {
							const value = event.target.value;

							this.setState((prevState: State) => ({
								tempCategory: {
									...prevState.tempCategory,
									name: {
										...prevState.tempCategory?.name,
										[language]: value
									}
								}
							}));
						}}
						onBlur={() => {
							if (!tempCategory?.seo && translate(tempCategory?.name)) {
								const seo = removeDiacritics(tempCategory?.name?.["pt"] || translate(tempCategory?.name));
								this.getSeoUrl(seo, true);
							}
						}}
					/>
				</Col>
				<Col xs={24}>
					<label htmlFor="subcategory_seo" className="InputLabel --label-required">
						{Strings.fields.seoUrl}
					</label>
					<Input
						id="subcategory_seo"
						value={tempCategory?.seo || ""}
						readOnly={seoUrlLoading}
						placeholder={Strings.fields.seoUrl}
						onChange={(event: any) => {
							const value = event.target.value;

							this.setState((prevState: State) => ({
								tempCategory: {
									...prevState.tempCategory,
									seo: value
								}
							}));
						}}
						onBlur={() => {
							if (tempCategory?.seo) {
								this.getSeoUrl(tempCategory?.seo, true);
							}
						}}
					/>
				</Col>
			</Row>
		);
	}

	render() {
		return (
			<div className="CategoryDetail">
				<Helmet>
					<title>{Strings.categories.single}</title>
					<meta name="description" content="Edit your category" />
				</Helmet>
				{this.renderCategoryInformation()}
				{this.renderSubCategories()}
				{this.renderDrawer()}
			</div>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language,
	mobile: state.mobile
});

export default connect(mapStateToProps)(CategoryDetail);
