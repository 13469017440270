import React, { Component } from "react";
import { Col, Table, Row, notification } from "antd";
import { ContentWrapper, Icon } from "components";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { delayedDispatch, setBreadcrumb, setLoader, setTitle, updateCrumb } from "store/actions";
import { API, Endpoints } from "utils/api";
import strings from "utils/strings";
import { formatPrice, getSuccessLevelBadge, xlsxSettings } from "utils/utils";
import xlsx from "json-as-xlsx";

class Special extends Component<any, any> {
	searchTimeout: NodeJS.Timeout | undefined;

	constructor(props: any) {
		super(props);

		this.state = {
			data: [],
			partner: undefined,
			partnerOptions: [],
			partnerSearch: ""
		};
	}

	async componentDidMount(): Promise<void> {
		const { dispatch } = this.props;

		dispatch(setTitle(""));

		await this.getData();
		this.breadcrumb();
	}

	componentDidUpdate(): void {
		const { dispatch } = this.props;
		dispatch(updateCrumb());
	}

	async getData() {
		const { partner } = this.state;
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		const body = {
			type: "special",
			partner
		};

		try {
			const response = await API.post({
				url: Endpoints.uriCommissions("search-by-type"),
				data: body
			});

			if (response.ok) {
				const { partners = [], partner, eligible = false, settings } = response.data.results;

				this.setState({ data: partners, partnerDetail: partner, eligible, settings });
			} else {
				notification.error({
					message: strings.commissions.special,
					description: response?.data?.message || strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: strings.serverErrors.title,
				description: err as string,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	handleSearch(partner: any) {
		this.setState({ partnerSearch: partner });

		if (partner.length < 3) return;

		if (this.searchTimeout) clearTimeout(this.searchTimeout);

		this.searchTimeout = setTimeout(async () => {
			this.getUsers();
		}, 500);
	}

	async getUsers() {
		const { partnerSearch } = this.state;

		try {
			const body = { search: partnerSearch, page: 0, perPage: 10 };

			const response = await API.post({
				url: Endpoints.uriPartners("search"),
				data: body
			});

			if (response.ok) {
				const { users = [] } = response.data.results || {};
				this.setState({ partnerOptions: users });
			} else {
				notification.error({
					message: strings.commissions.special,
					description: response?.data?.message || strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: strings.serverErrors.title,
				description: err as string,
				placement: "bottomRight",
				duration: 5
			});
		}
	}

	breadcrumb() {
		delayedDispatch(
			setBreadcrumb(() => {
				const { startDate, endDate, partnerOptions } = this.state;

				return {
					locations: [
						{
							text: strings.commissions.special,
							icon: "organization"
						}
					],
					actions: [
						{
							type: "select",
							text: strings.fields.partner,
							placeholder: strings.generic.searchByEmailOrName,
							options: partnerOptions.map((partner: any) => ({
								value: partner._id,
								text: `${partner.name} (${partner.email})`
							})),
							onChange: (partner: any) => {
								this.setState({ partner }, () => this.getData());
							},
							value: this.state.partner,
							showSearch: true,
							onSearch: (value: string) => this.handleSearch(value),
							style: { width: 300, height: 40 },
							allowClear: true
						}
					]
				};
			})
		);
	}

	exportData = () => {
		const { data: exportData = [] } = this.state;

		const columns = [
			{ label: "Parceiro", value: (row: any) => `${row.name} (#${row.userCode})` },
			{ label: "Nível de Sucesso", value: (row: any) => getSuccessLevelBadge(row?.partner?.successLevel) },
			{ label: "Total de Vezes Diamante", value: "totalDiamondRankTimes" },
			{ label: "Total de Meses como Diamante", value: "totalMonthsAsDiamond" },
			{ label: "Total de Valor Recebido", value: "totalAmountEarned" }
		];

		const data = [
			{
				sheet: "Recompensa Especial",
				columns,
				content: exportData
			}
		];

		xlsx(data, xlsxSettings("Recompensa Especial"));
	};

	renderMovements() {
		const { data = [] } = this.state;
		const { countries } = this.props;

		return (
			<ContentWrapper>
				<div className="ScreenHeader --multi">
					<div className="ScreenHeaderLeft">
						<Icon name="diagram" />
						<h2>{strings.commissions.special}</h2>
					</div>
					<div className="ScreenHeaderRight">
						<button className="EthicAddButton" onClick={this.exportData}>
							<Icon name="print" />
						</button>
					</div>
				</div>
				<Table
					style={{ marginTop: 20 }}
					columns={[
						{
							title: strings.fields.partner,
							dataIndex: "name",
							key: "name",
							render: (_: any, record: any) => {
								const countryCallingCode = record.phone?.substring(0, 4).replace("+", "");
								let country;

								if (countryCallingCode) {
									country = countries
										.find((elem: any) => elem.callingCodes.includes(countryCallingCode))
										?.alpha2Code?.toLowerCase();
								}

								return (
									<span className="TableRowAligned">
										{country ? (
											<div className="react-tel-input">
												<div className="selected-flag">
													<span className={`flag ${country}`} />
												</div>
											</div>
										) : null}
										{record.name}
										{Boolean(record?.userCode) ? ` (#${record.userCode})` : ""}
									</span>
								);
							},
							sorter: (a, b) => a.name?.localeCompare(b.name)
						},
						{
							title: strings.users.partnerRanking,
							dataIndex: "successLevel",
							key: "successLevel",
							render: (_: any, record: any) => getSuccessLevelBadge(record?.partner?.successLevel) || "-",
							sorter: (a, b) => a.partner?.successLevel?.localeCompare(b.partner?.successLevel)
						},
						{
							title: strings.commissions.totalTimesDiamond,
							dataIndex: "totalDiamondRankTimes",
							key: "totalDiamondRankTimes",
							render: (totalDiamondRankTimes: number) => totalDiamondRankTimes || 0,
							sorter: (a, b) => a.totalDiamondRankTimes - b.totalDiamondRankTimes
						},
						{
							title: strings.commissions.totalMonthsAsDiamond,
							dataIndex: "totalMonthsAsDiamond",
							key: "totalMonthsAsDiamond",
							render: (totalMonthsAsDiamond: number) => totalMonthsAsDiamond || 0,
							sorter: (a, b) => a.totalMonthsAsDiamond - b.totalMonthsAsDiamond
						},
						{
							title: strings.commissions.totalAmountEarned,
							dataIndex: "totalAmountEarned",
							key: "totalAmountEarned",
							render: (totalAmountEarned: number) => formatPrice(totalAmountEarned),
							sorter: (a, b) => a.totalAmountEarned - b.totalAmountEarned
						}
					]}
					dataSource={data}
					rowKey={(record) => record._id}
				/>
			</ContentWrapper>
		);
	}

	renderPartner() {
		const { partner, partnerDetail, eligible, settings } = this.state;
		const { bonuses } = settings || {};
		const { special } = bonuses || {};

		if (!partner || !special) return null;

		return (
			<Row gutter={[20, 20]}>
				{!eligible && (
					<Col xs={24}>
						<ContentWrapper>
							<div className="ScreenHeader">
								<div className="ScreenHeaderLeft">
									<Icon name="user" />
									<h2>{strings.commissions.eligibility}</h2>
								</div>
							</div>
							<p>
								<strong>
									{strings.commissions.actualRank}: {getSuccessLevelBadge(partnerDetail?.partner?.successLevel)}
								</strong>
							</p>
							<p className="CommissionsPartnersAttention">{strings.commissions.notEligibleSpecial}</p>
						</ContentWrapper>
					</Col>
				)}
				<Col xs={24}>
					<ContentWrapper>
						<div className="ScreenHeader">
							<div className="ScreenHeaderLeft">
								<Icon name="points" />
								<h2>{strings.fields.description}</h2>
							</div>
						</div>
						<p className="CommissionsPartnersAttention">{strings.commissions.specialDescription}</p>
					</ContentWrapper>
				</Col>
				<Col xs={24}>
					<ContentWrapper>
						<div className="ScreenHeader">
							<div className="ScreenHeaderLeft">
								<Icon name="points" />
								<h2>{strings.commissions.special}</h2>
							</div>
						</div>
						<Row>
							<Col xs={24} md={12}>
								<table className="CommissionTpvTable">
									<thead>
										<tr>
											<th>{strings.settings.successLevel}</th>
											<th>{strings.generic.bonus}</th>
										</tr>
									</thead>
									<tbody>
										{Object.entries(special)?.map((entry: any, index: number) => {
											const rank = partnerDetail?.partner?.rewards?.[entry[0]];
											const reached = rank?.achieved;

											const style: React.CSSProperties = {};
											if (reached) {
												style.backgroundColor = "#00b5b033";
												style.fontWeight = 600;
												style.color = "#00b5b0";
											}

											return (
												<tr key={index} style={style}>
													<td>{getSuccessLevelBadge(entry[0])}</td>
													<td>{formatPrice(entry[1])}</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</Col>
						</Row>
					</ContentWrapper>
				</Col>
			</Row>
		);
	}

	render() {
		const { partner } = this.state;

		console.log("partner", partner);

		return (
			<div className="CommissionSpecial">
				<Helmet>
					<title>{strings.commissions.special}</title>
					<meta name="description" content="Description of special commissions" />
				</Helmet>
				{!Boolean(partner) && this.renderMovements()}
				{Boolean(partner) && this.renderPartner()}
			</div>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language,
	countries: state.countries
});

export default connect(mapStateToProps)(Special);
