import React from "react";
import { connect } from "react-redux";
import { Tabs, notification } from "antd";
import { setLoader } from "store/actions";
import { store } from "store";
import { API, Endpoints } from "utils/api";
import { Helmet } from "react-helmet";
import { ContentWrapper, File as FileComponent } from "components";
import { FileUnknownOutlined } from "@ant-design/icons";
import Strings from "utils/strings";
import { HelpFile } from "screens/Admin/HelpFiles/types";

type State = {
	files?: [{ _id: string; files: HelpFile[] }?];
	selectedTab: string;
};
type Props = {
	language: string;
	dispatch: typeof store.dispatch;
};

class Files extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			files: [],
			selectedTab: "product"
		};
	}

	componentDidMount(): void {
		this.getData();
	}

	async getData() {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.get({
				url: Endpoints.uriFiles()
			});

			if (response?.ok) {
				const { files = [] } = response.data.results || {};
				this.setState({ files });
			} else {
				notification.error({
					message: Strings.serverErrors.title,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err: unknown) {
			notification.error({
				message: Strings.serverErrors.title,
				description: err as string,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	renderGallery() {
		const { files: apiFiles = [], selectedTab } = this.state;
		const filteredFiles = apiFiles.filter((arr) => arr?._id === selectedTab);

		return (
			<ContentWrapper>
				<div className="ScreenHeader">
					<div className="ScreenHeaderLeft">
						<FileUnknownOutlined translate={null} />
						<h2>{Strings.settings.files}</h2>
					</div>
				</div>
				<Tabs defaultActiveKey="1" activeKey={selectedTab} onChange={(key: string) => this.setState({ selectedTab: key })}>
					<Tabs.TabPane tab={Strings.settings.topicProduct} key="product" />
					<Tabs.TabPane tab={Strings.settings.topicBusiness} key="business" />
					<Tabs.TabPane tab={Strings.settings.other} key="other" />
				</Tabs>
				{filteredFiles.map((filesArr, i) => {
					const { files = [] } = filesArr!;

					return (
						<div key={i} className="FileGrid">
							{files.map((file) => {
								return <FileComponent key={file._id} readonly {...file} />;
							})}
						</div>
					);
				})}
				{filteredFiles.length === 0 && (
					<div className="FileLevelHeader --stripped">
						<h3>{Strings.settings.noFilesPartner}</h3>
					</div>
				)}
			</ContentWrapper>
		);
	}

	render() {
		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.settings.files}</title>
					<meta name="description" content="Gallery of support files/videos" />
				</Helmet>
				{this.renderGallery()}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: { language: string }) => ({
	language: state.language
});

export default connect(mapStateToProps)(Files);
