// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root{--primary-color: #00b5b0;--light-primary: #19f1eb;--secondary-color: #22a8dd;--light-secondary: #b8e4f0;--app-background-color: whitesmoke;--logo-container-background: #151515;--text-color: #333;--gradient: linear-gradient(180deg, #00b5b0 1.04%, #19f1eb 100%);--gradient-border: linear-gradient(90deg, #00b5b0 1.04%, #19f1eb60 100%)}.LoginWrapper{display:flex;flex-direction:column;width:100%;height:100%;background-image:linear-gradient(180deg, #efebeb 1.04%, #7c7c7c 100%);background-position:center;background-size:cover;background-repeat:no-repeat;background-color:#fff;overflow:hidden auto}.LoginWrapper .LoginLogo{margin-top:100px;display:flex;align-items:center;width:100%;height:150px;margin-left:10%;font-size:35px}.LoginWrapper .LoginLogo img{height:150px}.LoginWrapper .LoginContent{display:flex;flex-direction:column;justify-content:center;height:calc(100% - 150px);width:100%;margin-left:10%}.LoginWrapper .LoginContent .LoginWelcome{font-size:20px;font-weight:700;color:#fff}.LoginWrapper .LoginContent .LoginForgotPassword{font-size:12px;margin-top:5px;display:flex;justify-content:flex-end;color:#fff}.LoginWrapper .LoginContent .isLink{cursor:pointer}.LoginWrapper .LoginContent .isLink:hover{color:#00b5b0}.LoginWrapper .LoginContent .LoginButton{margin-top:30px;width:100%;background-color:#00b5b0}.LoginWrapper .LoginContent form{margin-top:20px;width:350px}.LoginWrapper .LoginContent form em{color:#00b5b0;font-weight:700}.LoginWrapper.__isMobile{padding:0 20px}.LoginWrapper.__isMobile .LoginLogo{margin-left:0}.LoginWrapper.__isMobile .LoginLogo img{height:80px}.LoginWrapper.__isMobile .LoginLogo,.LoginWrapper.__isMobile .LoginContent{margin-left:0}.LoginWrapper.__isMobile form{width:100%}.authyButton{display:flex;justify-content:center;align-items:center;margin-top:20px}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#00b5b0",
	"lightPrimary": "#19f1eb",
	"secondaryColor": "#22a8dd",
	"lightSecondary": "#b8e4f0"
};
module.exports = exports;
