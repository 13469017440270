import React, { Component } from "react";
import { ContentWrapper, Icon } from "components";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import strings from "utils/strings";
import "./styles.scss";

class pendingRegistration extends Component {
	render() {
		return (
			<div className="PartnerPendingRegistration">
				<Helmet>
					<title>{strings.users.pendingRegistration}</title>
					<meta name="description" content="Partner pending registration" />
				</Helmet>
				<ContentWrapper>
					<div className="ScreenHeader">
						<div className="ScreenHeaderLeft">
							<Icon name="warning" />
							<h2>{strings.users.pendingRegistration}</h2>
						</div>
					</div>
					<div className="PendingRegistration">
						<p>{strings.users.pendingRegistrationMessage}</p>
					</div>
				</ContentWrapper>
			</div>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language
});

export default connect(mapStateToProps)(pendingRegistration);
