import React, { Component } from "react";
import { Col, DatePicker, Drawer, Row, Select, notification } from "antd";
import { ContentWrapper, Icon, Table } from "components";
import { DateTime } from "luxon";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { setLoader, setTitle } from "store/actions";
import { API, Endpoints } from "utils/api";
import strings from "utils/strings";
import "./styles.scss";
import { formatPrice } from "utils/utils";
import { push } from "connected-react-router";
import moment from "moment";
import pt from "antd/lib/date-picker/locale/pt_PT";
import en from "antd/lib/date-picker/locale/en_US";
import { debounce } from "lodash";

const { RangePicker } = DatePicker;

class Commissions extends Component<any, any> {
	constructor(props: any) {
		super(props);

		if (this.props.language === "pt") {
			moment.updateLocale("pt", {
				week: {
					dow: 1 /// Date offset
				}
			});
		} else {
			moment.updateLocale("en", {
				week: {
					dow: 1 /// Date offset
				}
			});
		}

		this.state = {
			commissions: [],
			page: 0,
			pageSize: 100,
			total: 0,
			columnSearch: null,
			globalSearch: "",

			filters: {
				startDate: moment().startOf("month"),
				endDate: moment().endOf("month")
			},
			tempFilters: {
				startDate: moment().startOf("month"),
				endDate: moment().endOf("month")
			},
			showDrawer: false,
			destiny: undefined,
			origin: undefined
		};
	}

	componentDidMount(): void {
		const { dispatch } = this.props;

		dispatch(setTitle(strings.sidebar.commissions));

		this.getData();
	}

	async getData(pause?: boolean) {
		const { page, pageSize, columnSearch, globalSearch, filters, origin, destiny } = this.state;
		const { dispatch, countries = [] } = this.props;

		setTimeout(
			async () => {
				if (columnSearch !== this.state.columnSearch || globalSearch !== this.state.globalSearch) return;
				dispatch(setLoader(true));

				const body = {
					filters: columnSearch
						? Object.keys(columnSearch).map((elem) =>
								columnSearch[elem] !== ""
									? {
											field: elem,
											query: columnSearch[elem]
									  }
									: {}
						  )
						: [],
					page: page,
					perPage: pageSize,
					search: globalSearch,
					startDate: filters?.startDate.toISOString(),
					endDate: filters?.endDate.toISOString(),
					origin,
					destiny
				};

				try {
					const response = await API.post({
						url: Endpoints.uriCommissions("search"),
						data: body
					});

					if (response.ok) {
						const { commissions = [], redeemedValue, pendingValue, points, expiredValue, total = 0 } = response.data.results;

						for (const commission of commissions) {
							if (commission.origin) {
								const countryCallingCode = commission.origin?.phone?.substring(0, 4).replace("+", "");
								const country = countries.find((elem: any) => elem.callingCodes.includes(countryCallingCode))?.alpha2Code;

								if (country) {
									commission.origin.country = country.toLowerCase();
								}
							}

							if (commission.partner) {
								const countryCallingCode = commission.partner?.phone?.substring(0, 4).replace("+", "");
								const country = countries.find((elem: any) => elem.callingCodes.includes(countryCallingCode))?.alpha2Code;

								if (country) {
									commission.partner.country = country.toLowerCase();
								}
							}
						}

						this.setState({
							commissions,
							redeemedValue,
							pendingValue,
							points,
							expiredValue,
							total
						});
					} else {
						notification.error({
							message: strings.sidebar.commissions,
							description: response?.data?.message || strings.serverErrors.wentWrong,
							placement: "bottomRight",
							duration: 5
						});
					}
				} catch (err) {
					notification.error({
						message: strings.serverErrors.title,
						description: err as string,
						placement: "bottomRight",
						duration: 5
					});
				}

				dispatch(setLoader(false));
			},
			pause ? 1000 : 0
		);
	}

	applyFilters() {
		const { tempFilters } = this.state;

		this.setState(
			(prevState: any) => ({
				filters: { ...prevState.filters, startDate: tempFilters.startDate, endDate: tempFilters.endDate },
				showDrawer: false
			}),
			() => this.getData()
		);
	}

	get pagination() {
		return {
			total: this.state.total,
			pageIndex: this.state.page,
			pageSize: this.state.pageSize,
			setPage: (page: number, size: number) => {
				this.setState({ page, pageSize: size }, () => this.getData());
			}
		};
	}

	get filtration() {
		const { globalSearch, columnSearch } = this.state;
		return {
			showGlobalSearch: true,
			showColumnSearch: true,
			defaultValues: { globalSearch, columnSearch },
			onGlobalSearch: (globalSearch: string) => {
				this.setState({ globalSearch, page: 0 }, () => this.getData());
			},
			onColumnSearch: (columnSearch: string) => {
				this.setState({ columnSearch, page: 0 }, () => this.getData());
			}
		};
	}

	getType(type: string) {
		switch (type) {
			case "commissions":
				return strings.sidebar.commissions;
			case "bonus":
				return strings.generic.bonus;
			case "sales":
				return strings.orders.sales;
			default:
				return type;
		}
	}

	debounceFetcher = debounce(async (type: string, value: string) => {
		const body = { search: value, page: 0, perPage: 10 };

		const res = await API.post({ url: Endpoints.uriPartners("search"), data: body });
		if (res?.ok) {
			const { users = [] } = res.data.results;
			if (type === "origin") this.setState({ originOptions: users });
			else this.setState({ destinyOptions: users });
		}
	}, 800);

	handleSearch = (type: string, value: string) => {
		if (value) {
			this.debounceFetcher(type, value);
			if (type === "origin") this.setState({ searchOriginParams: value });
			else this.setState({ searchPartnerParams: value });
		} else {
			this.setState({ originOptions: [], destinyOptions: [], searchOriginParams: "", searchPartnerParams: "" });
		}
	};

	renderNumber(value: number) {
		if (value > 0) {
			return <span className="CommissionValueGreen">{value}</span>;
		} else if (value < 0) {
			return <span className="CommissionValueRed">{value}</span>;
		}

		return <span>{value}</span>;
	}

	renderValue(value: number) {
		if (value > 0) {
			return <span className="CommissionValueGreen">{formatPrice(value)}</span>;
		} else if (value < 0) {
			return <span className="CommissionValueRed">{formatPrice(value)}</span>;
		}

		return <span>{value}</span>;
	}

	renderCommissionRedeemed = (redeemed: boolean) => {
		if (redeemed) {
			return (
				<div className="TableTag --tag-success">
					<span>{strings.generic.yes}</span>
				</div>
			);
		}

		return (
			<div className="TableTag --tag-warning">
				<span>{strings.generic.no}</span>
			</div>
		);
	};

	renderDrawer() {
		const { showDrawer } = this.state;
		const { mobile } = this.props;

		return (
			<Drawer
				title={
					<div className="SidebarTitleContainer">
						<Icon name="organization" />
						<p>{strings.fields.selectFilters}</p>
					</div>
				}
				footer={
					<div className="SidebarFooterContainer">
						<button type="button" className="SidebarFooterButton --button-confirm" onClick={() => this.applyFilters()}>
							{strings.generic.confirm}
						</button>
						<button
							type="button"
							className="SidebarFooterButton --button-cancel"
							onClick={() => this.setState({ showDrawer: false, tempFilters: {} })}
						>
							{strings.generic.cancel}
						</button>
					</div>
				}
				placement="right"
				closable={false}
				onClose={() => this.setState({ showDrawer: false, tempFilters: {} })}
				visible={showDrawer}
				width={mobile ? "100%" : 600}
			>
				{this.renderDrawerContent()}
			</Drawer>
		);
	}

	renderDrawerContent() {
		const {
			tempFilters,
			searchOriginParams = "",
			searchDestinyParams = "",
			originOptions = [],
			destinyOptions = [],
			origin = undefined,
			destiny = undefined
		} = this.state;
		const { language } = this.props;

		return (
			<Row gutter={[20, 20]}>
				<Col xs={24}>
					<label className="InputLabel" htmlFor="period">
						{strings.dashboard.period}
					</label>
					<RangePicker
						id="period"
						style={{ width: "100%" }}
						locale={language === "pt" ? pt : en}
						allowEmpty={[false, false]}
						ranges={{
							[strings.ranges.today]: [moment(), moment()],
							[strings.ranges.thisMonth]: [moment().startOf("month"), moment().endOf("month")],
							[strings.ranges.lastMonth]: [
								moment().subtract(1, "month").startOf("month"),
								moment().subtract(1, "month").endOf("month")
							],
							[strings.ranges.thisYear]: [moment().startOf("year"), moment().endOf("month")],
							[strings.ranges.lastYear]: [
								moment().subtract(1, "year").startOf("year"),
								moment().subtract(1, "year").endOf("year")
							]
						}}
						placeholder={[strings.fields.startDate, strings.fields.endDate]}
						separator={
							<span className="__turn90">
								<Icon name="arrow-up" />
							</span>
						}
						value={[tempFilters?.startDate, tempFilters?.endDate]}
						onChange={(value: any) =>
							this.setState({ tempFilters: { ...tempFilters, startDate: value[0], endDate: value[1] } })
						}
						allowClear={false}
					/>
				</Col>
				<Col xs={24}>
					<label htmlFor="origin" className="InputLabel">
						{strings.generic.from}
					</label>
					<Select
						id="origin"
						style={{ width: "100%", height: 40 }}
						placeholder={strings.generic.from}
						filterOption={false}
						showSearch
						onSearch={(value) => this.handleSearch("origin", value)}
						value={origin}
						onChange={(elem: any) => {
							this.setState({ origin: elem });
						}}
						onClear={() =>
							this.setState({
								origin: undefined,
								originOptions: [],
								searchOriginParams: "",
								hasUnsavedFields: true
							})
						}
						notFoundContent={
							<div>{searchOriginParams === "" ? strings.generic.searchByEmailOrName : strings.generic.noResults}</div>
						}
						allowClear={true}
					>
						{originOptions?.map((partner: any) => (
							<Select.Option value={partner._id} key={partner._id}>
								{partner.name} ({partner.email})
							</Select.Option>
						))}
					</Select>
				</Col>
				<Col xs={24}>
					<label htmlFor="destiny" className="InputLabel">
						{strings.generic.destiny}
					</label>
					<Select
						id="destiny"
						style={{ width: "100%", height: 40 }}
						placeholder={strings.generic.from}
						filterOption={false}
						showSearch
						onSearch={(value) => this.handleSearch("destiny", value)}
						value={destiny}
						onChange={(elem: any) => {
							this.setState({ destiny: elem });
						}}
						onClear={() =>
							this.setState({
								destiny: undefined,
								destinyOptions: [],
								searchDestinyParams: "",
								hasUnsavedFields: true
							})
						}
						notFoundContent={
							<div>{searchDestinyParams === "" ? strings.generic.searchByEmailOrName : strings.generic.noResults}</div>
						}
						allowClear
					>
						{destinyOptions?.map((partner: any) => (
							<Select.Option value={partner._id} key={partner._id}>
								{partner.name} ({partner.email})
							</Select.Option>
						))}
					</Select>
				</Col>
			</Row>
		);
	}

	render() {
		const { commissions = [], filters, redeemedValue = 0, pendingValue = 0, points = 0, expiredValue = 0 } = this.state;
		const { dispatch } = this.props;

		const CARDS = [
			{
				icon: "wallet",
				value: redeemedValue,
				description: strings.commissions.redeemedValue,
				type: "currency"
			},
			{
				icon: "wallet",
				value: pendingValue,
				description: strings.commissions.pendingValue,
				type: "currency"
			},
			{
				icon: "points",
				value: points,
				description: strings.commissions.redeemedPoints
			},
			{
				icon: "wallet",
				value: expiredValue,
				description: strings.commissions.expiredValue,
				type: "currency"
			}
		];

		return (
			<React.Fragment>
				<Helmet>
					<title>{strings.sidebar.commissionsAndBonuses}</title>
					<meta name="description" content="Check all commissions and bonuses" />
				</Helmet>
				<div className="AdminCommissions">
					<div className="AdminCommissionCards">
						<Row gutter={[20, 20]}>
							{CARDS.map((card, index) => (
								<Col xs={24} md={6} key={index}>
									<div className="AdminCommissionCardOuterContainer">
										<div className="AdminCommissionCard">
											<div className="AdminCommissionCardHeader">
												<div className="AdminCommissionCardHeaderMask" />
												<div className="AdminCommissionCardType">
													<Icon name={card.icon} />
												</div>
											</div>
											<div className="AdminCommissionCardBody">
												<span className="AdminCommissionCardValue">
													{card.type === "currency" ? formatPrice(card.value) : card.value}
												</span>
												<h3 className="AdminCommissionCardDescription">{card.description}</h3>
											</div>
										</div>
									</div>
								</Col>
							))}
						</Row>
					</div>
					<div className="CommissionsTable">
						<Table
							title={{
								icon: "organization",
								title: strings.sidebar.commissionsAndBonuses
							}}
							data={commissions}
							headerOptions={[
								{
									icon: "filter",
									label: strings.generic.filters,
									onClick: () =>
										this.setState({
											showDrawer: true,
											tempFilters: {
												startDate: filters?.startDate,
												endDate: filters?.endDate
											}
										})
								}
							]}
							columns={[
								{
									Header: strings.fields.date,
									id: "date",
									accessor: (row: any) => row.date && DateTime.fromISO(row.date).toFormat("dd/MM/yyyy"),
									maxWidth: 150,
									Filter: () => null
								},
								{
									Header: strings.settings.type,
									id: "type",
									accessor: (row: any) => this.getType(row.type) || "-",
									Filter: () => null,
									maxWidth: 150
								},
								{
									Header: strings.products.notes,
									id: "notes",
									accessor: (row: any) => row.notes || "-",
									Filter: () => null
								},
								{
									Header: strings.generic.from,
									id: "origin",
									Cell: (row: any) =>
										row.cell.row.original?.origin?.name ? (
											<div className="react-tel-input">
												{row.cell.row.original?.origin?.phone ? (
													<div className="selected-flag">
														<span className={`flag ${row.cell.row.original?.origin?.country}`} />
														<span className="partner-name partner-has-flag">
															{row.cell.row.original?.origin?.name}
															{row.cell.row.original?.origin?.userCode != null
																? ` (#${row.cell.row.original?.origin?.userCode})`
																: ""}
														</span>
													</div>
												) : (
													<span className="partner-name">
														{row.cell.row.original?.origin?.name}
														{row.cell.row.original?.origin?.userCode != null
															? ` (#${row.cell.row.original?.origin?.userCode})`
															: ""}
													</span>
												)}
											</div>
										) : (
											"-"
										),
									Filter: () => null
								},
								{
									Header: strings.generic.destiny,
									id: "partner",
									Cell: (row: any) =>
										row.cell.row.original?.partner?.name ? (
											<div className="react-tel-input">
												{row.cell.row.original?.partner?.phone ? (
													<div className="selected-flag">
														<span className={`flag ${row.cell.row.original?.partner?.country}`} />
														<span className="partner-name partner-has-flag">
															{row.cell.row.original?.partner?.name}
															{row.cell.row.original?.partner?.userCode != null
																? ` (#${row.cell.row.original?.partner?.userCode})`
																: ""}
														</span>
													</div>
												) : (
													<span className="partner-name">
														{row.cell.row.original?.partner?.name}
														{row.cell.row.original?.partner?.userCode != null
															? ` (#${row.cell.row.original?.partner?.userCode})`
															: ""}
													</span>
												)}
											</div>
										) : (
											"-"
										),
									Filter: () => null
								},
								{
									Header: strings.products.points,
									id: "points",
									Cell: (row: any) =>
										row.cell.row.original?.points != null ? this.renderNumber(row.cell.row.original?.points) : "-",
									Filter: () => null,
									maxWidth: 100,
									align: "right"
								},
								{
									Header: strings.settings.value,
									id: "value",
									Cell: (row: any) =>
										row.cell.row.original?.value != null ? this.renderValue(row.cell.row.original?.value) : "-",
									Filter: () => null,
									maxWidth: 100,
									align: "right"
								}
							]}
							filterable
							fullPage
							isSinglePage
							paginated
							paginationApi={this.pagination}
							filtrationApi={this.filtration}
							add={{
								tooltip: strings.sidebar.commissions,
								onClick: () => dispatch(push("/commissions/view/new"))
							}}
							actions={{
								view: (obj: any) => ({
									onClick: () => dispatch(push(`/commissions/view/${obj._id}`))
								})
							}}
						/>
					</div>
				</div>
				{this.renderDrawer()}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language,
	countries: state.countries
});

export default connect(mapStateToProps)(Commissions);
