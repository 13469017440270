import React, { Component } from "react";
import { Col, Table, Row, notification } from "antd";
import { ContentWrapper, Icon } from "components";
import { DateTime } from "luxon";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { delayedDispatch, setBreadcrumb, setLoader, setTitle, updateCrumb } from "store/actions";
import { API, Endpoints } from "utils/api";
import strings from "utils/strings";
import { formatPrice, xlsxSettings } from "utils/utils";
import moment from "moment";
import { Area } from "@ant-design/charts";
import xlsx from "json-as-xlsx";

class Binary extends Component<any, any> {
	searchTimeout: NodeJS.Timeout | undefined;

	constructor(props: any) {
		super(props);

		this.state = {
			data: [],
			startDate: moment().add({ months: -5 }).startOf("month"),
			endDate: moment().endOf("month"),
			partner: undefined,
			partnerOptions: [],
			partnerSearch: ""
		};
	}

	async componentDidMount(): Promise<void> {
		const { dispatch } = this.props;

		dispatch(setTitle(""));

		await this.getData();
		this.breadcrumb();
	}

	componentDidUpdate(): void {
		const { dispatch } = this.props;
		dispatch(updateCrumb());
	}

	async getData() {
		const { startDate, endDate, partner } = this.state;
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		const body = {
			startDate: startDate.toISOString(),
			endDate: endDate.toISOString(),
			type: "binary",
			partner
		};

		try {
			const response = await API.post({
				url: Endpoints.uriCommissions("search-by-type"),
				data: body
			});

			if (response.ok) {
				const { commissions = [], cards, commissionsChart, currentCards, eligible = false } = response.data.results;

				this.setState({
					data: commissions,
					cards,
					commissionsChart,
					currentCards,
					eligible
				});
			} else {
				notification.error({
					message: strings.commissions.sales,
					description: response?.data?.message || strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: strings.serverErrors.title,
				description: err as string,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	handleSearch(partner: any) {
		this.setState({ partnerSearch: partner });

		if (partner.length < 3) return;

		if (this.searchTimeout) clearTimeout(this.searchTimeout);

		this.searchTimeout = setTimeout(async () => {
			this.getUsers();
		}, 500);
	}

	async getUsers() {
		const { partnerSearch } = this.state;

		try {
			const body = { search: partnerSearch, page: 0, perPage: 10 };

			const response = await API.post({
				url: Endpoints.uriPartners("search"),
				data: body
			});

			if (response.ok) {
				const { users = [] } = response.data.results || {};
				this.setState({ partnerOptions: users });
			} else {
				notification.error({
					message: strings.commissions.sales,
					description: response?.data?.message || strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: strings.serverErrors.title,
				description: err as string,
				placement: "bottomRight",
				duration: 5
			});
		}
	}

	breadcrumb() {
		delayedDispatch(
			setBreadcrumb(() => {
				const { startDate, endDate, partnerOptions } = this.state;

				return {
					locations: [
						{
							text: strings.commissions.binary,
							icon: "organization"
						}
					],
					actions: [
						{
							type: "select",
							text: strings.fields.partner,
							placeholder: strings.generic.searchByEmailOrName,
							options: partnerOptions.map((partner: any) => ({
								value: partner._id,
								text: `${partner.name} (${partner.email})`
							})),
							onChange: (partner: any) => {
								this.setState({ partner }, () => this.getData());
							},
							value: this.state.partner,
							showSearch: true,
							onSearch: (value: string) => this.handleSearch(value),
							separator: "right",
							style: { width: 300, height: 40 },
							allowClear: true
						},
						{
							type: "datePicker",
							text: strings.dashboard.period,
							dates: [startDate, endDate],
							monthly: true,
							onChange: this.handleSelect,
							className: "fixedPicker"
						}
					]
				};
			})
		);
	}

	handleSelect = (dates: any) => {
		const newDates = [];
		if (dates && dates[0]) {
			newDates.push(moment(dates[0]).startOf("day"));
		}

		if (dates && dates[1]) {
			newDates.push(moment(dates[1]).endOf("day"));
		}

		this.setState(
			{
				startDate: newDates[0],
				endDate: newDates[1]
			},
			() => this.getData()
		);
	};

	exportData = () => {
		const { data: exportData = [], partner, partnerOptions } = this.state;

		const columns = [
			{ label: "Data", value: (row: any) => DateTime.fromISO(row.date).toFormat("dd/MM/yyyy HH:mm") },
			{ label: "Ciclos", value: (row: any) => row.cycles },
			{ label: "Valor da Comissão", value: (row: any) => formatPrice(row.value) },
			{
				label: "Data de Expiração",
				value: (row: any) => (row.expirationDate ? DateTime.fromISO(row.expirationDate).toFormat("dd/MM/yyyy HH:mm") : "-")
			}
		];

		if (!partner) {
			columns.splice(1, 0, { label: "Parceiro", value: (row: any) => `${row.partner.name} (#${row.partner.userCode})` });
		}

		const data = [
			{
				sheet: "Comissões de Ciclos Binários",
				columns,
				content: exportData
			}
		];

		let name = "Comissões de Ciclos Binários";
		if (partner) {
			const partnerData = partnerOptions.find((elem: any) => elem._id === partner);
			if (partnerData) name = partnerData.name;
		}

		xlsx(data, xlsxSettings(name));
	};

	renderMovements() {
		const { data = [], partner } = this.state;
		const { countries = [] } = this.props;

		const columns: any[] = [
			{
				title: strings.fields.date,
				dataIndex: "date",
				key: "date",
				render: (date: string) => DateTime.fromISO(date).toFormat("dd/MM/yyyy HH:mm"),
				defaultSortOrder: "descend",
				sorter: (a: any, b: any) => DateTime.fromISO(a.date).toMillis() - DateTime.fromISO(b.date).toMillis(),
				width: 150
			},
			{
				title: strings.commissions.cycles,
				dataIndex: "cycles",
				key: "cycles",
				render: (cycles: number) => cycles || "-",
				sorter: (a: any, b: any) => a.cycles - b.cycles,
				width: 100
			},
			{
				title: strings.commissions.value,
				dataIndex: "value",
				key: "value",
				render: (value: number) => formatPrice(value),
				sorter: (a: any, b: any) => a.value - b.value,
				width: 150
			},
			{
				title: strings.generic.expirationDate,
				dataIndex: "expirationDate",
				key: "expirationDate",
				render: (value: string) => (value ? DateTime.fromISO(value).toFormat("dd/MM/yyyy HH:mm") : "-"),
				sorter: (a: any, b: any) => DateTime.fromISO(a._created).toMillis() - DateTime.fromISO(b._created).toMillis(),
				width: 150
			}
		];

		if (!partner) {
			columns.splice(1, 0, {
				title: strings.fields.partner,
				dataIndex: "partner",
				key: "partner",
				render: (partner: any) => {
					if (!partner) return "-";
					const countryCallingCode = partner.phone?.substring(0, 4).replace("+", "");
					let country;

					if (countryCallingCode) {
						country = countries.find((elem: any) => elem.callingCodes.includes(countryCallingCode))?.alpha2Code?.toLowerCase();
					}

					return (
						<span className="TableRowAligned">
							{country ? (
								<div className="react-tel-input">
									<div className="selected-flag">
										<span className={`flag ${country}`} />
									</div>
								</div>
							) : null}
							{partner.name}
							{Boolean(partner?.userCode) ? ` (#${partner.userCode})` : ""}
						</span>
					);
				}
			});
		}

		return (
			<ContentWrapper>
				<div className="ScreenHeader --multi">
					<div className="ScreenHeaderLeft">
						<Icon name="refresh" />
						<h2>{strings.wallet.movements}</h2>
					</div>
					<div className="ScreenHeaderRight">
						<button onClick={this.exportData} className="EthicAddButton">
							<Icon name="print" />
						</button>
					</div>
				</div>
				<Table style={{ marginTop: 20 }} columns={columns} dataSource={data} rowKey={(record) => record._id} />
			</ContentWrapper>
		);
	}

	renderChart() {
		const { commissionsChart = [] } = this.state;

		const config = {
			data: commissionsChart,
			xField: "month",
			yField: "cycles",
			appendPadding: 10,
			smooth: true,
			animation: {
				appear: {
					animation: "path-in",
					duration: 0
				}
			},
			meta: {
				total: {
					alias: strings.commissions.cycles
				}
			},
			tooltip: {
				formatter: (datum: any) => {
					return { name: strings.commissions.cycles, value: datum.cycles };
				}
			}
		};

		return (
			<ContentWrapper extraStyle={{ marginBottom: 0 }}>
				<div className="ScreenHeader">
					<div className="ScreenHeaderLeft">
						<Icon name="refresh" />
						<h2>{strings.commissions.cycles}</h2>
					</div>
				</div>
				<Area style={{ marginTop: 20 }} {...config} />
			</ContentWrapper>
		);
	}

	renderCards() {
		const { cards, partner } = this.state;
		const { cycles = 0, available = 0, spent = 0, expired = 0, total = 0 } = cards || {};

		const CARDS = [
			{
				icon: "refresh",
				value: cycles,
				description: strings.commissions.totalCycles
			},
			{
				icon: "wallet",
				value: available,
				description: strings.wallet.available,
				type: "currency"
			},
			{
				icon: "wallet",
				value: spent,
				description: strings.wallet.spent,
				type: "currency"
			},
			{
				icon: "wallet",
				value: expired,
				description: strings.wallet.expired,
				type: "currency"
			}
		];

		if (partner) {
			CARDS.splice(1, 0, {
				icon: "wallet",
				value: total,
				description: strings.commissions.withThisBonus,
				type: "currency"
			});
		}

		return CARDS.map((card, index) => (
			<Col xs={24} md={6} key={index}>
				<div className="IconCardOuterContainer">
					<div className="IconCard">
						<div className="IconCardHeader">
							<div className="IconCardHeaderMask" />
							<div className="IconCardType">
								<Icon name={card.icon} />
							</div>
						</div>
						<div className="IconCardBody">
							<span className="IconCardValue">{card.type === "currency" ? formatPrice(card.value) : card.value}</span>
							<h3 className="IconCardDescription">{card.description}</h3>
						</div>
					</div>
				</div>
			</Col>
		));
	}

	renderBinaryCards() {
		const { currentCards } = this.state;
		const { cycles = 0, leftTgv = 0, rightTgv = 0, total = 0 } = currentCards || {};

		const CARDS = [
			{
				icon: "points",
				value: leftTgv,
				description: strings.commissions.leftTgv
			},
			{
				icon: "points",
				value: rightTgv,
				description: strings.commissions.rightTgv
			},
			{
				icon: "refresh",
				value: cycles,
				description: strings.commissions.cycles
			},
			{
				icon: "wallet",
				value: total,
				description: strings.commissions.earned,
				type: "currency"
			}
		];

		return CARDS.map((card, index) => (
			<Col xs={24} md={6} key={index}>
				<div className="IconCardOuterContainer">
					<div className="IconCard">
						<div className="IconCardHeader">
							<div className="IconCardHeaderMask" />
							<div className="IconCardType">
								<Icon name={card.icon} />
							</div>
						</div>
						<div className="IconCardBody">
							<span className="IconCardValue">{card.type === "currency" ? formatPrice(card.value) : card.value}</span>
							<h3 className="IconCardDescription">{card.description}</h3>
						</div>
					</div>
				</div>
			</Col>
		));
	}

	render() {
		const { eligible, partner } = this.state;

		return (
			<div className="CommissionPartners">
				<Helmet>
					<title>{strings.commissions.binary}</title>
					<meta name="description" content="Description of commission of binary cycles" />
				</Helmet>
				<div className="IconCards">
					<Row gutter={[20, 20]}>
						{Boolean(partner) && !eligible && (
							<Col xs={24}>
								<ContentWrapper>
									<div className="ScreenHeader">
										<div className="ScreenHeaderLeft">
											<Icon name="user" />
											<h2>{strings.commissions.eligibility}</h2>
										</div>
									</div>
									<p className="CommissionsPartnersAttention">{strings.commissions.notEligible}</p>
								</ContentWrapper>
							</Col>
						)}
						{this.renderCards()}
						<Col xs={24}>{this.renderChart()}</Col>
						{this.renderBinaryCards()}
						<Col xs={24}>{this.renderMovements()}</Col>
					</Row>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language,
	countries: state.countries
});

export default connect(mapStateToProps)(Binary);
