import { Button, Checkbox, Col, Input, Modal, Row, Select, Switch, notification } from "antd";
import { ContentWrapper, Icon, PhoneInput } from "components";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { delayedDispatch, setBreadcrumb, setLoader, setTitle, setUser, updateCrumb } from "store/actions";
import { API, Endpoints } from "utils/api";
import CreditCard from "react-credit-cards";
import strings from "utils/strings";
import { formatPrice, translate } from "utils/utils";
import mbwayLogo from "assets/images/mbway.png";
import cc from "assets/images/cc.png";
import visa from "assets/images/visa.png";
import mastercard from "assets/images/mastercard.png";
import placeholder from "assets/images/placeholders/image.jpg";
import "react-credit-cards/es/styles-compiled.css";
import "./styles.scss";
import { push } from "connected-react-router";

class PartnerKits extends Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			products: [],
			defaultProducts: [],
			shippingMethods: [],
			user: JSON.parse(JSON.stringify(props.user)),
			defaultCountries: [],
			changingAddress: false,
			mbway: props?.user?.mbway || props?.user?.phone || "",
			cardInfo: {
				name: "",
				number: "",
				expiry: { month: "", year: "" },
				cvc: "",
				focused: ""
			},
			url: "",
			confirmed: false
		};
	}

	async componentDidMount() {
		const { dispatch } = this.props;

		dispatch(setTitle(""));

		await this.getData();
		this.breadcrumb();
	}

	componentDidUpdate() {
		const { dispatch } = this.props;

		dispatch(updateCrumb());
	}

	async getData() {
		const { user: stateUser } = this.state;
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const [response, responseContries, responsePaymentMethods] = await Promise.all([
				API.post({
					url: Endpoints.uriProducts("search"),
					data: {
						search: "",
						page: 0,
						perPage: 100,
						filters: [],
						isKit: true
					}
				}),
				API.get({
					url: Endpoints.uriCountries("?states=true")
				}),
				API.get({
					url: Endpoints.uriUsers("payment-methods")
				})
			]);

			let defaultProducts = [],
				products = [],
				defaultCountries = [],
				defaultStates = [],
				paymentMethods = [],
				user = stateUser;

			if (response?.ok) {
				const product = response.data?.results?.products?.[0];
				const selectedProducts = product
					? [{ ...JSON.parse(JSON.stringify(product)), product: product._id, quantity: 1, total: product.price }]
					: [];

				defaultProducts = response.data?.results?.products || [];
				products = selectedProducts;
			} else {
				notification.error({
					message: strings.sidebar.orders,
					description: response.data?.message || strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}

			if (responseContries?.ok) {
				const { countries = [] } = responseContries.data.results;
				const states: any[] = [];

				countries.forEach((country: any) => (states[country._id] = country?.states || []));

				for (const address of stateUser.addresses) {
					const tempCountry = countries.find((c: any) => c._id === address.country);
					if (tempCountry) {
						address.country = tempCountry;
						address.state = tempCountry.states?.find((s: any) => s._id === address.state);
					}
				}

				defaultCountries = countries;
				defaultStates = states;
				user = { ...stateUser, addresses: stateUser.addresses };
			} else {
				notification.error({
					message: strings.sidebar.orders,
					description: responseContries.data?.message || strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}

			if (responsePaymentMethods?.ok) {
				paymentMethods = responsePaymentMethods.data?.results?.paymentMethods || [];
			} else {
				notification.error({
					message: strings.sidebar.orders,
					description: responsePaymentMethods.data?.message || strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}

			this.setState({
				defaultProducts,
				products,
				defaultCountries,
				defaultStates,
				paymentMethods,
				user
			});
		} catch (err) {
			notification.error({
				message: strings.sidebar.orders,
				description: (err as string) || strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async getPaymentMethods() {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.get({
				url: Endpoints.uriUsers("payment-methods")
			});

			if (response?.ok) {
				const { paymentMethods = [] } = response.data.results || {};
				this.setState({ paymentMethods });
			} else {
				notification.error({
					message: strings.sidebar.orders,
					description: response.data?.message || strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: strings.sidebar.orders,
				description: (err as string) || strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async calculateTotal() {
		const { products, user, selectedShippingAddress, shippingMethods = [], selectedShippingMethod } = this.state;
		const { addresses = [] } = user || {};
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		let method;
		for (const m of shippingMethods) {
			for (const mm of m.methods) {
				if (mm._id === selectedShippingMethod) {
					method = mm;
					break;
				}
			}
		}

		const body = {
			products: products?.map((prod: any) => ({ ...prod, product: prod._id })) || [],
			isPickup: false,
			shipping: {
				address: addresses?.find((a: any) => a._id === selectedShippingAddress),
				method
			}
		} as any;

		try {
			const response = await API.post({
				url: Endpoints.uriOrders("calculate"),
				data: body
			});

			if (response?.ok) {
				const {
					products: newProducts = [],
					total = 0,
					shipping: shippingMethods,
					totalShipping = 0,
					totalProducts = 0,
					points = 0
				} = response.data.results || {};

				for (const product of newProducts) {
					product._id = product.product;
				}

				this.setState({
					products: JSON.parse(JSON.stringify(newProducts)),
					total,
					totalShipping,
					totalProducts,
					points,
					shippingMethods
				});
			} else {
				notification.error({
					message: strings.sidebar.orders,
					description: response.data?.message || strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: strings.sidebar.orders,
				description: (err as string) || strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async saveAddress() {
		const { tempAddress, user, defaultCountries } = this.state;
		const { dispatch } = this.props;

		if (!tempAddress || !this.validAddress()) return;

		dispatch(setLoader(true));

		try {
			const body = { ...tempAddress };
			delete body._id;

			const request = tempAddress?._id ? API.put : API.post;
			const response = await request({
				url: Endpoints.uriUsers(`${user?._id}/addresses/${tempAddress?._id || ""}`),
				data: body
			});

			if (response.ok) {
				const {
					user: { addresses }
				} = response.data.results || {};

				dispatch(setUser({ ...user, addresses }));

				for (const address of addresses) {
					const tempCountry = defaultCountries.find((c: any) => c._id === address.country);
					if (tempCountry) {
						address.country = tempCountry;
						address.state = tempCountry.states?.find((s: any) => s._id === address.state);
					}
				}

				this.setState((prevState: any) => ({
					user: {
						...prevState.user,
						addresses
					},
					tempAddress: null,
					changingAddress: false
				}));

				notification.success({
					message: strings.users.addresses,
					description: response.data?.message,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: strings.users.addresses,
					description: response.data?.message || strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: strings.serverErrors.title,
				description: (err as string) || strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async submitOrder() {
		const {
			products,
			user,
			paymentType,
			selectedShippingMethod,
			selectedShippingAddress,
			selectedPaymentAddress,
			paymentStatus,
			shippingMethods = [],
			total
		} = this.state;
		const { addresses = [] } = user || {};
		const { dispatch } = this.props;

		if (!this.validOrder()) return;

		dispatch(setLoader(true));

		let method, provider;
		for (const m of shippingMethods) {
			for (const mm of m.methods) {
				if (mm._id === selectedShippingMethod) {
					provider = m;
					method = mm;
					break;
				}
			}
		}

		const body = {
			products: products?.map((prod: any) => ({ ...prod, product: prod._id, kitLevel: prod.kitLevel })) || [],
			shipping: {
				address: addresses?.find((a: any) => a._id === selectedShippingAddress),
				method,
				provider
			},
			payment: {
				address: addresses?.find((a: any) => a._id === selectedPaymentAddress),
				paymentType
			},
			paymentStatus,
			user,
			total,
			isKit: true
		} as any;

		try {
			const response = await API.post({
				url: Endpoints.uriOrders(),
				data: body
			});

			if (response?.ok) {
				const { order } = response.data.results || {};

				if (order?._id) {
					try {
						const response = await API.get({ url: Endpoints.uriStaff("me") });
						if (response?.ok) {
							const { staff } = response.data.results || {};
							const staffCopy = JSON.parse(JSON.stringify(staff));

							dispatch(setUser(staffCopy));
							if (staffCopy?.partner?.pendingRegistration) {
								setTimeout(() => dispatch(push(`/partner-pending-registration`)), 1000);
							} else {
								setTimeout(() => dispatch(push(`/partner-dashboard`)), 1000);
							}
						}
					} catch (err) {
						console.log("API Error", err);
					}

					notification.success({
						message: strings.sidebar.orders,
						description: response?.data?.message,
						placement: "bottomRight",
						duration: 5
					});
				}
			} else {
				notification.error({
					message: strings.sidebar.orders,
					description: response.data?.message || strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: strings.sidebar.orders,
				description: (err as string) || strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		setTimeout(() => dispatch(setLoader(false)), 750);
	}

	async submitCreditCard() {
		const { cardInfo } = this.state;
		const { dispatch } = this.props;

		if (
			!cardInfo.name?.trim() ||
			!cardInfo.number?.trim() ||
			!cardInfo.expiry.month?.trim() ||
			!cardInfo.expiry.year?.trim() ||
			!cardInfo.cvc?.trim()
		) {
			notification.error({
				message: strings.orders.paymentMethod,
				description: strings.errors.pleaseFillFormCorrectly,
				placement: "bottomRight",
				duration: 5
			});
			return;
		}

		if (cardInfo.number.length < 16) {
			notification.error({
				message: strings.orders.paymentMethod,
				description: strings.orders.paymentMethodsInvalidCardNumber,
				placement: "bottomRight",
				duration: 5
			});
			return;
		}

		if (cardInfo.cvc.length < 3) {
			notification.error({
				message: strings.orders.paymentMethod,
				description: strings.orders.paymentMethodsInvalidCVC,
				placement: "bottomRight",
				duration: 5
			});
			return;
		}

		const year = new Date().getFullYear().toString();
		if (
			cardInfo.expiry.month.length < 2 ||
			cardInfo.expiry.year.length < 4 ||
			+cardInfo.expiry.month > 12 ||
			+cardInfo.expiry.year < +year
		) {
			notification.error({
				message: strings.orders.paymentMethod,
				description: strings.orders.paymentMethodsInvalidExpiry,
				placement: "bottomRight",
				duration: 5
			});
			return;
		}

		dispatch(setLoader(true));

		try {
			const response = await API.post({
				url: Endpoints.uriUsers("payment-method"),
				data: {
					cardInfo: {
						card_number: cardInfo.number,
						card_holder: cardInfo.name,
						card_expiration_month: cardInfo.expiry.month,
						card_expiration_year: cardInfo.expiry.year,
						card_cvv: cardInfo.cvc
					}
				}
			});

			if (response?.ok) {
				const { url } = response.data.results;

				if (!url) {
					notification.success({
						message: strings.orders.paymentMethod,
						description: response.data?.message,
						placement: "bottomRight",
						duration: 5
					});

					this.setState(
						{
							cardInfo: { name: "", number: "", expiry: { month: "", year: "" }, cvc: "" },
							addingCreditCard: false
						},
						() => this.getPaymentMethods()
					);
				} else {
					this.setState({ showEasyPay: true, url }, () => dispatch(setLoader(false)));
				}
			} else {
				notification.error({
					message: strings.orders.paymentMethod,
					description: response?.data?.message,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: strings.orders.paymentMethod,
				description: err as string,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async removePaymentMethod(paymentMethod: string) {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.delete({
				url: Endpoints.uriUsers(`payment-method/${paymentMethod}`)
			});

			if (response?.ok) {
				const paymentMethods = response?.data.results.paymentMethods || [];
				this.setState((prevState: any) => ({
					paymentMethods,
					selectedCreditCard: prevState.selectedCreditCard === paymentMethod ? null : prevState.selectedCreditCard
				}));

				notification.success({
					message: strings.orders.paymentMethod,
					description: response.data?.message,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: strings.orders.paymentMethod,
					description: response.data?.message || strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: strings.orders.paymentMethod,
				description: err as string,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	validOrder() {
		const { user, products, paymentType, selectedPaymentAddress, selectedShippingAddress, selectedShippingMethod, confirmed } =
			this.state;

		if (
			!user ||
			products?.length === 0 ||
			!paymentType ||
			!selectedPaymentAddress ||
			!selectedShippingAddress ||
			!selectedShippingMethod
		) {
			notification.warn({
				message: strings.sidebar.orders,
				description: strings.orders.invalidOrder,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		if (!confirmed) {
			notification.warn({
				message: strings.sidebar.orders,
				description: strings.orders.confirmOrder,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		return true;
	}

	breadcrumb() {
		const { dispatch } = this.props;

		dispatch(setBreadcrumb(null));
		delayedDispatch(
			setBreadcrumb(() => {
				const { products, selectedShippingAddress, paymentType, selectedCreditCard, confirmed } = this.state;
				const canCreate =
					products.length === 1 &&
					selectedShippingAddress &&
					(paymentType === "mbw" ||
						paymentType === "cash" ||
						paymentType === "bank_transfer" ||
						(paymentType === "cc" && selectedCreditCard)) &&
					confirmed;

				return {
					locations: [],
					actions: [
						{
							type: "label",
							text: <strong>{formatPrice(products?.reduce((acc: any, product: any) => acc + +product.price, 0))}</strong>,
							margin: "right"
						},
						{
							type: "button",
							text: strings.orders.submitOrder,
							className: "BreadcrumbOpenButton",
							onClick: () => this.submitOrder(),
							disabled: !canCreate,
							isSave: true,
							margin: "left"
						}
					]
				};
			})
		);
	}

	validAddress() {
		const { tempAddress } = this.state;

		if (!tempAddress?.address) {
			notification.warn({
				message: strings.users.addresses,
				description: strings.users.addressRequired,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		if (!tempAddress?.city) {
			notification.warn({
				message: strings.users.addresses,
				description: strings.users.cityRequired,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		if (!tempAddress?.country) {
			notification.warn({
				message: strings.users.addresses,
				description: strings.users.countryRequired,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		if (!tempAddress?.state) {
			notification.warn({
				message: strings.users.addresses,
				description: strings.users.stateRequired,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		if (!tempAddress?.postalCode) {
			notification.warn({
				message: strings.users.addresses,
				description: strings.users.zipRequired,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		return true;
	}

	renderKits() {
		const { defaultProducts = [], products = [], selectedShippingAddress } = this.state;

		return (
			<ContentWrapper>
				<Row gutter={[20, 10]}>
					<Col xs={24}>
						<div className="ScreenHeader">
							<div className="ScreenHeaderLeft">
								<Icon name="box" />
								<h2>{strings.orders.becomeDistributor}</h2>
							</div>
						</div>
					</Col>
					<Col xs={24}>
						<p>{strings.kits.description}</p>
					</Col>
					{defaultProducts?.map((product: any) => {
						const isSelected = products.find((p: any) => p.product === product._id);
						const image = product?.images?.[0]?.url || placeholder;

						return (
							<Col key={`product_${product._id}`} xs={24} md={12}>
								<div
									className={`KitCard${isSelected ? " --card-selected" : ""}`}
									onClick={() => {
										if (!isSelected) {
											this.setState(
												{
													products: [
														{
															...JSON.parse(JSON.stringify(product)),
															product: product._id,
															quantity: 1,
															total: product.price
														}
													]
												},
												() => {
													if (selectedShippingAddress) this.calculateTotal();
												}
											);
										}
									}}
								>
									<div className="KitCardHeader">
										<h2>{translate(product.name)}</h2>
										<h3>{formatPrice(product.price)}</h3>
									</div>
									<div className="KitCardImage">
										<img src={image} alt="product image" />
									</div>
									<div className="KitCardBody" dangerouslySetInnerHTML={{ __html: translate(product.description) }} />
								</div>
							</Col>
						);
					})}
				</Row>
			</ContentWrapper>
		);
	}

	renderAddressForm() {
		const { changingAddress, tempAddress, defaultCountries, defaultStates = [] } = this.state;

		if (!changingAddress) return null;

		return (
			<Col xs={24} md={12}>
				<div className="OrderAddressBlock">
					<div className="OrderAddressTitle">
						<Icon name="directions" />
						<h3>{strings.orders.addAddress}</h3>
					</div>
				</div>
				<Row style={{ marginTop: 10 }} gutter={[20, 10]}>
					<Col xs={24}>
						<label htmlFor="order_address_name" className="InputLabel">
							{strings.fields.name}
						</label>
						<Input
							id="order_address_name"
							placeholder={strings.fields.name}
							value={tempAddress?.name || ""}
							onChange={(e) => {
								const value = e.target.value;

								this.setState((prevState: any) => ({
									tempAddress: {
										...prevState.tempAddress,
										name: value
									}
								}));
							}}
							style={{ height: 40 }}
						/>
					</Col>
					<Col xs={24}>
						<label htmlFor="order_address_address" className="InputLabel">
							{strings.fields.address}
						</label>
						<Input
							id="order_address_address"
							placeholder={strings.fields.address}
							value={tempAddress?.address || ""}
							onChange={(e) => {
								const value = e.target.value;

								this.setState((prevState: any) => ({
									tempAddress: {
										...prevState.tempAddress,
										address: value
									}
								}));
							}}
							style={{ height: 40 }}
						/>
					</Col>
					<Col xs={24}>
						<label htmlFor="order_address_address2" className="InputLabel">
							{strings.fields.address2}
						</label>
						<Input
							id="order_address_address2"
							placeholder={strings.fields.address2}
							value={tempAddress?.address2 || ""}
							onChange={(e) => {
								const value = e.target.value;

								this.setState((prevState: any) => ({
									tempAddress: {
										...prevState.tempAddress,
										address2: value
									}
								}));
							}}
							style={{ height: 40 }}
						/>
					</Col>
					<Col xs={24} md={12}>
						<label htmlFor="zip" className="InputLabel --label-required">
							{strings.fields.zipCode}
						</label>
						<Input
							id="zip"
							placeholder={strings.fields.zipCode}
							value={tempAddress?.postalCode || ""}
							onChange={(e) => {
								const value = e.target.value;

								this.setState((prevState: any) => ({
									tempAddress: {
										...prevState.tempAddress,
										postalCode: value
									}
								}));
							}}
							style={{ height: 40 }}
						/>
					</Col>
					<Col xs={24} md={12}>
						<label htmlFor="city" className="InputLabel --label-required">
							{strings.fields.city}
						</label>
						<Input
							id="city"
							placeholder={strings.fields.city}
							value={tempAddress?.city || ""}
							onChange={(e) => {
								const value = e.target.value;

								this.setState((prevState: any) => ({
									tempAddress: {
										...prevState.tempAddress,
										city: value
									}
								}));
							}}
							style={{ height: 40 }}
						/>
					</Col>
					<Col xs={24} md={12}>
						<label htmlFor="country" className="InputLabel --label-required">
							{strings.fields.country}
						</label>
						<Select
							style={{ width: "100%" }}
							placeholder={strings.fields.country}
							showSearch
							filterOption={(input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							value={tempAddress?.country || null}
							onChange={(elem: any) => {
								this.setState((prevState: any) => ({
									tempAddress: {
										...prevState.tempAddress,
										country: elem,
										state: null
									}
								}));
							}}
						>
							{defaultCountries?.map((country: any) => (
								<Select.Option key={country._id} value={country._id}>
									{country?.name}
								</Select.Option>
							))}
						</Select>
					</Col>
					<Col xs={24} md={12}>
						<label htmlFor="state" className="InputLabel --label-required">
							{strings.fields.state}
						</label>
						<Select
							id="state"
							style={{ width: "100%" }}
							placeholder={tempAddress?.country ? strings.fields.state : strings.fields.selectCountryFirst}
							showSearch
							filterOption={(input: any, option: any) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							value={tempAddress?.state || null}
							disabled={!tempAddress?.country}
							options={
								tempAddress?.country
									? defaultStates[tempAddress?.country].map((state: any) => ({
											value: state?._id,
											label: state?.name
									  }))
									: null
							}
							onChange={(elem: any) => {
								this.setState((prevState: any) => ({
									tempAddress: {
										...prevState.tempAddress,
										state: elem
									}
								}));
							}}
						/>
					</Col>
					<Col xs={24} md={12}>
						<label htmlFor="order_address_phone" className="InputLabel">
							{strings.fields.phone}
						</label>
						<PhoneInput
							id="order_address_phone"
							defaultCountry="pt"
							value={tempAddress?.phone || ""}
							onChange={(phone: string) =>
								this.setState((prevState: any) => ({
									tempAddress: {
										...prevState.tempAddress,
										phone
									}
								}))
							}
							inputClass="input-phone"
							inputProps={{
								name: "phone",
								required: false,
								autoComplete: "off",
								placeholder: strings.fields.phone
							}}
						/>
					</Col>
					<Col xs={24} md={12}>
						<label htmlFor="vat_number" className="InputLabel">
							{strings.fields.vatNumber}
						</label>
						<Input
							id="vat_number"
							placeholder={strings.fields.vatNumber}
							value={tempAddress?.vatNumber || ""}
							onChange={(e) => {
								const value = e.target.value;

								this.setState((prevState: any) => ({
									tempAddress: {
										...prevState.tempAddress,
										vatNumber: value
									}
								}));
							}}
							style={{ height: 40 }}
						/>
					</Col>
					<Col xs={24}>
						<div className={`General_ColorFul_Switch ${tempAddress?.isDefault ? "__active" : ""}`}>
							<span>{strings.users.isDefault}</span>
							<Switch
								className={`Switch ${tempAddress?.isDefault ? "__active" : ""}`}
								checked={tempAddress?.isDefault || false}
								size="small"
								onChange={(value: any) =>
									this.setState((prevState: any) => ({
										tempAddress: {
											...prevState.tempAddress,
											isDefault: value,
											hasUnsavedFields: true
										}
									}))
								}
							/>
						</div>
					</Col>

					<Col xs={12}>
						<Button type="primary" style={{ width: "100%" }} onClick={() => this.saveAddress()}>
							{strings.generic.save}
						</Button>
					</Col>
					<Col xs={12}>
						<Button
							style={{ width: "100%" }}
							onClick={() =>
								this.setState({
									changingAddress: false,
									tempAddress: null
								})
							}
						>
							{strings.generic.cancel}
						</Button>
					</Col>
				</Row>
			</Col>
		);
	}

	renderClientAddresses() {
		const { user, changingAddress, selectedShippingAddress, selectedPaymentAddress } = this.state;

		return (
			<ContentWrapper>
				<div className="ScreenHeader">
					<div className="ScreenHeaderLeft">
						<Icon name="directions" />
						<h2>{strings.orders.orderAddresses}</h2>
					</div>
				</div>
				<Row gutter={[20, 20]}>
					{!changingAddress && (
						<React.Fragment>
							<Col xs={24} md={12}>
								<div className="OrderAddressBlock">
									<div className="OrderAddressTitle --rounded">
										<Icon name="directions" />
										<h3>{strings.orders.shippingAddress}</h3>
									</div>
									{!user && (
										<div className="OrderAddressInfo --single-entry --select-first">
											<strong>{strings.orders.selectClientFirst}</strong>
										</div>
									)}
									{user?.addresses?.map((address: any) => {
										const shippingVat = address?.vatNumber ? `${strings.fields.vatNumber}: ${address?.vatNumber}` : "";
										const shippingPhoneNumber = address?.phoneNumber
											? `${strings.fields.phoneShort}: ${address?.phoneNumber}`
											: "";

										return (
											<div className="OrderAddress" key={`address_${address._id}`}>
												<div className="OrderAddressInfo --single-entry">
													<strong>{address?.name}</strong>
													<span>
														{address?.address}
														{address?.address2 ? `, ${address?.address2}` : ""}
													</span>
													<span>
														{address?.postalCode} {address?.city}
													</span>
													<span>
														{address?.state?.name}, {address?.country?.name}
													</span>
													{Boolean(shippingVat) || Boolean(shippingPhoneNumber) ? (
														<span>
															{shippingVat}
															{shippingVat && shippingPhoneNumber
																? ` | ${shippingPhoneNumber}`
																: shippingPhoneNumber}
														</span>
													) : null}
												</div>
												<button
													className={`OrderAddressSelect${
														selectedShippingAddress === address._id ? " --selected" : ""
													}`}
													onClick={() =>
														this.setState({ selectedShippingAddress: address._id }, () => this.calculateTotal())
													}
												>
													<Icon name="correct-symbol" />
												</button>
											</div>
										);
									})}
									{user && (
										<button
											onClick={() =>
												this.setState({
													changingAddress: true
												})
											}
											className="OrderButton"
										>
											<strong>{strings.orders.addAddress}</strong>
										</button>
									)}
								</div>
							</Col>
							<Col xs={24} md={12}>
								<div className="OrderAddressBlock">
									<div className="OrderAddressTitle --rounded">
										<Icon name="directions" />
										<h3>{strings.orders.billingAddress}</h3>
									</div>
									{!user && (
										<div className="OrderAddressInfo --single-entry --select-first">
											<strong>{strings.orders.selectClientFirst}</strong>
										</div>
									)}
									{user?.addresses?.map((address: any) => {
										const shippingVat = address?.vatNumber ? `${strings.fields.vatNumber}: ${address?.vatNumber}` : "";
										const shippingPhoneNumber = address?.phoneNumber
											? `${strings.fields.phoneShort}: ${address?.phoneNumber}`
											: "";

										return (
											<div className="OrderAddress" key={`address_${address._id}`}>
												<div className="OrderAddressInfo --single-entry">
													<strong>{address?.name}</strong>
													<span>
														{address?.address}
														{address?.address2 ? `, ${address?.address2}` : ""}
													</span>
													<span>
														{address?.postalCode} {address?.city}
													</span>
													<span>
														{address?.state?.name}, {address?.country?.name}
													</span>
													{Boolean(shippingVat) || Boolean(shippingPhoneNumber) ? (
														<span>
															{shippingVat}
															{shippingVat ? ` | ${shippingPhoneNumber}` : shippingPhoneNumber}
														</span>
													) : null}
												</div>
												<button
													className={`OrderAddressSelect${
														selectedPaymentAddress === address._id ? " --selected" : ""
													}`}
													onClick={() => this.setState({ selectedPaymentAddress: address._id })}
												>
													<Icon name="correct-symbol" />
												</button>
											</div>
										);
									})}
									{user && (
										<button
											onClick={() =>
												this.setState({
													changingAddress: true
												})
											}
											className="OrderButton"
										>
											<strong>{strings.orders.addAddress}</strong>
										</button>
									)}
								</div>
							</Col>
						</React.Fragment>
					)}
					{changingAddress && this.renderAddressForm()}
				</Row>
			</ContentWrapper>
		);
	}

	renderOrderShipping() {
		const { products = [], shippingMethods = [], selectedShippingAddress, selectedShippingMethod } = this.state;

		return (
			<ContentWrapper>
				<div className="ScreenHeader">
					<div className="ScreenHeaderLeft">
						<Icon name="box" />
						<h2>{strings.orders.orderProcessing}</h2>
					</div>
				</div>
				<div className="OrderAddressBlock">
					<div className="OrderAddressTitle --rounded">
						<Icon name="directions" />
						<h3>{strings.orders.shippingMethod}</h3>
					</div>
					{products?.length === 0 && (
						<div className="OrderAddressInfo --single-entry --select-first">
							<strong>{strings.orders.selectProductsFirst}</strong>
						</div>
					)}
					{!selectedShippingAddress && (
						<div className="OrderAddressInfo --single-entry --select-first">
							<strong>{strings.orders.selectShippingAddressFirst}</strong>
						</div>
					)}
					{products?.length > 0 &&
						selectedShippingAddress &&
						shippingMethods?.map((shippingMethod: any) => {
							if (shippingMethod?.methods?.length === 0) return null;

							return shippingMethod?.methods?.map((method: any) => {
								return (
									<div className="OrderAddress" key={`shipping_method_${method._id}`}>
										<div className="OrderAddressInfo --single-entry --lowercase">
											<div className="OrderAddressColumns">
												<img src={shippingMethod?.image || placeholder} alt={translate(method?.name)} />
												<div className="OrderAddressBlock">
													<strong>{translate(method?.name)}</strong>
													<span>{translate(method?.description)}</span>
												</div>
											</div>
										</div>
										<strong>
											{method?.methodType === "free" ? strings.orders.free : formatPrice(method?.value || 0)}
										</strong>
										<button
											className={`OrderAddressSelect${selectedShippingMethod === method._id ? " --selected" : ""}`}
											onClick={() =>
												this.setState(
													{
														selectedShippingMethod: method._id
													},
													() => this.calculateTotal()
												)
											}
										>
											<Icon name="correct-symbol" />
										</button>
									</div>
								);
							});
						})}
				</div>
			</ContentWrapper>
		);
	}

	renderOrderPayment() {
		const {
			products = [],
			paymentType,
			mbway,
			paymentMethods = [],
			selectedCreditCard,
			addingCreditCard = false,
			cardInfo
		} = this.state;

		return (
			<ContentWrapper>
				<div className="ScreenHeader">
					<div className="ScreenHeaderLeft">
						<Icon name="box" />
						<h2>{strings.orders.orderProcessing}</h2>
					</div>
				</div>
				<div className="OrderAddressBlock">
					<div className="OrderAddressTitle --rounded">
						<Icon name="directions" />
						<h3>{strings.orders.paymentMethod}</h3>
					</div>
					{products?.length === 0 ? (
						<div className="OrderAddressInfo --single-entry --select-first">
							<strong>{strings.orders.selectProductsFirst}</strong>
						</div>
					) : (
						<React.Fragment>
							<div className="OrderAddress">
								<div className="OrderAddressInfo --single-entry --lowercase">
									<div className="OrderAddressColumns">
										<img src={mbwayLogo} alt="mbway" />
										<div className="OrderAddressBlock">
											<strong>MB WAY</strong>
											<span>{strings.orders.mbwayDescription}</span>
											{paymentType === "mbw" && (
												<React.Fragment>
													<label
														htmlFor="phone"
														style={{ marginTop: 10 }}
														className="InputLabel --label-required"
													>
														{strings.fields.phone}
													</label>
													<PhoneInput
														defaultCountry="pt"
														value={mbway || ""}
														inputClass="input-phone"
														inputProps={{
															name: "phone",
															required: true,
															autoComplete: "off",
															placeholder: strings.fields.phone
														}}
														onChange={(phone: any) =>
															this.setState({
																mbway: phone,
																selectedCreditCard: undefined,
																paymentType: "mbw",
																paymentId: undefined,
																last_four: undefined
															})
														}
													/>
												</React.Fragment>
											)}
										</div>
									</div>
								</div>
								<button
									className={`OrderAddressSelect${paymentType === "mbw" ? " --selected" : ""}`}
									onClick={() => this.setState({ paymentType: "mbw" })}
								>
									<Icon name="correct-symbol" />
								</button>
							</div>
							<div className="OrderAddress">
								<div className="OrderAddressInfo --single-entry --lowercase">
									<div className="OrderAddressColumns">
										<img src={cc} alt="credit-card" />
										<div className="OrderAddressBlock" style={{ borderRadius: 0 }}>
											<strong>{strings.orders.creditCard}</strong>
											{paymentType === "cc" &&
												!addingCreditCard &&
												paymentMethods.map((payment: any) => {
													const selected = selectedCreditCard === payment.id;
													const image = payment.method?.card_type === "visa" ? visa : mastercard;

													return (
														<div className="OrderCreditCard" key={payment.id}>
															<button
																className={`OrderCreditCardSelect${selected ? " --selected" : ""}`}
																onClick={() => {
																	this.setState({
																		paymentType: "cc",
																		selectedCreditCard: payment.id,
																		paymentId: payment.id,
																		last_four: payment.method?.last_four
																	});
																}}
															>
																<Icon name="correct-symbol" />
															</button>
															<div className="OrderCreditCardDetails">
																<div className="OrderCreditCardImage">
																	<img src={image} alt="credit card" />
																</div>
																<div className="OrderCreditCardData">
																	<span>**** {payment.method?.last_four}</span>
																	<span>
																		{strings.orders.expires} {payment.method?.expiration_date}
																	</span>
																</div>
															</div>
															<div className="OrderCreditCardRemove">
																<button
																	className="OrdersRemoveCreditCard"
																	onClick={() => this.removePaymentMethod(payment.id)}
																>
																	<Icon name="trash" />
																	{strings.generic.remove}
																</button>
															</div>
														</div>
													);
												})}
											{paymentType === "cc" && paymentMethods.length === 0 && !addingCreditCard && (
												<span>{strings.orders.noCreditCards}</span>
											)}
											{paymentType === "cc" && !addingCreditCard && (
												<button
													className="OrdersAddCreditCard"
													onClick={() =>
														this.setState({
															addingCreditCard: true,
															cardInfo: {
																number: "",
																name: "",
																expiry: {
																	month: "",
																	year: ""
																},
																cvc: "",
																focused: ""
															}
														})
													}
												>
													{strings.orders.addCreditCard}
												</button>
											)}
											{addingCreditCard && (
												<React.Fragment>
													<Col xs={24} md={12}>
														<Row gutter={[20, 10]}>
															<Col xs={24} style={{ marginTop: 40 }}>
																<CreditCard
																	number={cardInfo?.number}
																	name={cardInfo?.name}
																	expiry={
																		(cardInfo?.expiry?.month || "") +
																		"/" +
																		(cardInfo?.expiry?.year || "")
																	}
																	cvc={cardInfo?.cvc}
																	focused={cardInfo?.focused}
																/>
															</Col>
															<Col xs={24}>
																<label htmlFor="card_number" className="InputLabel">
																	{strings.fields.cardNumber}
																</label>
																<Input
																	id="card_number"
																	placeholder={strings.fields.cardNumber}
																	value={cardInfo?.number || ""}
																	onChange={(e) => {
																		if (!/^[0-9]*$/.test(e.target.value) || e.target.value?.length > 16)
																			return;
																		const value = e.target.value;

																		this.setState((prevState: any) => ({
																			cardInfo: {
																				...prevState.cardInfo,
																				number: value
																			}
																		}));
																	}}
																	onFocus={() =>
																		this.setState((prevState: any) => ({
																			cardInfo: {
																				...prevState.cardInfo,
																				focused: "number"
																			}
																		}))
																	}
																	style={{ height: 40 }}
																/>
															</Col>
															<Col xs={24}>
																<label htmlFor="card_name" className="InputLabel">
																	{strings.fields.cardName}
																</label>
																<Input
																	id="card_name"
																	placeholder={strings.fields.cardName}
																	value={cardInfo?.name || ""}
																	onChange={(e) => {
																		const value = e.target.value;

																		this.setState((prevState: any) => ({
																			cardInfo: {
																				...prevState.cardInfo,
																				name: value
																			}
																		}));
																	}}
																	onFocus={() =>
																		this.setState((prevState: any) => ({
																			cardInfo: {
																				...prevState.cardInfo,
																				focused: "name"
																			}
																		}))
																	}
																	style={{ height: 40 }}
																/>
															</Col>
															<Col xs={8}>
																<label htmlFor="card_expiration_date_month" className="InputLabel">
																	{strings.fields.month}
																</label>
																<Input
																	id="card_expiration_date_month"
																	placeholder={strings.fields.month}
																	value={cardInfo?.expiry?.month || ""}
																	onChange={(e) => {
																		if (!/^[0-9]*$/.test(e.target.value) || e.target.value?.length > 2)
																			return;
																		const value = e.target.value;

																		this.setState((prevState: any) => ({
																			cardInfo: {
																				...prevState.cardInfo,
																				expiry: {
																					...prevState.cardInfo?.expiry,
																					month: value
																				}
																			}
																		}));
																	}}
																	onFocus={() =>
																		this.setState((prevState: any) => ({
																			cardInfo: {
																				...prevState.cardInfo,
																				focused: "expiry"
																			}
																		}))
																	}
																	style={{ height: 40 }}
																/>
															</Col>
															<Col xs={8}>
																<label htmlFor="card_expiration_date_year" className="InputLabel">
																	{strings.fields.year}
																</label>
																<Input
																	id="card_expiration_date_year"
																	placeholder={strings.fields.year}
																	value={cardInfo?.expiry?.year || ""}
																	onChange={(e) => {
																		if (!/^[0-9]*$/.test(e.target.value) || e.target.value?.length > 4)
																			return;

																		const value = e.target.value;

																		this.setState((prevState: any) => ({
																			cardInfo: {
																				...prevState.cardInfo,
																				expiry: {
																					...prevState.cardInfo?.expiry,
																					year: value
																				}
																			}
																		}));
																	}}
																	onFocus={() =>
																		this.setState((prevState: any) => ({
																			cardInfo: {
																				...prevState.cardInfo,
																				focused: "expiry"
																			}
																		}))
																	}
																	style={{ height: 40 }}
																/>
															</Col>
															<Col xs={8}>
																<label htmlFor="card_cvc" className="InputLabel">
																	{strings.fields.cvc}
																</label>
																<Input
																	id="card_cvc"
																	placeholder={strings.fields.cvc}
																	value={cardInfo?.cvc || ""}
																	onChange={(e) => {
																		if (!/^[0-9]*$/.test(e.target.value) || e.target.value?.length > 3)
																			return;

																		const value = e.target.value;

																		this.setState((prevState: any) => ({
																			cardInfo: {
																				...prevState.cardInfo,
																				cvc: value
																			}
																		}));
																	}}
																	onFocus={() =>
																		this.setState((prevState: any) => ({
																			cardInfo: {
																				...prevState.cardInfo,
																				focused: "cvc"
																			}
																		}))
																	}
																	onBlur={() =>
																		this.setState((prevState: any) => ({
																			cardInfo: {
																				...prevState.cardInfo,
																				focused: "number"
																			}
																		}))
																	}
																	style={{ height: 40 }}
																/>
															</Col>
														</Row>
													</Col>
													<Col xs={24} md={12}>
														<div className="OrderAddCreditCardOptions">
															<button onClick={() => this.submitCreditCard()}>{strings.generic.save}</button>
															<button
																onClick={() => {
																	this.setState({
																		cardInfo: {
																			number: "",
																			name: "",
																			expiry: { month: "", year: "" },
																			cvc: "",
																			focused: ""
																		},
																		addingCreditCard: false
																	});
																}}
															>
																{strings.generic.cancel}
															</button>
														</div>
													</Col>
												</React.Fragment>
											)}
										</div>
									</div>
								</div>
								<button
									className={`OrderAddressSelect${paymentType === "cc" ? " --selected" : ""}`}
									onClick={() => this.setState({ paymentType: "cc" })}
								>
									<Icon name="correct-symbol" />
								</button>
							</div>

							<div className="OrderAddress">
								<div className="OrderAddressInfo --single-entry --lowercase">
									<div className="OrderAddressColumns">
										<div className="OrderAddressImage">
											<em className="moon-bank-transfer-logo" />
										</div>
										<div className="OrderAddressBlock" style={{ borderRadius: 0 }}>
											<strong>{strings.orders.bankTransfer}</strong>
										</div>
									</div>
								</div>
								<button
									className={`OrderAddressSelect${paymentType === "bank_transfer" ? " --selected" : ""}`}
									onClick={() => this.setState({ paymentType: "bank_transfer" })}
								>
									<Icon name="correct-symbol" />
								</button>
							</div>
							<div className="OrderAddress">
								<div className="OrderAddressInfo --single-entry --lowercase">
									<div className="OrderAddressColumns">
										<div className="OrderAddressImage">
											<em className="moon-pay" />
										</div>
										<div className="OrderAddressBlock" style={{ borderRadius: 0 }}>
											<strong>{strings.orders.cash}</strong>
										</div>
									</div>
								</div>
								<button
									className={`OrderAddressSelect${paymentType === "cash" ? " --selected" : ""}`}
									onClick={() => this.setState({ paymentType: "cash" })}
								>
									<Icon name="correct-symbol" />
								</button>
							</div>
						</React.Fragment>
					)}
				</div>
			</ContentWrapper>
		);
	}

	render3DSecure() {
		const { showEasyPay, url } = this.state;

		return (
			<Modal
				title={strings.orders.paymentMethod}
				visible={showEasyPay}
				onCancel={() => {
					this.setState({
						showEasyPay: false,
						url: "",
						addingCreditCard: false,
						cardInfo: {
							name: "",
							number: "",
							expiry: {
								month: "",
								year: ""
							},
							cvc: ""
						}
					});

					this.getPaymentMethods();
				}}
				footer={null}
				width="100%"
				bodyStyle={{ padding: 0, height: window.innerHeight - 100 }}
				centered
				destroyOnClose
			>
				{Boolean(url) && (
					<iframe
						src={url}
						style={{
							width: "100%",
							height: "100%",
							top: "0px",
							bottom: "0px",
							left: "0px",
							right: "0px",
							background: "#fff",
							zIndex: 99999
						}}
					/>
				)}
			</Modal>
		);
	}

	renderConfirmation() {
		const { confirmed } = this.state;

		return (
			<ContentWrapper>
				<div className="ScreenHeader">
					<div className="ScreenHeaderLeft">
						<Icon name="warning" />
						<h2>{strings.orders.confirmation}</h2>
					</div>
				</div>
				<Checkbox onChange={() => this.setState((prev: any) => ({ confirmed: !prev.confirmed }))} checked={confirmed}>
					{strings.orders.iConfirm}
				</Checkbox>
			</ContentWrapper>
		);
	}

	render() {
		return (
			<div className="KitsScreen">
				<Helmet>
					<title>{strings.orders.partnerKit}</title>
					<meta name="description" content="Select your Essência da Vida kit" />
				</Helmet>
				{this.renderKits()}
				{this.renderClientAddresses()}
				{this.renderOrderShipping()}
				{this.renderOrderPayment()}
				{this.renderConfirmation()}
				{this.render3DSecure()}
			</div>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language,
	user: state.user
});

export default connect(mapStateToProps)(PartnerKits);
