/*
 *
 * Users
 *
 */

import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Props, State } from "./types";
import { Helmet } from "react-helmet";
import { setBreadcrumb, setLoader, setTitle } from "store/actions";
import { Icon, Table } from "components";
import { Modal, Select, Tooltip, notification } from "antd";
import { getSuccessLevelBadge } from "utils/utils";

import Strings from "utils/strings";
import { API, Endpoints } from "utils/api";
import "./styles.scss";

const SUCCESSLEVELS = [
	"junior",
	"executive",
	"special-executive",
	"bronze",
	"gold",
	"gold30",
	"gold60",
	"gold100",
	"ruby",
	"emerald",
	"diamond",
	"diamond2",
	"diamond4",
	"diamond6",
	"diamond8",
	"special-diamond"
];

export class Partners extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			users: [],
			page: 0,
			pageSize: 100,
			total: 0,
			columnSearch: null,
			globalSearch: "",
			openConfirmResendInviteModal: false,
			tempUser: null
		};
	}

	async componentDidMount() {
		const { dispatch } = this.props;

		dispatch(setTitle(""));
		dispatch(setBreadcrumb(null));

		this.getData();
	}

	async getData(pause?: boolean) {
		const { page, pageSize, columnSearch, globalSearch } = this.state;
		const { dispatch } = this.props;

		setTimeout(
			async () => {
				if (columnSearch !== this.state.columnSearch || globalSearch !== this.state.globalSearch) return;
				dispatch(setLoader(true));

				const body = {
					filters: columnSearch
						? Object.keys(columnSearch).map((elem) =>
								columnSearch[elem] !== ""
									? {
											field: elem,
											query: columnSearch[elem]
									  }
									: {}
						  )
						: [],
					page: page,
					perPage: pageSize,
					search: globalSearch
				};

				try {
					const response = await API.post({
						url: Endpoints.uriPartners("search"),
						data: body
					});

					if (response.ok) {
						const { users = [], total = 0 } = response.data.results;
						this.setState({ users, total });
					} else {
						notification.error({
							message: Strings.staff.partner,
							description: response?.data?.message || Strings.serverErrors.wentWrong,
							placement: "bottomRight",
							duration: 5
						});
					}
				} catch (err) {
					notification.error({
						message: Strings.serverErrors.title,
						description: err as string,
						placement: "bottomRight",
						duration: 5
					});
				}

				dispatch(setLoader(false));
			},
			pause ? 1000 : 0
		);
	}

	async deleteUser(id: string) {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.delete({
				url: Endpoints.uriPartners(id)
			});

			if (response.ok) {
				this.getData();
				notification.success({
					message: Strings.staff.partner,
					description: response?.data?.message || Strings.users.deleted,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.serverErrors.title,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: err as string,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async toggleUser(id: string, active: boolean) {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.patch({
				url: Endpoints.uriPartners(`${id}/status`),
				data: {
					active: !active
				}
			});

			if (response.ok) {
				this.getData();
				notification.success({
					message: Strings.staff.partner,
					description: response?.data?.message || Strings.users.updated,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.staff.partner,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: err as string,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async removeUser(id: string) {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.delete({
				url: Endpoints.uriPartners(id)
			});

			if (response.ok) {
				this.getData();
				notification.success({
					message: Strings.staff.partner,
					description: response?.data?.message || Strings.users.deleted,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.staff.partner,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: err as string,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async resendEmail(user: any) {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.patch({
				url: Endpoints.uriPartners(`${user._id}/resend-invite`)
			});

			if (response?.ok) {
				this.setState({ openConfirmResendInviteModal: false, tempUser: null });
				notification.success({
					message: Strings.users.title,
					description: response.data.message,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.serverErrors.title,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	get pagination() {
		return {
			total: this.state.total,
			pageIndex: this.state.page,
			pageSize: this.state.pageSize,
			setPage: (page: number, size: number) => {
				this.setState({ page, pageSize: size }, () => this.getData());
			}
		};
	}

	get filtration() {
		const { globalSearch, columnSearch } = this.state;
		return {
			showGlobalSearch: true,
			showColumnSearch: true,
			defaultValues: { globalSearch, columnSearch },
			onGlobalSearch: (globalSearch: string) => {
				this.setState({ globalSearch, page: 0 }, () => this.getData());
			},
			onColumnSearch: (columnSearch: string) => {
				this.setState({ columnSearch, page: 0 }, () => this.getData());
			}
		};
	}

	getStatus(status: any) {
		if (status) {
			return Strings.staff.confirmed;
		}
		return Strings.staff.pending;
	}

	renderStatus = (elem: any, noActions = true) => {
		if (elem.value === "true") {
			return (
				<div className="TableTag --tag-success">
					<span>{this.getStatus(true)}</span>
				</div>
			);
		}

		return (
			<Tooltip title={Strings.authentication.resendInviteEmail}>
				<div
					className="TableTag --tag-warning --tag-clickable"
					onClick={() => {
						if (!noActions) this.setState({ openConfirmResendInviteModal: true, tempUser: elem.cell.row.original });
					}}
				>
					{!noActions && <Icon name="refresh" className="resend" />}
					<span>{this.getStatus(false)}</span>
				</div>
			</Tooltip>
		);
	};

	renderSuspended = (elem: any) => {
		if (elem.value === "true") {
			return (
				<div className="TableTag --tag-warning">
					<span>{Strings.generic.yes}</span>
				</div>
			);
		}

		return (
			<div className="TableTag --tag-success">
				<span>{Strings.generic.no}</span>
			</div>
		);
	};

	renderUsers() {
		const { users = [], columnSearch } = this.state;
		const { dispatch, partners } = this.props;

		let actions = {
			edit: (obj: any) => ({
				onClick: () => dispatch(push(`/partners/${obj._id}`))
			}),
			toggle: (obj: any) => ({
				value: obj.active,
				onChange: () => this.toggleUser(obj._id, obj.active)
			})
		} as any;

		if (partners) {
			actions = {
				view: (obj: any) => ({
					onClick: () => dispatch(push(`/partner-partners/${obj._id}`))
				})
			};
		}

		const columns: any[] = [
			{
				Header: Strings.users.id,
				id: "userCode",
				accessor: (row: any) => row.userCode || "-",
				maxWidth: 75
			},
			{
				Header: Strings.fields.photo,
				id: "photo",
				accessor: (row: any) => row.photo,
				type: "image",
				maxWidth: 65
			},
			{
				Header: Strings.fields.name,
				id: "name",
				accessor: (row: any) => row.name
			},
			{
				Header: Strings.fields.email,
				id: "email",
				accessor: (row: any) => row.email || "-",
				Cell: (row: any) =>
					row.cell.row.original?.email ? (
						<a href={`mailto:${row.cell.row.original?.email}`}>{row.cell.row.original?.email}</a>
					) : (
						"-"
					)
			},
			{
				Header: Strings.fields.phone,
				id: "phone",
				accessor: (row: any) => row.phone || "-"
			},
			{
				Header: Strings.settings.successLevel,
				id: "successLevel",
				accessor: (row: any) => getSuccessLevelBadge(row.successLevel) || "-",
				Filter: () => {
					return (
						<Select
							onChange={(value) => {
								const filters = columnSearch || {};
								const exists: any = Object.keys(filters)?.find((elem: any) => elem.field === "successLevel");

								if (value) {
									if (exists) {
										exists.query = value;
									} else {
										filters["successLevel"] = value;
									}
								} else {
									delete filters["successLevel"];
								}

								this.setState({ columnSearch: filters }, () => this.getData(false));
							}}
							style={{ width: "100%" }}
							placeholder={Strings.settings.successLevel}
							allowClear
							showSearch
						>
							{SUCCESSLEVELS.map((elem) => (
								<Select.Option key={elem} value={elem}>
									{getSuccessLevelBadge(elem)}
								</Select.Option>
							))}
						</Select>
					);
				}
			},
			{
				Header: Strings.users.referredBy,
				id: "referredBy",
				accessor: (row: any) => row.referredBy?.name || "-"
			},
			{
				Header: Strings.users.partnerStatus,
				id: "partner_confirmed",
				accessor: (user: any) => String(user.partnerConfirmed),
				Cell: (cell: any) => this.renderStatus(cell, false),
				maxWidth: 150,
				Filter: () => null
			},
			{
				Header: Strings.staff.status,
				id: "confirmed",
				accessor: (user: any) => String(user.confirmed),
				Cell: (cell: any) => this.renderStatus(cell, true),
				maxWidth: 150,
				Filter: () => null
			}
		];

		if (!partners) {
			columns.splice(7, 0, {
				Header: Strings.users.suspended,
				id: "suspended",
				accessor: (user: any) => String(user.suspended),
				Cell: (cell: any) => this.renderSuspended(cell),
				maxWidth: 150,
				Filter: () => null
			});
		}

		return (
			<Table
				title={{
					icon: "partner",
					title: Strings.sidebar.partners
				}}
				data={users}
				columns={columns}
				filterable
				fullPage
				isSinglePage
				paginated
				paginationApi={this.pagination}
				filtrationApi={this.filtration}
				add={{
					tooltip: Strings.users.addPartner,
					onClick: () => {
						if (partners) {
							dispatch(push("/partner-partners/new"));
						} else {
							dispatch(push("/partners/new"));
						}
					}
				}}
				actions={actions}
			/>
		);
	}

	renderConfirmResendInvite = () => {
		const { openConfirmResendInviteModal, tempUser } = this.state;

		return (
			<Modal
				className="resendInvite"
				style={{ textAlign: "center" }}
				visible={openConfirmResendInviteModal}
				cancelText={Strings.generic.close}
				okText={Strings.authentication.resendInviteEmail}
				onOk={() => this.resendEmail(tempUser)}
				onCancel={() => this.setState({ openConfirmResendInviteModal: false })}
				title={null}
				closable={false}
				bodyStyle={{ minHeight: 200 }}
			>
				<Icon name="paper-plane" style={{ fontSize: "50px" }} />
				<div className="title">
					{openConfirmResendInviteModal
						? Strings.formatString(Strings.authentication.confirmResendInviteEmail, tempUser?.name)
						: Strings.authentication.resendInviteEmail}
				</div>
			</Modal>
		);
	};

	render() {
		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.sidebar.partners}</title>
					<meta name="description" content="Description of Partners" />
				</Helmet>
				{this.renderUsers()}
				{this.renderConfirmResendInvite()}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language
});

export default connect(mapStateToProps)(Partners);
