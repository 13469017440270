// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root{--primary-color: #00b5b0;--light-primary: #19f1eb;--secondary-color: #22a8dd;--light-secondary: #b8e4f0;--app-background-color: whitesmoke;--logo-container-background: #151515;--text-color: #333;--gradient: linear-gradient(180deg, #00b5b0 1.04%, #19f1eb 100%);--gradient-border: linear-gradient(90deg, #00b5b0 1.04%, #19f1eb60 100%)}.EthicHeader h3{font-weight:700;font-size:17px;text-decoration:dashed underline;text-decoration-thickness:1px;margin-bottom:0}.EthicHeader.--stripped h3{color:#333}.EthicGrid{display:grid;grid-template-columns:repeat(auto-fill, minmax(300px, 1fr));grid-gap:20px;row-gap:20px}.EthicAddButton{height:30px;border-radius:8px;display:flex;align-items:center;justify-content:center;gap:10px;background-color:#fff;padding:0 10px;border:1px solid #d1d1d1;cursor:pointer;transition:200ms ease-in-out;margin-bottom:8px}.EthicAddButton em,.EthicAddButton svg{font-size:12px;font-weight:600;transition:200ms ease-in-out}.EthicAddButton p{margin:0;font-weight:600;font-size:12px;transition:200ms ease-in-out}.EthicAddButton:hover{border-color:#00b5b0}.EthicAddButton:hover em,.EthicAddButton:hover svg,.EthicAddButton:hover p{color:#00b5b0}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#00b5b0",
	"lightPrimary": "#19f1eb",
	"secondaryColor": "#22a8dd",
	"lightSecondary": "#b8e4f0"
};
module.exports = exports;
