import React, { ReactNode } from "react";
import "./styles.scss";

interface step {
	value: number;
	element: ReactNode;
	topElement: ReactNode;
}

interface ProgressBarProps {
	steps: step[];
	min: number;
	max?: number;
	value: number;
}

export default function ProgressBar(props: ProgressBarProps) {
	const { steps, min, max, value } = props;

	if (!steps || steps.length === 0) throw new Error("ProgressBar: steps is required");
	if (value === undefined) throw new Error("ProgressBar: value is required");
	if (min != null && value < min) throw new Error("ProgressBar: value must be between min and max");

	const orderedSteps = steps.sort((a, b) => a.value - b.value);
	const firstStep = min ?? 0;
	const finalStep = max ?? orderedSteps[orderedSteps.length - 1].value;
	const currentValuePercentage = Math.min(((value - firstStep) / (finalStep - firstStep)) * 100, 100);

	return (
		<div className="ag-progress-bar-container">
			<div className="ag-progress-bar__top-steps">
				{steps.map((step, index) => {
					const stepPercentage = ((step.value - firstStep) / (finalStep - firstStep)) * 100;
					const style: React.CSSProperties = { left: `${stepPercentage}%` };

					return (
						<React.Fragment key={index}>
							{index === 0 && (
								<div className="ag-progress-bar__step" style={{ left: -3 }}>
									{firstStep}
								</div>
							)}
							<div className="ag-progress-bar__step" style={style}>
								{step.topElement}
							</div>
						</React.Fragment>
					);
				})}
			</div>
			<div className="ag-progress-bar">
				{steps.map((step, index) => {
					const isLastStep = index === steps.length - 1;
					if (isLastStep) return null;
					const currentPercentage = ((step.value - firstStep) / (finalStep - firstStep)) * 100;

					return <div key={index} className="ag-progress-bar__marker" style={{ left: `${currentPercentage}%` }} />;
				})}
				<div className="ag-progress-bar__inner-bar" style={{ width: `${currentValuePercentage}%` }} />
				<div className="ag-progress-bar__inner-bar__overlay" style={{ width: `${currentValuePercentage}%` }}>
					{value > 0 && currentValuePercentage > 5 ? value : null}
				</div>
			</div>
			<div className="ag-progress-bar__bottom-steps">
				{steps.map((step, index) => {
					const stepPercentage = ((step.value - firstStep) / (finalStep - firstStep)) * 100;
					const style: React.CSSProperties = { left: `${stepPercentage}%` };

					return (
						<React.Fragment key={index}>
							{index === 0 && (
								<div className="ag-progress-bar__step" style={{ left: -3 }}>
									{firstStep}
								</div>
							)}
							<div className="ag-progress-bar__step" style={style}>
								{step.element}
							</div>
						</React.Fragment>
					);
				})}
			</div>
		</div>
	);
}
