// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root{--primary-color: #00b5b0;--light-primary: #19f1eb;--secondary-color: #22a8dd;--light-secondary: #b8e4f0;--app-background-color: whitesmoke;--logo-container-background: #151515;--text-color: #333;--gradient: linear-gradient(180deg, #00b5b0 1.04%, #19f1eb 100%);--gradient-border: linear-gradient(90deg, #00b5b0 1.04%, #19f1eb60 100%)}.CommissionDetail .DisabledSelect .ant-select-selector{background:#fff !important}.CommissionDetail .DisabledSelect .ant-select-selection-item{color:rgba(0,0,0,.8509803922);cursor:text;user-select:text}.CommissionDetail .CommissionNumberInput{width:100%;padding-right:30px}.CommissionDetail .CommissionNumberInput input{text-align:right}.CommissionDetail .CommissionNumberInput::after{display:flex;align-items:center;position:absolute;content:\" €\";font-size:12px;right:25px;top:1px;height:100%}.CommissionDetail .CommissionNumberInput.--weight::after{content:\" kg\"}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#00b5b0",
	"lightPrimary": "#19f1eb",
	"secondaryColor": "#22a8dd",
	"lightSecondary": "#b8e4f0"
};
module.exports = exports;
