import { Table } from "components";
import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import Strings from 'utils/strings';
import { push } from 'connected-react-router';
import { delayedDispatch, setBreadcrumb, setLoader, updateCrumb } from 'store/actions';
import { notification } from 'antd';
import { API, Endpoints } from "utils/api";
import { translate } from "utils/utils";

class Faqs extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			faqs: [],
		};
	}

	componentDidMount() {
		const { dispatch } = this.props;

		dispatch(setBreadcrumb(null));
		delayedDispatch(
			setBreadcrumb(() => {
				return {
					locations: [
						{
							text: Strings.sidebar.settings,
							route: "/settings",
							icon: "preferences",
						},
						{
							text: Strings.settings.faqs,
							icon: "support-tickets",
						},
					],
				};
			})
		);

		this.getData();
	}

	componentDidUpdate(): void {
		const { dispatch } = this.props;
		dispatch(updateCrumb());
	}

	async deleteFaq(value: any) {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.delete({
				url: Endpoints.uriFaqs(value),
			});

			if (response.ok) {
				this.setState({ faqs: response.data.results.faqs || [] });

				notification.success({
					message: Strings.sidebar.faqs,
					description: Strings.faqs.deleted,
					placement: 'bottomRight',
					duration: 5,
				});
			} else {
				notification.error({
					message: Strings.sidebar.faqs,
					description: response.data?.message || Strings.serverErrors.wentWrong,
					placement: 'bottomRight',
					duration: 5,
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: err as string || Strings.serverErrors.wentWrong,
				placement: 'bottomRight',
				duration: 5,
			});
		}

		dispatch(setLoader(false));
	}


	async getData() {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.get({
				url: Endpoints.uriFaqs(),
			});

			if (response.ok) {
				this.setState({ faqs: response.data.results.faqs || [] });
			} else {
				notification.error({
					message: Strings.sidebar.faqs,
					description: response.data?.message || Strings.serverErrors.wentWrong,
					placement: 'bottomRight',
					duration: 5,
				});

				dispatch(push('/settings'));
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: err as string || Strings.serverErrors.wentWrong,
				placement: 'bottomRight',
				duration: 5,
			});
		}

		dispatch(setLoader(false));
	}

	onDrag = async () => {
		const { faqs, hoverIndex } = this.state;
		const { dispatch } = this.props;

		const item = faqs[hoverIndex];
		const itemId = item?._id;

		if (!itemId || item.pos === hoverIndex) return;

		dispatch(setLoader(true));

		try {
			const response = await API.patch({
				url: Endpoints.uriFaqs(`${itemId}/position`),
				data: { pos: hoverIndex },
			});

			if (response.ok) {
				await this.getData();

				notification.success({
					message: Strings.sidebar.faqs,
					description: response?.data?.message || Strings.faqs.updated,
					placement: 'bottomRight',
					duration: 5,
				});
			} else {
				notification.error({
					message: Strings.serverErrors.title,
					description: response.data?.message,
					placement: 'bottomRight',
					duration: 5,
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: err as string || Strings.serverErrors.wentWrong,
				placement: 'bottomRight',
				duration: 5,
			});
		}

		dispatch(setLoader(false));
	};

	async toggleFaqs(value: any) {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.patch({
				url: Endpoints.uriFaqs(`${value._id}/status`),
				data: {
					isActive: !value.isActive,
				}
			});

			if (response.ok) {
				await this.getData();

				notification.success({
					message: Strings.sidebar.faqs,
					description: response?.data?.message || Strings.faqs.updated,
					placement: 'bottomRight',
					duration: 5,
				});
			} else {
				notification.error({
					message: Strings.serverErrors.title,
					description: response.data?.message,
					placement: 'bottomRight',
					duration: 5,
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: err as string || Strings.serverErrors.wentWrong,
				placement: 'bottomRight',
				duration: 5,
			});
		}
		dispatch(setLoader(false));
	}

	render() {
		const { faqs } = this.state;
		const { dispatch } = this.props;

		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.settings.faqs}</title>
					<meta name='description' content='Set faqs' />
				</Helmet>
				<Table
					title={{
						icon: "support-tickets",
						title: Strings.settings.faqs
					}}
					data={faqs}
					columns={[
						{
							Header: Strings.fields.question,
							id: 'question',
							accessor: (row: any) => translate(row.question),
						},
					]}
					isSinglePage
					fullPage
					paginated={false}
					add={{
						tooltip: Strings.faqs.addFaq,
						onClick: () => dispatch(push('/settings/faqs/new'))
					}}
					actions={{
						edit: (obj: any) => ({
							onClick: () => dispatch(push(`/settings/faqs/${obj._id}`)),
						}),
						remove: (obj: any) => ({
							onClick: () => this.deleteFaq(obj._id),
						}),
						toggle: (obj: any) => ({
							value: obj.isActive,
							onChange: () => this.toggleFaqs(obj),
						})
					}}
					draggable
					onDrag={async (list: any, dragIndex: any, hoverIndex: any) => {
						this.setState({ faqs: list, dragIndex, hoverIndex })
					}}
					onDragEnd={this.onDrag}

				/>
			</React.Fragment>
		);
	}
}
const mapStateToProps = (state: any) => ({
	language: state.language,
});
export default connect(mapStateToProps)(Faqs);
