// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root{--primary-color: #00b5b0;--light-primary: #19f1eb;--secondary-color: #22a8dd;--light-secondary: #b8e4f0;--app-background-color: whitesmoke;--logo-container-background: #151515;--text-color: #333;--gradient: linear-gradient(180deg, #00b5b0 1.04%, #19f1eb 100%);--gradient-border: linear-gradient(90deg, #00b5b0 1.04%, #19f1eb60 100%)}.ShippingDetailScreen .ShippingImageContainer{height:100%;position:relative;cursor:pointer;border:2px dashed #a6a5a6;border-radius:4px;transition:200ms ease-in-out}.ShippingDetailScreen .ShippingImageContainer:hover{border-color:#00b5b0}.ShippingDetailScreen .ShippingImageContainer:hover .GenericImageUploadOverlay:not(.--visible){opacity:1;background-color:rgba(0,0,0,.5019607843);color:#fff}.ShippingDetailScreen .ShippingImageContainer .ShppingImage{height:100%;width:100%;background-repeat:no-repeat;background-size:cover;background-position:center}.ShippingDetailScreen .ShippingImageContainer .GenericImageDelete{display:flex;justify-content:center;align-items:center;position:absolute;right:3px;top:3px;min-height:25px;min-width:25px;border-radius:50%;background-color:#ddd;border:none;box-shadow:0 0 4px 2px rgba(0,0,0,.5019607843);cursor:pointer;transition:200ms ease-in-out}.ShippingDetailScreen .ShippingImageContainer .GenericImageDelete em{font-size:12px}.ShippingDetailScreen .ShippingImageContainer .GenericImageDelete:hover{color:#40a9ff}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#00b5b0",
	"lightPrimary": "#19f1eb",
	"secondaryColor": "#22a8dd",
	"lightSecondary": "#b8e4f0"
};
module.exports = exports;
