import { Col, DatePicker, Input, InputNumber, Row, Select, notification } from "antd";
import { ContentWrapper, Icon } from "components";
import { debounce } from "lodash";
import { DateTime } from "luxon";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { delayedDispatch, setBreadcrumb, setLoader, setTitle, updateCrumb } from "store/actions";
import { API, Endpoints } from "utils/api";
import strings from "utils/strings";
import "./styles.scss";
import { replace } from "connected-react-router";
import moment from "moment";

class CommissionDetail extends Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			isNew: props.match.params.id === "new",
			origin: props.match.params.id === "new" ? props.user._id : undefined,
			partner: undefined,
			defaultPartners: [],
			defaultOrigins:
				props.match.params.id === "new" ? [{ name: props.user.name, email: props.user.email, _id: props.user._id }] : [],
			searchParams: "",
			date: DateTime.local().toISO(),
			redeemed: true,
			redeemDate: DateTime.local().toISO(),
			type: "commissions"
		};
	}

	async componentDidMount() {
		const { isNew } = this.state;
		const { dispatch } = this.props;

		dispatch(setTitle(strings.sidebar.commissions));

		if (!isNew) await this.getData();
		this.breadcrumb();
	}

	componentDidUpdate() {
		const { dispatch } = this.props;
		dispatch(updateCrumb());
	}

	async getData(commissionId?: string) {
		const {
			dispatch,
			match: {
				params: { id }
			}
		} = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.get({
				url: Endpoints.uriCommissions(commissionId || id)
			});

			if (response?.ok) {
				const { commission } = response.data?.results || {};

				const partner = commission?.partner;
				const origin = commission?.origin;
				const defaultPartners = partner ? [{ name: partner.name, email: partner.email, _id: partner._id }] : [];
				const defaultOrigins = origin ? [{ name: origin.name, email: origin.email, _id: origin._id }] : [];

				commission.partner = partner?._id;
				commission.origin = origin?._id;

				this.setState({ commission, ...commission, defaultPartners, defaultOrigins, isNew: false });
			} else {
				notification.error({
					message: strings.sidebar.commissions,
					description: response?.data?.message || strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: strings.serverErrors.title,
				description: err as string,
				placement: "bottomRight",
				duration: 5
			});
		} finally {
			dispatch(setLoader(false));
		}
	}

	async saveCommission() {
		const { isNew, origin, partner, date, points, value, notes } = this.state;
		const { dispatch } = this.props;

		if (!isNew) return;
		if (points == null && value == null) {
			notification.warn({
				message: strings.sidebar.commissions,
				description: strings.errors.invalidFields,
				placement: "bottomRight",
				duration: 5
			});
			return;
		}

		if (!origin || !partner) {
			notification.warn({
				message: strings.sidebar.commissions,
				description: strings.users.selectPartner,
				placement: "bottomRight",
				duration: 5
			});
			return;
		}

		dispatch(setLoader(true));

		try {
			const response = await API.post({
				url: Endpoints.uriCommissions(),
				data: {
					origin,
					partner,
					date,
					points,
					value,
					redeemed: true,
					redeemDate: DateTime.local().toISO(),
					notes
				}
			});

			if (response?.ok) {
				dispatch(replace(`/commissions/view/${response.data.results.commission._id}`));
				await this.getData(response.data.results.commission._id);
				this.breadcrumb();

				notification.success({
					message: strings.sidebar.commissions,
					description: response?.data?.message,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: strings.sidebar.commissions,
					description: response?.data?.message || strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			console.log("err", err);
			notification.error({
				message: strings.serverErrors.title,
				description: err as string,
				placement: "bottomRight",
				duration: 5
			});
		} finally {
			dispatch(setLoader(false));
		}
	}

	breadcrumb() {
		const { dispatch } = this.props;

		dispatch(setBreadcrumb(null));
		delayedDispatch(
			setBreadcrumb(() => {
				const { commission, isNew } = this.state;

				return {
					locations: [
						{
							icon: "organization",
							text: strings.sidebar.commissions,
							route: "/commissions"
						},
						{
							icon: commission?._id ? "eye" : "plus",
							text: commission?._id
								? DateTime.fromISO(commission.date).toLocaleString(DateTime.DATE_SHORT)
								: strings.generic.newF
						}
					],
					actions: [
						{
							type: "button",
							text: strings.generic.save,
							disabled: !this.state.hasUnsavedFields,
							className: "BreadcrumbSaveButton",
							isSave: true,
							hasIcon: true,
							visible: isNew,
							onClick: () => this.saveCommission()
						}
					]
				};
			})
		);
	}

	debounceFetcher = debounce(async (value: string) => {
		const body = { search: value, page: 0, perPage: 10 };

		try {
			const response = await API.post({
				url: Endpoints.uriPartners("search"),
				data: body
			});

			if (response?.ok) {
				const { users } = response.data.results;
				this.setState({ defaultPartners: users });
			}
		} catch (err) {}
	}, 800);

	handleSearch = (value: string) => {
		if (value) {
			this.debounceFetcher(value);
			this.setState({ searchParams: value });
		} else {
			this.setState({ defaultPartners: [], searchParams: "" });
		}
	};

	render() {
		const {
			isNew,
			defaultPartners,
			defaultOrigins,
			searchParams,
			type,
			partner,
			origin,
			date,
			points,
			value,
			expired,
			usedBalance,
			notes
		} = this.state;

		return (
			<div className="CommissionDetail">
				<Helmet>
					<title>{strings.sidebar.commissions}</title>
					<meta name="description" content="Edit a commission" />
				</Helmet>
				<ContentWrapper>
					<div className="ScreenHeader">
						<div className="ScreenHeaderLeft">
							<Icon name="organization" />
							<h2>{strings.users.commission}</h2>
						</div>
					</div>
					<Row gutter={[20, 20]}>
						<Col xs={24} md={12}>
							<label htmlFor="commission_date" className={`InputLabel${isNew ? " --label-required" : ""}`}>
								{strings.fields.date}
							</label>
							{isNew ? (
								<DatePicker
									id="commission_date"
									value={date ? moment.utc(date) : undefined}
									placeholder={strings.fields.date}
									style={{ width: "100%", height: 40 }}
									onChange={(value) => {
										this.setState({ date: value?.toISOString(), hasUnsavedFields: true });
									}}
									allowClear={false}
								/>
							) : (
								<Input
									id="commission_date"
									value={date ? DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT) : ""}
									placeholder={strings.fields.date}
									style={{ height: 40 }}
									readOnly
								/>
							)}
						</Col>
						<Col xs={24} md={12}>
							<label htmlFor="commission_type" className={`InputLabel${isNew ? " --label-required" : ""}`}>
								{strings.settings.type}
							</label>
							<Select
								id="commission_type"
								showSearch
								onChange={(value) => {
									this.setState({ type: value, hasUnsavedFields: true });
								}}
								disabled={!isNew}
								className={!isNew ? "DisabledSelect" : ""}
								value={type}
								placeholder={strings.settings.type}
								showArrow={isNew}
								style={{ width: "100%" }}
								allowClear={false}
							>
								<Select.Option value="commissions">{strings.sidebar.commissions}</Select.Option>
								<Select.Option value="bonus">{strings.generic.bonus}</Select.Option>
							</Select>
						</Col>
						<Col xs={24} md={12}>
							<label htmlFor="commission_partner" className={`InputLabel${isNew ? " --label-required" : ""}`}>
								{strings.staff.partner}
							</label>
							<Select
								id="commission_partner"
								showSearch
								filterOption={false}
								onSearch={this.handleSearch}
								onChange={(value) => {
									this.setState({ partner: value, hasUnsavedFields: true });
								}}
								disabled={!isNew}
								className={!isNew ? "DisabledSelect" : ""}
								value={partner}
								placeholder={strings.staff.partner}
								showArrow={isNew}
								style={{ width: "100%" }}
								notFoundContent={
									<div>{searchParams === "" ? strings.generic.searchByEmailOrName : strings.generic.noResults}</div>
								}
								allowClear
							>
								{defaultPartners?.map((partner: any) => (
									<Select.Option value={partner._id} key={partner._id}>
										{partner.name} ({partner.email})
									</Select.Option>
								))}
							</Select>
						</Col>
						<Col xs={24} md={12}>
							<label htmlFor="commission_origin" className="InputLabel">
								{strings.generic.from}
							</label>
							<Select
								id="commission_origin"
								disabled
								className="DisabledSelect"
								value={origin}
								placeholder={strings.generic.from}
								showArrow={false}
								style={{ width: "100%" }}
							>
								{defaultOrigins?.map((partner: any) => (
									<Select.Option value={partner._id} key={partner._id}>
										{partner.name} ({partner.email})
									</Select.Option>
								))}
							</Select>
						</Col>
						<Col xs={24} md={12}>
							<label htmlFor="commission_points" className="InputLabel">
								{strings.products.points}
							</label>
							<Input
								id="commission_points"
								value={points || ""}
								type="number"
								placeholder={strings.products.points}
								style={{ height: 40, color: points > 0 ? "#52c41a" : points < 0 ? "#f5222d" : "#333", fontWeight: 500 }}
								readOnly={!isNew}
								onChange={(e) => this.setState({ points: e.target.value, hasUnsavedFields: true })}
							/>
						</Col>
						<Col xs={24} md={12}>
							<label htmlFor="commission_value" className="InputLabel">
								{strings.settings.value}
							</label>
							<InputNumber
								className="CommissionNumberInput"
								id="commission_value"
								placeholder="0.00"
								style={{ width: "100%", color: value > 0 ? "#52c41a" : value < 0 ? "#f5222d" : "#333", fontWeight: 500 }}
								prefix="€"
								type="number"
								step={0.1}
								value={value || undefined}
								readOnly={!isNew}
								onChange={(value) => {
									this.setState({ value, hasUnsavedFields: true });
								}}
							/>
						</Col>
						<Col xs={24}>
							<label htmlFor="commission_notes" className="InputLabel">
								{strings.products.notes}
							</label>
							<Input.TextArea
								id="commission_notes"
								value={notes || ""}
								placeholder={strings.products.notes}
								rows={4}
								style={{ resize: "none" }}
								readOnly={!isNew}
								onChange={(e) => this.setState({ notes: e.target.value, hasUnsavedFields: true })}
							/>
						</Col>
					</Row>
					{!isNew && (
						<React.Fragment>
							<div className="ScreenHeader --mt-40">
								<div className="ScreenHeaderLeft">
									<Icon name="preferences" />
									<h2>{strings.users.status}</h2>
								</div>
							</div>
							<Row gutter={[20, 20]}>
								<Col xs={24} md={12}>
									<label htmlFor="commission_status" className="InputLabel">
										{strings.users.status}
									</label>
									<Input
										id="commission_status"
										value={expired ? strings.commissions.expired : strings.faqs.isActive}
										placeholder={strings.users.status}
										style={{ height: 40 }}
										readOnly
									/>
								</Col>
								<Col xs={24} md={12}>
									<label htmlFor="commission_used_balance" className="InputLabel">
										{strings.commissions.usedBalance}
									</label>
									<InputNumber
										id="commission_used_balance"
										className="CommissionNumberInput"
										placeholder="0.00"
										style={{
											width: "100%",
											color: usedBalance > 0 ? "#f5222d" : value < 0 ? "#52c41a" : "#333",
											fontWeight: 500
										}}
										prefix="€"
										type="number"
										value={usedBalance || 0}
									/>
								</Col>
							</Row>
						</React.Fragment>
					)}
				</ContentWrapper>
			</div>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language,
	user: state.user
});

export default connect(mapStateToProps)(CommissionDetail);
