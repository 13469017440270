import React from "react";
import { Button, Col, Input, Row, Select, notification } from "antd";
import { ContentWrapper, Icon, PhoneInput } from "components";
import { push } from "connected-react-router";
import { DateTime } from "luxon";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { delayedDispatch, setBreadcrumb, setLoader, setTitle, updateCrumb } from "store/actions";
import { API, Endpoints } from "utils/api";
import strings from "utils/strings";
import "./styles.scss";

import mbway from "assets/images/mbway.png";
import creditCard from "assets/images/credit_card.png";
import placeholder from "assets/images/placeholders/image.jpg";
import { formatPrice, translate } from "utils/utils";

class OrderDetail extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			shippingProducts: [],
			creatingShipping: false,
			defaultShippings: [],
			editingShipping: ""
		};
	}

	async componentDidMount() {
		const { dispatch } = this.props;

		dispatch(setTitle(""));

		await this.getData();
		this.breadcrumb();
	}

	componentDidUpdate() {
		const { dispatch } = this.props;

		dispatch(updateCrumb());
	}

	async getData() {
		const { dispatch, match, partners } = this.props;
		const { id } = match.params;

		dispatch(setLoader(true));

		try {
			const [response, responseShippings] = await Promise.all([
				API.get({
					url: Endpoints.uriOrders(id)
				}),
				API.get({
					url: Endpoints.uriShipping()
				})
			]);

			if (responseShippings?.ok) {
				const { shippings = [] } = responseShippings.data.results;
				this.setState({ defaultShippings: shippings });
			} else {
				notification.error({
					message: strings.sidebar.orders,
					description: response.data?.message || strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}

			if (response?.ok) {
				const { order } = response.data.results;

				this.setState({ order, ...order });
				dispatch(setTitle(`${strings.orders.orderDetail} - #${this.state.orderNumber}`));
			} else {
				notification.error({
					message: strings.sidebar.orders,
					description: response.data?.message || strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			if (partners) {
				dispatch(push("/partner-orders"));
			} else {
				dispatch(push("/orders"));
			}

			notification.error({
				message: strings.serverErrors.title,
				description: (err as string) || strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async closeOrder() {
		const { isClosed } = this.state;
		const { dispatch, match } = this.props;
		const { id } = match.params;

		dispatch(setLoader(true));

		try {
			const response = await API.patch({
				url: Endpoints.uriOrders(`${id}/close`),
				data: { isClosed: !isClosed }
			});

			if (response?.ok) {
				const { order } = response.data.results;
				this.setState({ order, ...order });

				notification.success({
					message: strings.sidebar.orders,
					description: response?.data?.message,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: strings.sidebar.orders,
					description: response.data?.message || strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: strings.serverErrors.title,
				description: (err as string) || strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async createShipping() {
		const { tempShipping } = this.state;
		const { dispatch, match } = this.props;
		const { id } = match.params;

		if (!this.validShipment()) return;

		dispatch(setLoader(true));

		try {
			tempShipping.products = tempShipping.products.filter((product: any) => product.quantity > 0);

			const response = await API.post({
				url: Endpoints.uriOrders(`${id}/shipping`),
				data: { ...tempShipping }
			});

			if (response?.ok) {
				const { order } = response.data.results;
				this.setState({ tempShipping: null, creatingShipping: false, order, ...order });

				notification.success({
					message: strings.settings.shippings,
					description: response?.data?.message,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: strings.settings.shippings,
					description: response.data?.message || strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: strings.serverErrors.title,
				description: (err as string) || strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async updateOrderStatus(type: string) {
		const { paymentStatus, shippingStatus } = this.state;
		const { dispatch, match } = this.props;
		const { id } = match.params;

		dispatch(setLoader(true));

		try {
			let endpoint = "",
				body = {} as any;
			if (type === "paymentStatus") {
				endpoint = "payment-status";
				body.paymentStatus = paymentStatus;
			} else {
				endpoint = "shipping-status";
				body.shippingStatus = shippingStatus;
			}

			const response = await API.patch({
				url: Endpoints.uriOrders(`${id}/${endpoint}`),
				data: body
			});

			if (response?.ok) {
				const { order } = response.data.results;
				this.setState({ order, ...order });

				notification.success({
					message: strings.sidebar.orders,
					description: response?.data?.message,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: strings.sidebar.orders,
					description: response.data?.message || strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: strings.serverErrors.title,
				description: (err as string) || strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async saveShipping() {
		const { tempEditShipping } = this.state;
		const { dispatch, match } = this.props;
		const { id } = match.params;

		if (!tempEditShipping?.provider?.shippingProvider || !tempEditShipping?.provider?.trackingNumber) {
			notification.warn({
				message: strings.settings.shippings,
				description: strings.orders.shippingsEmptyFields,
				placement: "bottomRight",
				duration: 5
			});
			return;
		}

		dispatch(setLoader(true));

		try {
			const response = await API.put({
				url: Endpoints.uriOrders(`${id}/shipping`),
				data: {
					provider: tempEditShipping.provider,
					_id: tempEditShipping._id
				}
			});

			if (response?.ok) {
				const { order } = response.data.results;
				this.setState({ tempEditShipping: null, editingShipping: "", order, ...order });

				notification.success({
					message: strings.settings.shippings,
					description: response?.data?.message,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: strings.settings.shippings,
					description: response.data?.message || strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: strings.serverErrors.title,
				description: (err as string) || strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async updateShippingStatus(status: string, shippingId: string) {
		const { dispatch, match } = this.props;
		const { id } = match.params;

		dispatch(setLoader(true));

		try {
			const response = await API.patch({
				url: Endpoints.uriOrders(`${id}/shipping`),
				data: {
					_id: shippingId,
					status
				}
			});

			if (response?.ok) {
				const { order } = response.data.results;
				this.setState({ order, ...order });

				notification.success({
					message: strings.settings.shippings,
					description: response?.data?.message,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: strings.settings.shippings,
					description: response.data?.message || strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: strings.serverErrors.title,
				description: (err as string) || strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	breadcrumb() {
		const { dispatch, partners } = this.props;

		dispatch(setBreadcrumb(null));
		delayedDispatch(
			setBreadcrumb(() => {
				const { isClosed, paymentStatus } = this.state;
				const { partners } = this.props;
				const defaultShippingStatus = this.getDefaultShippingStatus();

				return {
					locations: [
						{
							text: strings.sidebar.orders,
							route: partners ? "/partner-orders" : "/orders",
							icon: "box"
						},
						{
							text: `#${this.state.orderNumber}`,
							icon: "box"
						}
					],
					actions: [
						{
							type: "select",
							text: strings.orders.paymentStatus,
							value: paymentStatus,
							onChange: (option: string) => {
								this.setState({ paymentStatus: option }, () => {
									this.updateOrderStatus("paymentStatus");
								});
							},
							options: [
								{ text: strings.orders.paymentStatuses.pending_payment, value: "pending_payment" },
								{ text: strings.orders.paymentStatuses.paid, value: "paid" }
							],
							minWidth: 150,
							disabled: partners
						},
						{
							type: "select",
							text: strings.orders.shippingStatus,
							value: this.state.shippingStatus,
							onChange: (option: string) => {
								this.setState({ shippingStatus: option }, () => {
									this.updateOrderStatus("shippingStatus");
								});
							},
							options:
								this.state.shippingStatus === "pending_processing" || this.state.shippingStatus === "processing"
									? defaultShippingStatus
											.filter((status) => status.value === "pending_processing" || status.value === "processing")
											.map((status) => ({
												value: status.value,
												text: status.label
											}))
									: defaultShippingStatus.map((status) => ({
											value: status.value,
											text: status.label
									  })),
							minWidth: 150,
							disabled:
								!(this.state.shippingStatus === "pending_processing" || this.state.shippingStatus === "processing") ||
								partners,
							margin: "left"
						},
						{
							type: "button",
							text: strings.orders.close,
							onClick: () => this.closeOrder(),
							visible: this.state.shippingStatus !== "shipped" && !isClosed,
							className: "BreadcrumbForgetButton",
							hasIcon: true,
							icon: <Icon name="lock" />,
							separator: "left",
							disabled: partners
						},
						{
							type: "button",
							text: strings.orders.reopen,
							onClick: () => this.closeOrder(),
							visible: isClosed,
							className: "BreadcrumbOpenButton",
							hasIcon: true,
							icon: <Icon name="lock" />,
							separator: "left"
						}
					]
				};
			})
		);
	}

	validShipment() {
		const { tempShipping } = this.state;

		if (!tempShipping?.provider?.shippingProvider) {
			notification.warn({
				message: strings.orders.shipments,
				description: strings.orders.shippingProviderRequired,
				placement: "bottomRight",
				duration: 5
			});

			return false;
		}

		if (!tempShipping?.products || tempShipping?.products?.length === 0 || tempShipping?.products?.every((p: any) => !p?.quantity)) {
			notification.warn({
				message: strings.orders.shipments,
				description: strings.orders.shippingProductsRequired,
				placement: "bottomRight",
				duration: 5
			});

			return false;
		}

		return true;
	}

	getPaymentStatus(status: string) {
		const statusString = status as keyof typeof strings.orders.paymentStatuses;
		return strings.orders.paymentStatuses[statusString] || "-";
	}

	getShippingStatus(status: string) {
		const statusString = status as keyof typeof strings.orders.shippingStatuses;
		return strings.orders.shippingStatuses[statusString] || "-";
	}

	getDefaultPaymentStatus(paymentStatus: string) {
		if (paymentStatus === "pending_payment") {
			return [
				{ label: strings.orders.paymentStatuses.pending_payment, value: "pending_payment" },
				{ label: strings.orders.paymentStatuses.paid, value: "paid" }
			];
		}
	}

	getDefaultShippingStatus() {
		return [
			{ label: strings.orders.shippingStatuses.pending_processing, value: "pending_processing" },
			{ label: strings.orders.shippingStatuses.processing, value: "processing" },
			{ label: strings.orders.shippingStatuses.partially_shipped, value: "partially_shipped" },
			{ label: strings.orders.shippingStatuses.shipped, value: "shipped" },
			{ label: strings.orders.shippingStatuses.delivered, value: "delivered" },
			{ label: strings.orders.shippingStatuses.partially_returned, value: "partially_returned" },
			{ label: strings.orders.shippingStatuses.returned, value: "returned" },
			{ label: strings.orders.shippingStatuses.closed, value: "closed" },
			{ label: strings.orders.shippingStatuses.cancelled, value: "cancelled" },
			{ label: strings.orders.shippingStatuses.awaiting_pickup, value: "awaiting_pickup" },
			{ label: strings.orders.shippingStatuses.partially_pickedup, value: "partially_pickedup" },
			{ label: strings.orders.shippingStatuses.partially_created, value: "partially_created" },
			{ label: strings.orders.shippingStatuses.pickup_created, value: "pickup_created" }
		];
	}

	getPaymentName(paymentType: string) {
		switch (paymentType) {
			case "mbw":
				return "MB Way";
			case "bank_transfer":
				return strings.orders.bankTransfer;
			case "cash_on_delivery":
				return strings.orders.cashOnDelivery;
			case "cc":
				return strings.orders.creditCard;
			case "cash":
				return strings.orders.cash;
			default:
				return paymentType;
		}
	}

	getShippingHistoryOptions() {
		return [
			{ label: strings.orders.pickupCreated, value: "created" },
			{ label: strings.orders.shipped, value: "shipped" },
			{ label: strings.orders.cancelled, value: "cancel" },
			{ label: strings.orders.returned, value: "returned" },
			{ label: strings.orders.delivered, value: "delivered" }
		];
	}

	createTempShipping() {
		const { products = [] } = this.state;
		const shippingProducts = products
			?.map((product: any) => {
				return {
					...product,
					quantity: 0,
					maxQuantity: product.quantity - product.shippedQuantity,
					total: product.price
				};
			})
			.filter((product: any) => product.quantity >= 0 && product.maxQuantity > 0);

		this.setState({
			tempShipping: {
				products: shippingProducts,
				provider: {
					shippingProvider: undefined,
					trackingNumber: ""
				},
				date: DateTime.utc(),
				status: "pending",
				totalProducts: 0,
				weight: 0
			},
			creatingShipping: true
		});
	}

	renderClientInformation() {
		const { user, shipping, _created } = this.state;

		return (
			<ContentWrapper>
				<div className="ScreenHeader">
					<div className="ScreenHeaderLeft">
						<Icon name="user" />
						<h2>{strings.orders.clientInformation}</h2>
					</div>
				</div>
				<Row gutter={[20, 20]}>
					<Col xs={24} md={12}>
						<label htmlFor="order_user_name" className="InputLabel">
							{strings.fields.name}
						</label>
						<Input id="order_user_name" value={user?.name} readOnly style={{ height: 40 }} placeholder={strings.fields.name} />
					</Col>
					<Col xs={24} md={12}>
						<label htmlFor="order_user_email" className="InputLabel">
							{strings.fields.email}
						</label>
						<Input
							id="order_user_email"
							value={user?.email}
							readOnly
							style={{ height: 40 }}
							placeholder={strings.fields.email}
						/>
					</Col>
					<Col xs={24} md={12}>
						<label htmlFor="order_user_phone" className="InputLabel">
							{strings.fields.email}
						</label>
						<PhoneInput
							defaultCountry={(!user?.phone && shipping?.address?.country?.alpha2Code?.toLowerCase()) || "pt"}
							value={user?.phone || ""}
							disabled
							inputClass="input-phone phone-disabled"
						/>
					</Col>
					<Col xs={24} md={12}>
						<label htmlFor="order_date" className="InputLabel">
							{strings.orders.date}
						</label>
						<Input
							id="order_date"
							value={_created ? DateTime.fromISO(_created).toFormat("dd/MM/yyyy HH:mm") : "-"}
							readOnly
							style={{ height: 40 }}
							placeholder={strings.orders.date}
						/>
					</Col>
				</Row>
			</ContentWrapper>
		);
	}

	renderClientAddresses() {
		const { shipping, payment } = this.state;
		const { address: shippingAddress, provider, methodId } = shipping || {};
		const { address: billingAddress } = payment || {};

		const shippingVat = shippingAddress?.vatNumber ? `${strings.fields.vatNumber}: ${shippingAddress?.vatNumber}` : "";
		const shippingPhoneNumber = shippingAddress?.phoneNumber ? `${strings.fields.phoneShort}: ${shippingAddress?.phoneNumber}` : "";
		const billingVat = billingAddress?.vatNumber ? `${strings.fields.vatNumber}: ${billingAddress?.vatNumber}` : "";
		const billingPhoneNumber = billingAddress?.phoneNumber ? `${strings.fields.phoneShort}: ${billingAddress?.phoneNumber}` : "";

		let paymentImage = null;
		switch (payment?.paymentType) {
			case "mbw":
				paymentImage = <img src={mbway} alt="mbway" />;
				break;
			case "cc":
				paymentImage = <img src={creditCard} alt="creditCard" />;
				break;
			case "cash_on_delivery":
			case "cash":
				paymentImage = <Icon name="pay" />;
				break;
			case "bank_transfer":
				paymentImage = <Icon name="bank-transfer-logo" />;
				break;
			default:
				break;
		}

		const populatedMethod = provider?.methods?.find((m: any) => m._id === methodId);

		return (
			<ContentWrapper>
				<div className="ScreenHeader">
					<div className="ScreenHeaderLeft">
						<Icon name="repeat" />
						<h2>{strings.orders.addresses}</h2>
					</div>
				</div>
				<Row gutter={[20, 20]}>
					<Col xs={24} md={12}>
						<div className="OrderAddressBlock">
							<div className="OrderAddressTitle">
								<Icon name="directions" />
								<h3>{strings.orders.shippingAddress}</h3>
							</div>
							<div className="OrderAddressInfo --disabled">
								<strong>{shippingAddress?.name}</strong>
								<span>
									{shippingAddress?.address}
									{shippingAddress?.address2 ? `, ${shippingAddress?.address2}` : ""}
								</span>
								<span>
									{shippingAddress?.postalCode} {shippingAddress?.city}
								</span>
								<span>
									{shippingAddress?.state?.name}, {shippingAddress?.country?.name}
								</span>
								{Boolean(shippingVat) || Boolean(shippingPhoneNumber) ? (
									<span>
										{shippingVat}
										{shippingVat ? ` | ${shippingPhoneNumber}` : shippingPhoneNumber}
									</span>
								) : null}
							</div>
						</div>
					</Col>
					<Col xs={24} md={12}>
						<div className="OrderAddressBlock">
							<div className="OrderAddressTitle">
								<Icon name="directions" />
								<h3>{strings.orders.billingAddress}</h3>
							</div>
							<div className="OrderAddressInfo --disabled">
								<strong>{billingAddress?.name}</strong>
								<span>
									{billingAddress?.address}
									{billingAddress?.address2 ? `, ${billingAddress?.address2}` : ""}
								</span>
								<span>
									{billingAddress?.postalCode} {billingAddress?.city}
								</span>
								<span>
									{billingAddress?.state?.name}, {billingAddress?.country?.name}
								</span>
								{Boolean(billingVat) || Boolean(billingPhoneNumber) ? (
									<span>
										{billingVat}
										{billingVat ? ` | ${billingPhoneNumber}` : billingPhoneNumber}
									</span>
								) : null}
							</div>
						</div>
					</Col>
				</Row>
				<div className="ScreenHeader --mt-20">
					<div className="ScreenHeaderLeft">
						<Icon name="preferences" />
						<h2>{strings.orders.shippingAndPayment}</h2>
					</div>
				</div>
				<Row gutter={[20, 20]}>
					<Col xs={24} md={12}>
						<div className="OrderColorBlock">
							<div className="OrderColorTitle">
								<Icon name="delivery-truck" />
								<h3>{strings.settings.shippingMethod}</h3>
							</div>
							<div className="OrderColorInfo">
								<div className="OrderColorImage">
									<img height="40" width="40" src={provider?.image || placeholder} alt={provider?.name} />
								</div>
								<div className="OrderColorName">
									<strong>{translate(populatedMethod?.name)}</strong>
									<span>{translate(populatedMethod?.description)}</span>
								</div>
							</div>
						</div>
					</Col>
					<Col xs={24} md={12}>
						<div className="OrderColorBlock">
							<div className="OrderColorTitle">
								<Icon name="wallet-solid" />
								<h3>{strings.orders.paymentMethod}</h3>
							</div>
							<div className="OrderColorInfo">
								<div className="OrderColorImage">{paymentImage}</div>
								<div className="OrderColorName">
									<strong>{this.getPaymentName(payment?.paymentType)}</strong>
								</div>
							</div>
						</div>
					</Col>
				</Row>
			</ContentWrapper>
		);
	}

	renderOrderResume() {
		const { products, total, totalProducts, totalShipping, totalDiscount, taxes = [] } = this.state;

		return (
			<ContentWrapper>
				<div className="ScreenHeader">
					<div className="ScreenHeaderLeft">
						<Icon name="shopping-list" />
						<h2>{strings.orders.resume}</h2>
					</div>
				</div>
				<div className="OrderResumeContainer">
					<div className="OrderResumeSubtotal">
						<div className="OrderResumeRow">
							<strong>{strings.orders.subtotal}</strong>
							<span>
								{products?.length || 0} {products?.length === 1 ? strings.generic.item : strings.generic.items}
							</span>
							<span>{formatPrice(totalProducts)}</span>
						</div>
						<div className="OrderResumeRow">
							<strong>{strings.orders.shippingCost}</strong>
							<span>{formatPrice(totalShipping || 0)}</span>
						</div>
					</div>
					<div className="OrderResumeTaxes">
						<div className="OrderResumeRow">
							<strong>{strings.orders.taxes}</strong>
							<span>
								{taxes.map((tax: any) => {
									return <div key={`tax_${tax.value}`}>{strings.formatString(strings.orders.taxVat, tax.value)}</div>;
								})}
							</span>
							<span>
								{Array.isArray(taxes) && taxes.map((tax) => <div key={`tax_${tax.value}`}>{formatPrice(tax.amount)}</div>)}
							</span>
						</div>
					</div>
					<div className="OrderResumeDiscount">
						<div className="OrderResumeRow">
							<strong>{strings.fields.discount}</strong>
							<strong>{formatPrice(totalDiscount)}</strong>
						</div>
					</div>
					<div className="OrderResumeTotal">
						<div className="OrderResumeRow">
							<strong>{strings.fields.total}</strong>
							<strong>{formatPrice(total)}</strong>
						</div>
					</div>
				</div>
			</ContentWrapper>
		);
	}

	renderOrderProducts() {
		const { products } = this.state;

		return (
			<ContentWrapper>
				<div className="ScreenHeader">
					<div className="ScreenHeaderLeft">
						<Icon name="basket" />
						<h2>{strings.orders.productList}</h2>
					</div>
				</div>
				<div className="OrderProductsContainer">
					<div className="OrderProductsTable">
						<table>
							<tbody>
								{products?.map((product: any, index: number) => {
									return (
										<tr key={`product_${product._id}`}>
											<td>
												<div className="OrderProductColumn --no-padding">
													<img src={product.image} alt={translate(product.name)} />
												</div>
											</td>
											<td>
												<div className="OrderProductColumn">
													<strong>{translate(product.name)}</strong>
												</div>
											</td>
											<td>
												<div className="OrderProductColumn">
													<strong>{strings.products.reference}</strong>
													<span>{product.reference}</span>
												</div>
											</td>
											<td>
												<div className="OrderProductColumn">
													<strong className="--align-right">{strings.products.qtyOrdered}</strong>
													<span className="--align-right">{product.quantity}</span>
												</div>
											</td>
											<td>
												<div className="OrderProductColumn">
													<strong className="--align-right">{strings.products.qtyShipped}</strong>
													<span className="--align-right">{product.shippedQuantity}</span>
												</div>
											</td>
											<td>
												<div className="OrderProductColumn">
													<strong className="--align-right">{strings.products.qtyDelivered}</strong>
													<span className="--align-right">{product.deliveredQuantity}</span>
												</div>
											</td>
											<td>
												<div className="OrderProductColumn">
													<strong className="--align-right">{strings.products.price}</strong>
													<span className="--align-right">{formatPrice(product.price)}</span>
												</div>
											</td>
											<td>
												<div className="OrderProductColumn">
													<strong className="--align-right">{strings.fields.subTotal}</strong>
													<span className="--align-right">{formatPrice(product.total)}</span>
												</div>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				</div>
			</ContentWrapper>
		);
	}

	renderShippingList() {
		const { shipping, products, creatingShipping, tempShipping, defaultShippings, editingShipping, tempEditShipping } = this.state;
		const { partners } = this.props;
		const { history = [] } = shipping || {};

		const canCreateShipping = products?.some((product: any) => +product.shippedQuantity < +product.quantity);

		if (!history.length && !creatingShipping) {
			return (
				<ContentWrapper>
					<div className="ScreenHeader --multi">
						<div className="ScreenHeaderLeft">
							<Icon name="delivery-truck" />
							<h2>{strings.orders.shipments}</h2>
						</div>
						{canCreateShipping && (
							<Button
								disabled={partners}
								type="primary"
								style={{ marginBottom: 10 }}
								onClick={() => this.createTempShipping()}
							>
								{strings.orders.prepareShipment}
							</Button>
						)}
					</div>
					{history?.length === 0 && (
						<div className="OrderShippingProductsContainer">
							<div className="OrderShippingProductsTable">
								<div className="OrderShippingProductsEmpty">{strings.orders.noShipments}</div>
							</div>
						</div>
					)}
				</ContentWrapper>
			);
		}

		return (
			<ContentWrapper>
				<div className="ScreenHeader --multi">
					<div className="ScreenHeaderLeft">
						<Icon name="delivery-truck" />
						<h2>{strings.orders.shipments}</h2>
					</div>
					{canCreateShipping && !creatingShipping && (
						<Button type="primary" style={{ marginBottom: 10 }} onClick={() => this.createTempShipping()}>
							{strings.orders.prepareShipment}
						</Button>
					)}
				</div>
				{creatingShipping && Boolean(tempShipping) && this.renderNewShipping()}
				{!creatingShipping &&
					history?.map((shipping: any) => {
						const { products = [] } = shipping;
						const total = products.reduce((acc: any, product: any) => acc + product.total, 0);

						return (
							<div key={`shipping_history_${shipping._id}`} className="OrderShippingProductsContainer">
								<div className="OrderShippingProductsTableHeader">
									<Row gutter={[20, 10]}>
										<Col xs={24}>
											<h3>{DateTime.fromISO(shipping.date).toFormat("dd-MM-yyyy HH:mm")}</h3>
										</Col>
										<Col xs={24} md={18}>
											<div className="OrderShippingHeaderRow">
												<div>
													<label htmlFor="shipping_provider" className="InputLabel">
														{strings.settings.shipping}:
													</label>
													<Select
														id="shipping_provider"
														style={{ width: "100%" }}
														placeholder={strings.settings.shipping}
														showSearch
														filterOption={(input: any, option: any) =>
															option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
														}
														value={
															editingShipping === shipping._id
																? tempEditShipping?.provider?.shippingProvider
																: shipping?.provider?.shippingProvider || null
														}
														onChange={(elem: any) => {
															tempEditShipping.provider.shippingProvider = elem;
															this.setState({ tempEditShipping });
														}}
														disabled={editingShipping !== shipping._id}
													>
														{defaultShippings.map((shipping: any) => (
															<Select.Option key={shipping._id} value={shipping._id}>
																{shipping?.name}
															</Select.Option>
														))}
													</Select>
												</div>
												<div>
													<label htmlFor="shipping_object_number" className="InputLabel">
														{strings.orders.objectNumber}:
													</label>
													<Input
														id="shipping_object_number"
														style={{ height: 40 }}
														value={
															(editingShipping === shipping._id
																? tempEditShipping?.provider?.trackingNumber
																: shipping?.provider?.trackingNumber) || ""
														}
														onChange={(e) => {
															tempEditShipping.provider.trackingNumber = e.target.value;
															this.setState({ tempEditShipping });
														}}
														placeholder={strings.orders.objectNumber}
														disabled={editingShipping !== shipping._id}
													/>
												</div>
												{!editingShipping && (
													<div className="OrderShippingHeaderRowActions">
														<label style={{ display: "block" }} className="InputLabel">
															&nbsp;
														</label>
														<Button
															type="primary"
															onClick={() =>
																this.setState({
																	editingShipping: shipping._id,
																	tempEditShipping: JSON.parse(JSON.stringify(shipping))
																})
															}
															disabled={editingShipping}
														>
															{strings.fields.edit}
														</Button>
													</div>
												)}
												{editingShipping === shipping._id && (
													<React.Fragment>
														<div className="OrderShippingHeaderRowActions">
															<label style={{ display: "block" }} className="InputLabel">
																&nbsp;
															</label>
															<Button type="primary" onClick={() => this.saveShipping()}>
																{strings.generic.save}
															</Button>
														</div>
														<div className="OrderShippingHeaderRowActions">
															<label style={{ display: "block" }} className="InputLabel">
																&nbsp;
															</label>
															<Button
																type="primary"
																onClick={() =>
																	this.setState({ editingShipping: "", tempEditShipping: null })
																}
															>
																{strings.generic.cancel}
															</Button>
														</div>
													</React.Fragment>
												)}
											</div>
										</Col>
										<Col xs={24} md={6}>
											<div className="OrderShippingHeaderStatus">
												<label htmlFor="shipping_status" className="InputLabel">
													{strings.orders.shippingStatus}:
												</label>
												<Select
													id="shipping_status"
													style={{ width: "100%", maxWidth: 200 }}
													placeholder={strings.settings.shipping}
													showSearch
													filterOption={(input: any, option: any) =>
														option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
													}
													value={shipping?.status || null}
													onChange={(elem: any) => this.updateShippingStatus(elem, shipping._id)}
													disabled={shipping?.status !== "shipped" && shipping?.status !== "created"}
												>
													{this.getShippingHistoryOptions().map((option: any) => (
														<Select.Option key={option.value} value={option.value}>
															{option.label}
														</Select.Option>
													))}
												</Select>
											</div>
										</Col>
									</Row>
								</div>
								<div className="OrderShippingProductsTable">
									<table>
										<tbody>
											{products?.map((product: any) => {
												return (
													<tr key={`product_${product._id}`}>
														<td>
															<div className="OrderProductColumn --no-padding">
																<img src={product.image} alt={translate(product.name)} />
															</div>
														</td>
														<td>
															<div className="OrderProductColumn">
																<strong>{translate(product.name)}</strong>
															</div>
														</td>
														<td>
															<div className="OrderProductColumn">
																<strong>{strings.products.reference}</strong>
																<span>{product.reference}</span>
															</div>
														</td>
														<td>
															<div className="OrderProductColumn">
																<strong className="--align-right">{strings.fields.quantity}</strong>
																<span className="--align-right">{product.quantity}</span>
															</div>
														</td>
														<td>
															<div className="OrderProductColumn">
																<strong className="--align-right">{strings.products.price}</strong>
																<span className="--align-right">{formatPrice(product.price)}</span>
															</div>
														</td>
														<td>
															<div className="OrderProductColumn">
																<strong className="--align-right">{strings.fields.subTotal}</strong>
																<span className="--align-right">{formatPrice(product.total)}</span>
															</div>
														</td>
													</tr>
												);
											})}
											<tr>
												<td>&nbsp;</td>
												<td>&nbsp;</td>
												<td>&nbsp;</td>
												<td>&nbsp;</td>
												<td>&nbsp;</td>
												<td>
													<div className="OrderProductColumn">
														<strong className="--align-right">{strings.fields.total}</strong>
														<span className="--align-right">{formatPrice(total)}</span>
													</div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						);
					})}
			</ContentWrapper>
		);
	}

	renderNewShipping() {
		const { tempShipping, creatingShipping, defaultShippings } = this.state;
		const { products = [] } = tempShipping || {};

		if (!creatingShipping) return null;

		let addonBefore;
		const provider = defaultShippings.find((s: any) => s._id === tempShipping?.provider?.shippingProvider);

		if (provider?.link) {
			addonBefore = <span>{provider.link}</span>;
		}

		return (
			<React.Fragment>
				<div className="OrderShippingProductsContainer">
					<div className="OrderShippingProductsTable">
						<div className="OrderShippingHeader">
							<Row gutter={[20, 20]}>
								<Col xs={24} md={16}>
									<Row gutter={[20, 5]}>
										<Col xs={24}>
											<div className="OrderShippingHeaderRow">
												<label htmlFor="new_shipping_provider" className="InputLabel">
													{strings.settings.shipping}:
												</label>
												<Select
													id="new_shipping_provider"
													style={{ width: "100%", maxWidth: 200 }}
													placeholder={strings.settings.shipping}
													showSearch
													filterOption={(input: any, option: any) =>
														option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
													}
													value={tempShipping?.provider?.shippingProvider || null}
													onChange={(elem: any) => {
														this.setState((prevState: any) => ({
															tempShipping: {
																...prevState.tempShipping,
																provider: {
																	...prevState.tempShipping?.provider,
																	shippingProvider: elem
																}
															}
														}));
													}}
												>
													{defaultShippings.map((shipping: any) => (
														<Select.Option key={shipping._id} value={shipping._id}>
															{shipping?.name}
														</Select.Option>
													))}
												</Select>
											</div>
										</Col>
										<Col xs={24}>
											<div className="OrderShippingHeaderRow">
												<label htmlFor="shipping_object_number" className="InputLabel">
													{strings.orders.objectNumber}:
												</label>
												<Input
													addonBefore={addonBefore}
													id="shipping_object_number"
													style={{ height: 40, maxWidth: addonBefore ? "100%" : 200 }}
													value={tempShipping?.provider?.trackingNumber || ""}
													onChange={(e) => {
														const value = e.target.value;

														this.setState((prevState: any) => ({
															tempShipping: {
																...prevState.tempShipping,
																provider: {
																	...prevState.tempShipping?.provider,
																	trackingNumber: value
																}
															}
														}));
													}}
													placeholder={strings.orders.objectNumber}
												/>
											</div>
										</Col>
									</Row>
								</Col>
								<Col xs={24} md={8}>
									<h3>{DateTime.fromISO(tempShipping?.date).toFormat("dd-MM-yyyy")}</h3>
								</Col>
							</Row>
						</div>
						<table>
							<tbody>
								{products?.map((product: any) => {
									return (
										<tr key={`product_${product._id}`}>
											<td>
												<div className="OrderProductColumn --no-padding">
													<img src={product.image} alt={translate(product.name)} />
												</div>
											</td>
											<td>
												<div className="OrderProductColumn">
													<strong>{translate(product.name)}</strong>
												</div>
											</td>
											<td>
												<div className="OrderProductColumn">
													<strong>{strings.products.reference}</strong>
													<span>{product.reference}</span>
												</div>
											</td>
											<td>
												<div className="OrderProductColumn --centered">
													<strong>{strings.fields.quantity}</strong>
													<div className="OrderProductQuantity">
														<Button
															size="small"
															disabled={product.quantity === 0}
															onClick={() => {
																if (product.quantity > 0) {
																	product.quantity = +product.quantity - 1;
																	product.total = product.price * product.quantity;
																	this.setState({ tempShipping });
																}
															}}
														>
															-
														</Button>
														<span>{product?.quantity || 0}</span>
														<Button
															size="small"
															disabled={product.quantity >= product.maxQuantity}
															onClick={() => {
																if (product.quantity < product.maxQuantity) {
																	product.quantity = +product.quantity + 1;
																	product.total = product.price * product.quantity;
																	this.setState({ tempShipping });
																}
															}}
														>
															+
														</Button>
													</div>
												</div>
											</td>
											<td>
												<div className="OrderProductColumn">
													<strong className="--align-right">{strings.orders.pricePerItem}</strong>
													<span className="--align-right">{formatPrice(product.price)}</span>
												</div>
											</td>
											<td>
												<div className="OrderProductColumn">
													<strong className="--align-right">{strings.fields.subTotal}</strong>
													<span className="--align-right">{formatPrice(product.total)}</span>
												</div>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				</div>
				<div className="OrderShippingActions">
					<Button onClick={() => this.createShipping()}>{strings.orders.createShipping}</Button>
					<Button onClick={() => this.setState({ tempShipping: null, creatingShipping: false })}>{strings.generic.cancel}</Button>
				</div>
			</React.Fragment>
		);
	}

	render() {
		const { orderNumber } = this.state;

		return (
			<React.Fragment>
				<Helmet>
					<title>{orderNumber != null ? `${strings.orders.orderDetail}: #${orderNumber}` : strings.generic.loading}</title>
					<meta name="description" content="Order details" />
				</Helmet>
				{this.renderClientInformation()}
				{this.renderClientAddresses()}
				{this.renderOrderResume()}
				{this.renderOrderProducts()}
				{this.renderShippingList()}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language
});

export default connect(mapStateToProps)(OrderDetail);
