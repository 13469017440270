// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root{--primary-color: #00b5b0;--light-primary: #19f1eb;--secondary-color: #22a8dd;--light-secondary: #b8e4f0;--app-background-color: whitesmoke;--logo-container-background: #151515;--text-color: #333;--gradient: linear-gradient(180deg, #00b5b0 1.04%, #19f1eb 100%);--gradient-border: linear-gradient(90deg, #00b5b0 1.04%, #19f1eb60 100%)}.ManualWrapper{display:flex;flex-direction:column;cursor:pointer;transition:all .3s ease-in-out;position:relative}.ManualWrapper .ManualDescription{margin-top:10px}.ManualWrapper .ManualDescription h2{font-size:14px;font-weight:600;margin-bottom:0;line-height:1}.ManualWrapper .ManualDescription small{display:block;color:#aaa;line-height:1.25;margin-top:5px}.ManualWrapper .ManualLevel{display:block;margin-top:10px;color:#aaa}.ManualWrapper .ManualOptions{position:absolute;top:10px;right:10px;display:flex;gap:10px;transition:all .3s ease-in-out}.ManualWrapper .ManualOptions .ManualOption{display:flex;align-items:center;justify-content:center;width:30px;height:30px;border-radius:50%;border:none;background-color:#fff;box-shadow:0 0 5px rgba(0,0,0,.2);transition:all .3s ease-in-out;cursor:pointer}.ManualWrapper .ManualOptions .ManualOption svg{font-size:16px}.ManualWrapper .ManualOptions .ManualOption:hover{transform:scale(1.1)}.ManualWrapper .ManualOptions .ManualOption:hover:not(.ManualOptionDelete){color:#00b5b0}.ManualWrapper .ManualOptions .ManualOption:hover.ManualOptionDelete{color:#f44336}.ManualWrapper .ManualLabel{position:absolute;top:10px;left:10px}.ManualWrapper .ManualLabel p{display:block;font-size:12px;font-weight:600;color:#fff;background-color:#22a8dd;padding:2px 10px;border-radius:5px}.ManualWrapper video,.ManualWrapper img{height:210px;background-color:#e0e0e0;box-shadow:0 0 5px rgba(0,0,0,.2)}.ManualWrapper img{object-fit:cover}.ManualWrapper:hover{transform:scale(1.05)}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#00b5b0",
	"lightPrimary": "#19f1eb",
	"secondaryColor": "#22a8dd",
	"lightSecondary": "#b8e4f0"
};
module.exports = exports;
