import React, { Component } from "react";
import { connect } from "react-redux";
import { notification } from "antd";
import { setLoader, setTitle } from "store/actions";
import Strings from "utils/strings";
import { API, Endpoints } from "utils/api";
import type { Product, Props, State } from "./types";
import type { LanguageSchema } from "utils/types";
import { Helmet } from "react-helmet";
import { Table } from "components";
import { translate } from "utils/utils";
import { push } from "connected-react-router";

class Products extends Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			products: [],
			page: 0,
			total: 0,
			pageSize: 100,
			columnSearch: null,
			globalSearch: ""
		};
	}

	componentDidMount(): void {
		const { dispatch } = this.props;
		this.getData();

		dispatch(setTitle(""));
	}

	async getData(pause?: boolean) {
		const { page, pageSize, columnSearch, globalSearch } = this.state;
		const { dispatch } = this.props;

		setTimeout(
			async () => {
				if (columnSearch !== this.state.columnSearch || globalSearch !== this.state.globalSearch) return;
				dispatch(setLoader(true));

				const body = {
					filters: columnSearch
						? Object.keys(columnSearch).map((elem) =>
								columnSearch[elem] !== ""
									? {
											field: elem,
											query: columnSearch[elem]
									  }
									: {}
						  )
						: [],
					page: page,
					perPage: pageSize,
					search: globalSearch
				};

				dispatch(setLoader(true));

				try {
					const response = await API.post({
						url: Endpoints.uriProducts("search"),
						data: body
					});

					if (response?.ok) {
						const { products = [] } = response.data.results || {};
						this.setState({ products });
					} else {
						notification.error({
							message: Strings.products.title,
							description: response?.data?.message,
							placement: "bottomRight",
							duration: 5
						});
					}
				} catch (err: unknown) {
					notification.error({
						message: Strings.serverErrors.title,
						description: (err as string) || Strings.serverErrors.wentWrong,
						placement: "bottomRight",
						duration: 5
					});
				}

				dispatch(setLoader(false));
			},
			pause ? 1000 : 0
		);
	}

	async updateStock(id: string, value: boolean) {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.patch({
				url: Endpoints.uriProducts(`${id}/stock`),
				data: { inStock: value }
			});

			if (response?.ok) {
				await this.getData();

				notification.success({
					message: Strings.products.title,
					description: response?.data?.message || Strings.products.updated,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.products.title,
					description: response?.data?.message,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err: unknown) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async toggleProduct(id: string, value: boolean) {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.patch({
				url: Endpoints.uriProducts(`${id}/status`),
				data: {
					isActive: !value
				}
			});

			if (response?.ok) {
				await this.getData();

				notification.success({
					message: Strings.products.title,
					description: response?.data?.message || Strings.products.updated,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.products.title,
					description: response?.data?.message,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err: unknown) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async deleteProduct(id: string) {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.delete({
				url: Endpoints.uriProducts(id)
			});

			if (response?.ok) {
				await this.getData();

				notification.success({
					message: Strings.products.title,
					description: response?.data?.message || Strings.products.deleted,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.products.title,
					description: response?.data?.message,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err: unknown) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	get pagination() {
		return {
			total: this.state.total,
			pageIndex: this.state.page,
			pageSize: this.state.pageSize,
			setPage: (page: number, size: number) => {
				this.setState({ page, pageSize: size }, () => this.getData());
			}
		};
	}

	get filtration() {
		const { globalSearch, columnSearch } = this.state;
		return {
			showGlobalSearch: true,
			showColumnSearch: true,
			defaultValues: { globalSearch, columnSearch },
			onGlobalSearch: (globalSearch: string) => {
				this.setState({ globalSearch, page: 0 }, () => this.getData());
			},
			onColumnSearch: (columnSearch: string) => {
				this.setState({ columnSearch, page: 0 }, () => this.getData());
			}
		};
	}

	renderTable() {
		const { products = [] } = this.state;
		const { dispatch } = this.props;

		return (
			<Table
				title={{
					icon: "box",
					title: Strings.products.title
				}}
				data={products}
				columns={[
					{
						Header: Strings.fields.image,
						id: "image",
						accessor: (row: any) => row.images?.find((image: any) => image.favorite)?.url || null,
						type: "image",
						maxWidth: 65
					},
					{
						Header: Strings.fields.name,
						id: "name",
						accessor: (row: any) => translate(row.name) || "-"
					},
					{
						Header: Strings.products.reference,
						id: "reference",
						accessor: (row: any) => row.reference || "-"
					},
					{
						Header: Strings.products.ean,
						id: "ean",
						accessor: (row: any) => row.ean || "-"
					},
					{
						Header: Strings.products.price,
						id: "price",
						accessor: (row: any) => (row.price != null ? `${(Math.round(row.price * 100) / 100).toFixed(2)}€` : "-")
					},
					{
						Header: Strings.products.stock,
						id: "stock",
						accessor: (row: any) => (row.isKit ? "-" : row.stock || 0),
						Filter: () => null
					}
				]}
				filterable
				fullPage
				isSinglePage
				paginated
				paginationApi={this.pagination}
				filtrationApi={this.filtration}
				add={{
					tooltip: Strings.users.addPartner,
					onClick: () => dispatch(push("/products/new"))
				}}
				actions={{
					edit: (obj: Product) => ({
						onClick: () => dispatch(push(`/products/${obj._id}`)),
						location: `/products/${obj._id}`
					}),
					toggle: (obj: Product) => ({
						value: obj.isActive,
						onChange: () => this.toggleProduct(obj._id!, obj.isActive)
					}),
					remove: (obj: Product) => ({
						onClick: () => this.deleteProduct(obj._id!)
					})
				}}
			/>
		);
	}

	render() {
		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.products.title}</title>
					<meta name="description" content="Description of Products" />
				</Helmet>
				{this.renderTable()}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: { language: keyof LanguageSchema }) => ({
	language: state.language
});

export default connect(mapStateToProps)(Products);
