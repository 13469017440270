import React, { Component } from "react";
import { Col, Table, Row, notification } from "antd";
import { ContentWrapper, Icon } from "components";
import { DateTime } from "luxon";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { delayedDispatch, setBreadcrumb, setLoader, setTitle, updateCrumb } from "store/actions";
import { API, Endpoints } from "utils/api";
import strings from "utils/strings";
import { formatPrice, getSuccessLevelBadge, xlsxSettings } from "utils/utils";
import moment from "moment";
import { push } from "connected-react-router";
import { Area } from "@ant-design/charts";
import xlsx from "json-as-xlsx";

class NewPartners extends Component<any, any> {
	searchTimeout: NodeJS.Timeout | undefined;

	constructor(props: any) {
		super(props);

		this.state = {
			data: [],
			startDate: moment().add({ months: -5 }).startOf("month"),
			endDate: moment().endOf("month"),
			partner: undefined,
			partnerOptions: [],
			partnerSearch: ""
		};
	}

	async componentDidMount(): Promise<void> {
		const { dispatch } = this.props;

		dispatch(setTitle(""));

		await this.getData();
		this.breadcrumb();
	}

	componentDidUpdate(): void {
		const { dispatch } = this.props;
		dispatch(updateCrumb());
	}

	async getData() {
		const { startDate, endDate, partner } = this.state;
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		const body = {
			startDate: startDate.toISOString(),
			endDate: endDate.toISOString(),
			type: "new-partner",
			partner
		};

		try {
			const response = await API.post({
				url: Endpoints.uriCommissions("search-by-type"),
				data: body
			});

			if (response.ok) {
				const { commissions = [], cards, commissionsChart } = response.data.results;

				this.setState({
					data: commissions,
					cards,
					commissionsChart
				});
			} else {
				notification.error({
					message: strings.commissions.sales,
					description: response?.data?.message || strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: strings.serverErrors.title,
				description: err as string,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	breadcrumb() {
		delayedDispatch(
			setBreadcrumb(() => {
				const { startDate, endDate, partnerOptions } = this.state;

				return {
					locations: [
						{
							text: strings.commissions.newPartners,
							icon: "organization"
						}
					],
					actions: [
						{
							type: "datePicker",
							text: strings.dashboard.period,
							dates: [startDate, endDate],
							monthly: true,
							onChange: this.handleSelect,
							className: "fixedPicker"
						}
					]
				};
			})
		);
	}

	handleSelect = (dates: any) => {
		const newDates = [];
		if (dates && dates[0]) {
			newDates.push(moment(dates[0]).startOf("day"));
		}

		if (dates && dates[1]) {
			newDates.push(moment(dates[1]).endOf("day"));
		}

		this.setState(
			{
				startDate: newDates[0],
				endDate: newDates[1]
			},
			() => this.getData()
		);
	};

	exportData = () => {
		const { data: exportData = [] } = this.state;

		const columns = [
			{ label: "Data", value: (row: any) => DateTime.fromISO(row.date).toFormat("dd/MM/yyyy HH:mm") },
			{ label: "Número de Encomenda", value: (row: any) => (row.order ? `#${row.order.orderNumber}` : "-") },
			{ label: "Novo Parceiro", value: (row: any) => `${row.origin.name} (#${row.origin.userCode})` },
			{ label: "Parceiro", value: (row: any) => `${row.partner.name} (#${row.partner.userCode})` },
			{ label: "Nível Adquirido", value: (row: any) => getSuccessLevelBadge(row?.order?.products?.[0]?.kitLevel) || "-" },
			{ label: "Pontos", value: (row: any) => row.points },
			{ label: "Valor", value: (row: any) => formatPrice(row.value) },
			{
				label: "Data de Expiração",
				value: (row: any) => (row.expirationDate ? DateTime.fromISO(row.expirationDate).toFormat("dd/MM/yyyy HH:mm") : "-")
			}
		];

		const data = [
			{
				sheet: "Bónus de Novos Parceiros",
				columns,
				content: exportData
			}
		];

		xlsx(data, xlsxSettings("Bónus de Novos Parceiros"));
	};

	renderMovements() {
		const { data = [] } = this.state;
		const { dispatch, countries } = this.props;

		return (
			<ContentWrapper>
				<div className="ScreenHeader">
					<div className="ScreenHeaderLeft">
						<Icon name="user" />
						<h2>{strings.commissions.newPartners}</h2>
					</div>
				</div>
				<Table
					style={{ marginTop: 20 }}
					columns={[
						{
							title: strings.fields.date,
							dataIndex: "date",
							key: "date",
							render: (date: string) => DateTime.fromISO(date).toFormat("dd/MM/yyyy HH:mm"),
							defaultSortOrder: "descend",
							sorter: (a, b) => DateTime.fromISO(a.date).toMillis() - DateTime.fromISO(b.date).toMillis(),
							width: 150
						},
						{
							title: strings.orders.orderNumber,
							dataIndex: "order",
							key: "order",
							render: (order: { orderNumber: number; products: Array<{ kitLevel: string }> }) => {
								if (order) {
									return (
										<a
											href={`/partner-orders/${order?.orderNumber}`}
											onClick={(e) => {
												e.preventDefault();
												dispatch(push(`/partner-orders/${order?.orderNumber}`));
											}}
										>
											#{order?.orderNumber}
										</a>
									);
								}

								return "-";
							},
							sorter: (a, b) => DateTime.fromISO(a._created).toMillis() - DateTime.fromISO(b._created).toMillis(),
							width: 150
						},
						{
							title: strings.users.newPartner,
							dataIndex: "origin",
							key: "origin",
							render: (origin: any) => {
								if (!origin) return "-";
								const countryCallingCode = origin.phone?.substring(0, 4).replace("+", "");
								let country;

								if (countryCallingCode) {
									country = countries
										.find((elem: any) => elem.callingCodes.includes(countryCallingCode))
										?.alpha2Code?.toLowerCase();
								}

								return (
									<span className="TableRowAligned">
										{country ? (
											<div className="react-tel-input">
												<div className="selected-flag">
													<span className={`flag ${country}`} />
												</div>
											</div>
										) : null}
										{origin.name}
										{Boolean(origin?.userCode) ? ` (#${origin.userCode})` : ""}
									</span>
								);
							},
							sorter: (a, b) => a.origin.name?.localeCompare(b.origin.name)
						},
						{
							title: strings.users.adquiredLevel,
							dataIndex: "order",
							key: "adquired-level",
							render: (order: { orderNumber: number; products: Array<{ kitLevel: string }> }) =>
								getSuccessLevelBadge(order?.products?.[0]?.kitLevel) || "-",
							sorter: (a, b) => a.order?.products?.[0]?.kitLevel?.localeCompare(b.order.products?.[0]?.kitLevel),
							width: 150
						},
						{
							title: strings.products.points,
							dataIndex: "points",
							key: "points",
							render: (value: number) => value,
							sorter: (a, b) => a.value - b.value,
							width: 150
						},
						{
							title: strings.commissions.value,
							dataIndex: "value",
							key: "value",
							render: (value: number) => formatPrice(value),
							sorter: (a, b) => a.value - b.value,
							width: 150
						},
						{
							title: strings.generic.expirationDate,
							dataIndex: "expirationDate",
							key: "expirationDate",
							render: (value: string) => (value ? DateTime.fromISO(value).toFormat("dd/MM/yyyy HH:mm") : "-"),
							align: "center",
							sorter: (a, b) => DateTime.fromISO(a._created).toMillis() - DateTime.fromISO(b._created).toMillis(),
							width: 150
						}
					]}
					dataSource={data}
					rowKey={(record) => record._id}
				/>
			</ContentWrapper>
		);
	}

	renderChart() {
		const { commissionsChart = [] } = this.state;

		const config = {
			data: commissionsChart,
			xField: "month",
			yField: "total",
			appendPadding: 10,
			smooth: true,
			animation: {
				appear: {
					animation: "path-in",
					duration: 0
				}
			},
			meta: {
				total: {
					alias: strings.users.newPartners
				}
			},
			tooltip: {
				formatter: (datum: any) => {
					return { name: strings.users.newPartners, value: datum.total };
				}
			}
		};

		return (
			<ContentWrapper extraStyle={{ marginBottom: 0 }}>
				<div className="ScreenHeader">
					<div className="ScreenHeaderLeft">
						<Icon name="diagram" />
						<h2>{strings.users.newPartners}</h2>
					</div>
				</div>
				<Area style={{ marginTop: 20 }} {...config} />
			</ContentWrapper>
		);
	}

	render() {
		const { cards, data } = this.state;
		const { available = 0, spent = 0, expired = 0, total = 0 } = cards || {};

		const CARDS = [
			{
				icon: "wallet",
				value: total,
				description: strings.commissions.earnedFromNewPartners,
				type: "currency"
			},
			{
				icon: "wallet",
				value: available,
				description: strings.wallet.available,
				type: "currency"
			},
			{
				icon: "wallet",
				value: spent,
				description: strings.wallet.spent,
				type: "currency"
			},
			{
				icon: "wallet",
				value: expired,
				description: strings.wallet.expired,
				type: "currency"
			}
		];

		return (
			<div className="CommissionPartners">
				<Helmet>
					<title>{strings.commissions.newPartners}</title>
					<meta name="description" content="Description of commission of new partners" />
				</Helmet>
				<div className="IconCards">
					<Row gutter={[20, 20]}>
						{CARDS.map((card, index) => (
							<Col xs={24} md={6} key={index}>
								<div className="IconCardOuterContainer">
									<div className="IconCard">
										<div className="IconCardHeader">
											<div className="IconCardHeaderMask" />
											<div className="IconCardType">
												<Icon name={card.icon} />
											</div>
										</div>
										<div className="IconCardBody">
											<span className="IconCardValue">
												{card.type === "currency" ? formatPrice(card.value) : card.value}
											</span>
											<h3 className="IconCardDescription">{card.description}</h3>
										</div>
									</div>
								</div>
							</Col>
						))}
						<Col xs={18}>{this.renderChart()}</Col>
						<Col xs={6}>
							<div className="IconCardOuterContainer">
								<div className="IconCard">
									<div className="IconCardHeader">
										<div className="IconCardHeaderMask" />
										<div className="IconCardType">
											<Icon name="user" />
										</div>
									</div>
									<div className="IconCardBody">
										<span className="IconCardValue">{data?.length || 0}</span>
										<h3 className="IconCardDescription">{strings.users.totalNewPartners}</h3>
									</div>
									<div className="IconCardBody">
										<span className="IconCardValue">{cards?.partnersWorkingForBinary || 0}</span>
										<h3 className="IconCardDescription">{strings.users.workingBinaryPartners}</h3>
									</div>
									<div className="IconCardBody">
										<span className="IconCardValue">
											{(cards?.totalPartners || 0) - (cards?.partnersWorkingForBinary || 0) || 0}
										</span>
										<h3 className="IconCardDescription">{strings.users.totalPartners}</h3>
									</div>
								</div>
							</div>
						</Col>
						<Col xs={24}>{this.renderMovements()}</Col>
					</Row>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language,
	countries: state.countries
});

export default connect(mapStateToProps)(NewPartners);
