/**
 *
 * Header
 *
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { Icon, Title, Breadcrumb } from "components";
import { adminSubDomains, localType } from "screens/App/routes";
import { formatPrice, getSuccessLevelBadge } from "utils/utils";

import "./styles.scss";
import strings from "utils/strings";

export class Header extends Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			isMobile: window.innerWidth < 992,
			showMenu: false
		};

		this.toggleSidebar = this.toggleSidebar.bind(this);
		this.handleResize = this.handleResize.bind(this);
	}

	componentDidMount() {
		window.addEventListener("resize", this.handleResize);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.handleResize);
	}

	handleResize() {
		this.setState({ isMobile: window.innerWidth < 992 });
	}

	toggleSidebar() {
		this.props.onToggleSidebar();
	}

	renderBurger() {
		const { sidebarOpen } = this.props;

		return (
			<div className="BurgerContainer" onClick={this.toggleSidebar}>
				{sidebarOpen ? <Icon name="dots-vertical" className="BurgerMenu __bigger" /> : <Icon name="menu" className="BurgerMenu" />}
			</div>
		);
	}

	renderTitle() {
		const { title } = this.props;

		return (
			<React.Fragment>
				<div className="HeaderLeftSection">
					{this.renderBurger()}
					{/* <span className="PageTitle">{title}</span> */}
					<Title title={title} />
				</div>
				<div className="HeaderRightSection" />
			</React.Fragment>
		);
	}

	renderSuccessLevel() {
		const { user } = this.props;

		if (!user?.partner?.successLevel) return null;

		return (
			<div className="HeaderPartnerPoints">
				<Icon name="star-full" />
				<span>{getSuccessLevelBadge(user?.partner?.successLevel)}</span>
			</div>
		);
	}

	renderOptions() {
		const { user } = this.props;
		const { partner } = user || {};

		const hostname = window.location.hostname?.split(".")?.[0];
		const isAdmin = (hostname === "localhost" && localType === "admin") || adminSubDomains.includes(hostname);

		return (
			<div className="HeaderOptions">
				{!isAdmin && this.renderSuccessLevel()}
				{!isAdmin && partner?.store != null ? (
					<button
						className="HeaderStoreButton"
						onClick={() => {
							if (hostname === "localhost") {
								window.open(`http://${partner.store}.edvdev.net`, "_blank");
								return;
							}

							const parsedHostname = window.location.hostname.split(".")?.slice(1)?.join(".");
							window.open(`${window.location.protocol}//${partner.store}.${parsedHostname}`, "_blank");
						}}
					>
						<Icon name="shop" />
						<span>{strings.generic.visitStore}</span>
					</button>
				) : null}
			</div>
		);
	}

	render() {
		const { sidebarOpen } = this.props;

		return (
			<div className="HeaderContainer">
				<div id="AppHeader" className="Header">
					{this.renderTitle()}
					{this.renderOptions()}
				</div>
				<Breadcrumb sidebarOpen={sidebarOpen} />
			</div>
		);
	}
}

const mapStateToProps = (state: any) => ({
	router: state.router,
	title: state.title,
	user: state.user,
	language: state.language,
	settings: state.settings
});

export default connect(mapStateToProps)(Header);
