import React from "react";
import { connect } from "react-redux";
import type { Manual as ManualType } from "screens/Admin/Manuals/types";
import { push } from "connected-react-router";
import { store } from "store";
import { Tooltip } from "antd";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { translate } from "utils/utils";
import Strings from "utils/strings";
import pdfThumbnail from "assets/images/pdf.png";
import "./styles.scss";

type State = { clicked: boolean };
type Props = ManualType & { dispatch: typeof store.dispatch; language: string; isEthic?: boolean };

class ManualItem extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			clicked: false
		};
	}

	renderOptions() {
		const { _id, url, dispatch, onDelete, isEthic } = this.props;

		return (
			<div className="ManualOptions">
				<Tooltip title={Strings.settings.viewManual}>
					<button
						className="ManualOption"
						onClick={(e: React.MouseEvent) => {
							e.preventDefault();
							e.stopPropagation();
							window.open(translate(url), "_blank");
						}}
					>
						<EyeOutlined translate={null} />
					</button>
				</Tooltip>
				<Tooltip title={Strings.settings.editManual}>
					<button
						className="ManualOption"
						onClick={() => {
							if (!isEthic) dispatch(push(`/settings/manuals/${_id}`));
							else dispatch(push(`/settings/ethics/${_id}`));
						}}
					>
						<EditOutlined translate={null} />
					</button>
				</Tooltip>
				<Tooltip title={Strings.settings.deleteManual}>
					<button
						className="ManualOption ManualOptionDelete"
						onClick={(e: React.MouseEvent) => {
							e.preventDefault();
							e.stopPropagation();

							if (typeof onDelete === "function") {
								onDelete();
							}
						}}
					>
						<DeleteOutlined translate={null} />
					</button>
				</Tooltip>
			</div>
		);
	}

	render() {
		const { clicked } = this.state;
		const { _id, url, title, description, dispatch, readonly = false, isEthic } = this.props;

		return (
			<div
				onClick={() => {
					if (!readonly) {
						if (!isEthic) dispatch(push(`/settings/manuals/${_id}`));
						else dispatch(push(`/settings/ethics/${_id}`));
					} else {
						window.open(translate(url), "_blank");
					}
				}}
				className="ManualWrapper"
			>
				{!readonly && this.renderOptions()}
				{!clicked ? (
					// @ts-ignore
					<img loading="lazy" src={pdfThumbnail} />
				) : (
					<iframe
						width="100%"
						height="210px"
						src={`${translate(url)}?modestbranding=1`}
						title="YouTube video player"
						frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						allowFullScreen
					/>
				)}
				<div className="ManualDescription">
					<h2>{translate(title).length > 100 ? `${translate(title).substring(0, 100)}...` : translate(title)}</h2>
					{translate(description)?.length > 0 ? (
						<small>
							{translate(description).length > 100
								? `${translate(description).substring(0, 100)}...`
								: translate(description)}
						</small>
					) : null}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state: { language: string }) => ({
	language: state.language
});

export default connect(mapStateToProps)(ManualItem);
