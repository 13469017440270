import React, { Component } from "react";
import { Col, Row, notification } from "antd";
import { ContentWrapper, Icon } from "components";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { setLoader, setTitle } from "store/actions";
import { API, Endpoints } from "utils/api";
import strings from "utils/strings";
import { formatPrice, getSuccessLevelBadge } from "utils/utils";

class Special extends Component<any, any> {
	searchTimeout: NodeJS.Timeout | undefined;

	constructor(props: any) {
		super(props);

		this.state = {
			data: []
		};
	}

	async componentDidMount(): Promise<void> {
		const { dispatch } = this.props;

		dispatch(setTitle(""));

		await this.getData();
	}

	async getData() {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		const body = { type: "special" };

		try {
			const response = await API.post({
				url: Endpoints.uriCommissions("search-by-type"),
				data: body
			});

			if (response.ok) {
				const { partner, eligible = false, settings } = response.data.results;

				this.setState({ partnerDetail: partner, eligible, settings });
			} else {
				notification.error({
					message: strings.commissions.special,
					description: response?.data?.message || strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: strings.serverErrors.title,
				description: err as string,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	renderPartner() {
		const { partnerDetail, eligible, settings } = this.state;
		const { bonuses } = settings || {};
		const { special } = bonuses || {};

		if (!special) return null;

		return (
			<Row gutter={[20, 20]}>
				{!eligible && (
					<Col xs={24}>
						<ContentWrapper>
							<div className="ScreenHeader">
								<div className="ScreenHeaderLeft">
									<Icon name="user" />
									<h2>{strings.commissions.eligibility}</h2>
								</div>
							</div>
							<p>
								<strong>
									{strings.commissions.actualRank}: {getSuccessLevelBadge(partnerDetail?.partner?.successLevel)}
								</strong>
							</p>
							<p className="CommissionsPartnersAttention">{strings.commissions.notEligibleSpecial}</p>
						</ContentWrapper>
					</Col>
				)}
				<Col xs={24}>
					<ContentWrapper>
						<div className="ScreenHeader">
							<div className="ScreenHeaderLeft">
								<Icon name="points" />
								<h2>{strings.fields.description}</h2>
							</div>
						</div>
						<p className="CommissionsPartnersAttention">{strings.commissions.specialDescription}</p>
					</ContentWrapper>
				</Col>
				<Col xs={24}>
					<ContentWrapper>
						<div className="ScreenHeader">
							<div className="ScreenHeaderLeft">
								<Icon name="points" />
								<h2>{strings.commissions.special}</h2>
							</div>
						</div>
						<Row>
							<Col xs={24} md={12}>
								<table className="CommissionTpvTable">
									<thead>
										<tr>
											<th>{strings.settings.successLevel}</th>
											<th>{strings.generic.bonus}</th>
										</tr>
									</thead>
									<tbody>
										{Object.entries(special)?.map((entry: any, index: number) => {
											const rank = partnerDetail?.partner?.rewards?.[entry[0]];
											const reached = rank?.achieved;

											const style: React.CSSProperties = {};
											if (reached) {
												style.backgroundColor = "#00b5b033";
												style.fontWeight = 600;
												style.color = "#00b5b0";
											}

											return (
												<tr key={index} style={style}>
													<td>{getSuccessLevelBadge(entry[0])}</td>
													<td>{formatPrice(entry[1])}</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</Col>
						</Row>
					</ContentWrapper>
				</Col>
			</Row>
		);
	}

	render() {
		return (
			<div className="CommissionSpecial">
				<Helmet>
					<title>{strings.commissions.special}</title>
					<meta name="description" content="Description of special commissions" />
				</Helmet>
				{this.renderPartner()}
			</div>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language,
	countries: state.countries
});

export default connect(mapStateToProps)(Special);
