import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

type Props = {
	value: string;
	style?: React.CSSProperties;
	className?: string;
	onChange: (value: string) => void;
};

export default function Editor(props: Props) {
	const { value, onChange, style, className } = props;

	return (
		<ReactQuill
			id="react-quill-editor"
			theme="snow"
			className={className}
			style={style}
			value={value}
			onChange={onChange}
			formats={[
				"header",
				"font",
				"size",
				"bold",
				"italic",
				"underline",
				"blockquote",
				"list",
				"bullet",
				"indent",
				"link",
				"image",
				"video",
				"color",
				"background",
				"align"
			]}
			modules={{
				toolbar: [
					[{ header: "1" }, { header: "2" }, { font: [] }],
					[{ size: [] }],
					["bold", "italic", "underline", "blockquote", "code-block"],
					[{ align: [] }, { align: "center" }, { align: "right" }, { align: "justify" }],
					[{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
					["link", "image", "video"],
					[{ color: [] }, { background: [] }],
					["clean"]
				],
				clipboard: {
					matchVisual: false
				}
			}}
		/>
	);
}
