import React, { Component } from "react";
import { Col, Table, Row, notification } from "antd";
import { ContentWrapper, Icon, ProgressBarSteps } from "components";
import { DateTime } from "luxon";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { setLoader, setTitle } from "store/actions";
import { API, Endpoints } from "utils/api";
import strings from "utils/strings";
import { capitalize, formatPrice, getSuccessLevelBadge } from "utils/utils";

class Incentives extends Component<any, any> {
	searchTimeout: NodeJS.Timeout | undefined;

	constructor(props: any) {
		super(props);

		this.state = {
			data: []
		};
	}

	async componentDidMount(): Promise<void> {
		const { dispatch } = this.props;

		dispatch(setTitle(""));

		await this.getData();
	}

	async getData() {
		const { partner } = this.state;
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		const body = {
			type: "incentives",
			partner
		};

		try {
			const response = await API.post({
				url: Endpoints.uriCommissions("search-by-type"),
				data: body
			});

			if (response.ok) {
				const { settings, eligible, currentBonus, accumulatedTpv, movements = [], partner } = response.data.results;

				this.setState({
					movements,
					settings,
					eligible,
					partnerDetail: partner,
					currentBonus,
					accumulatedTpv
				});
			} else {
				notification.error({
					message: strings.commissions.incentives,
					description: response?.data?.message || strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: strings.serverErrors.title,
				description: err as string,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	renderPartnerMovements() {
		const { movements = [] } = this.state;

		return (
			<ContentWrapper>
				<div className="ScreenHeader">
					<div className="ScreenHeaderLeft">
						<Icon name="diagram" />
						<h2>{strings.commissions.incentives}</h2>
					</div>
				</div>
				<Table
					style={{ marginTop: 20 }}
					columns={[
						{
							title: strings.fields.date,
							dataIndex: "date",
							render: (date: string) => DateTime.fromISO(date).toFormat("dd/MM/yyyy HH:mm"),
							defaultSortOrder: "descend",
							sorter: (a, b) => DateTime.fromISO(a.date).toMillis() - DateTime.fromISO(b.date).toMillis(),
							width: 150
						},
						{
							title: strings.fields.description,
							dataIndex: "notes",
							key: "notes",
							render: (notes: string) => notes || "-",
							sorter: (a, b) => a.description?.localeCompare(b.description)
						},
						{
							title: strings.generic.bonus,
							dataIndex: "bonus",
							render: (_: any, record: any) => {
								if (record.value != null) return formatPrice(record.value);
								return capitalize(record.prize);
							}
						},
						{
							title: strings.commissions.accumulatedTpv,
							dataIndex: "tpv",
							render: (tpv: number) => tpv || 0
						},
						{
							title: strings.generic.expirationDate,
							dataIndex: "expirationDate",
							render: (value: string) => (value ? DateTime.fromISO(value).toFormat("dd/MM/yyyy HH:mm") : "-"),
							align: "center",
							sorter: (a, b) => DateTime.fromISO(a.expirationDate).toMillis() - DateTime.fromISO(b.expirationDate).toMillis(),
							width: 150
						}
					]}
					dataSource={movements}
					rowKey={(record) => record._id}
				/>
			</ContentWrapper>
		);
	}

	renderProgressBar() {
		const { currentBonus, accumulatedTpv } = this.state;

		const steps = [];
		for (const incentive of currentBonus.incentives) {
			steps.push({
				value: incentive.tpv,
				element: incentive.type === "money" ? <small>{formatPrice(incentive.bonus)}</small> : <small>{incentive.prize}</small>,
				topElement: <small>{incentive.tpv}</small>
			});
		}

		steps.sort((a, b) => a.value - b.value);

		if (!steps.length) return null;

		return <ProgressBarSteps min={0} steps={steps} value={accumulatedTpv || 0} />;
	}

	renderIncentive() {
		const { currentBonus, accumulatedTpv } = this.state;

		if (!currentBonus) return null;

		return (
			<ContentWrapper>
				<div className="ScreenHeader">
					<div className="ScreenHeaderLeft">
						<Icon name="points" />
						<h2>{strings.commissions.progressAdditional}</h2>
					</div>
				</div>
				<Row gutter={[20, 20]}>
					<Col xs={24}>{this.renderProgressBar()}</Col>
					<Col xs={24} md={6}>
						<div className="IconCardOuterContainer">
							<div className="IconCard">
								<div className="IconCardHeader">
									<div className="IconCardHeaderMask" />
									<div className="IconCardType">
										<Icon name="points" />
									</div>
								</div>
								<div className="IconCardBody">
									<span className="IconCardValue">{accumulatedTpv || 0}</span>
									<h3 className="IconCardDescription">{strings.commissions.accumulatedTpv}</h3>
								</div>
							</div>
						</div>
					</Col>
					<Col xs={24} md={8}>
						<table className="CommissionTpvTable">
							<thead>
								<tr>
									<th>{strings.commissions.accumulatedTpv}</th>
									<th>{strings.generic.bonus}</th>
								</tr>
							</thead>
							<tbody>
								{currentBonus?.incentives?.map((entry: any, index: number) => {
									const reached = accumulatedTpv >= entry.tpv;

									const style: React.CSSProperties = {};
									if (reached) {
										style.backgroundColor = "#00b5b033";
										style.fontWeight = 600;
										style.color = "#00b5b0";
									}

									return (
										<tr key={index} style={style}>
											<td>{entry.tpv}</td>
											<td>{entry.type === "money" ? formatPrice(entry.bonus) : entry.prize}</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</Col>
				</Row>
			</ContentWrapper>
		);
	}

	renderProgressBarTrip(bonus: any) {
		const { partnerDetail } = this.state;

		const steps = [];
		for (const incentive of bonus.bonuses) {
			steps.push({
				value: incentive.annual,
				element: <small>{incentive.prize}</small>,
				topElement: <small>{incentive.annual}</small>
			});
		}

		steps.sort((a, b) => a.value - b.value);

		if (!steps.length) return null;

		return <ProgressBarSteps min={0} steps={steps} value={partnerDetail?.partner?.points?.accumulatedTpv || 0} />;
	}

	renderTrip() {
		const { settings, partnerDetail } = this.state;
		const { bonuses } = settings || {};
		const groupTrip = bonuses?.groupTrip;

		if (!groupTrip) return null;

		return (
			<ContentWrapper>
				<div className="ScreenHeader">
					<div className="ScreenHeaderLeft">
						<Icon name="points" />
						<h2>{strings.commissions.progressTrip}</h2>
					</div>
				</div>
				<Row gutter={[20, 20]}>
					<Col xs={24}>{this.renderProgressBarTrip(groupTrip)}</Col>
					<Col xs={24} md={6}>
						<div className="IconCardOuterContainer">
							<div className="IconCard">
								<div className="IconCardHeader">
									<div className="IconCardHeaderMask" />
									<div className="IconCardType">
										<Icon name="points" />
									</div>
								</div>
								<div className="IconCardBody">
									<span className="IconCardValue">{partnerDetail?.partner?.points?.accumulatedTpv || 0}</span>
									<h3 className="IconCardDescription">{strings.commissions.accumulatedTpv}</h3>
								</div>
							</div>
						</div>
					</Col>
					<Col xs={24} md={8}>
						<table className="CommissionTpvTable">
							<thead>
								<tr>
									<th>{strings.commissions.accumulatedTpv}</th>
									<th>{strings.generic.bonus}</th>
								</tr>
							</thead>
							<tbody>
								{groupTrip?.bonuses?.map((entry: any, index: number) => {
									const reached = partnerDetail?.partner?.points?.accumulatedTpv >= entry.annual;

									const style: React.CSSProperties = {};
									if (reached) {
										style.backgroundColor = "#00b5b033";
										style.fontWeight = 600;
										style.color = "#00b5b0";
									}

									return (
										<tr key={index} style={style}>
											<td>{entry.annual}</td>
											<td>{entry.prize}</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</Col>
				</Row>
			</ContentWrapper>
		);
	}

	render() {
		const { eligible, partnerDetail } = this.state;

		return (
			<div className="CommissionIncentives">
				<Helmet>
					<title>{strings.commissions.incentives}</title>
					<meta name="description" content="Description of incentives" />
				</Helmet>
				<div className="IconCards">
					<Row gutter={[20, 20]}>
						{!eligible && (
							<Col xs={24}>
								<ContentWrapper>
									<div className="ScreenHeader">
										<div className="ScreenHeaderLeft">
											<Icon name="user" />
											<h2>{strings.commissions.eligibility}</h2>
										</div>
									</div>
									<p>
										<strong>
											{strings.commissions.actualRank}: {getSuccessLevelBadge(partnerDetail?.partner?.successLevel)}
										</strong>
									</p>
									<p className="CommissionsPartnersAttention">{strings.commissions.notEligible}</p>
								</ContentWrapper>
							</Col>
						)}
						<Col xs={24}>{this.renderIncentive()}</Col>
						<Col xs={24}>{this.renderTrip()}</Col>
						<Col xs={24}>{this.renderPartnerMovements()}</Col>
					</Row>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language,
	countries: state.countries
});

export default connect(mapStateToProps)(Incentives);
