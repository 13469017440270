import React, { Component } from "react";
import { Table, notification, TableColumnsType } from "antd";
import { ContentWrapper, Icon } from "components";
import { DateTime } from "luxon";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { setLoader, setTitle, updateCrumb } from "store/actions";
import { API, Endpoints } from "utils/api";
import strings from "utils/strings";
import { formatPrice } from "utils/utils";

class Adjustments extends Component<any, any> {
	searchTimeout: NodeJS.Timeout | undefined;

	constructor(props: any) {
		super(props);

		this.state = {
			movements: []
		};
	}

	async componentDidMount(): Promise<void> {
		const { dispatch } = this.props;

		dispatch(setTitle(""));

		await this.getData();
	}

	componentDidUpdate(): void {
		const { dispatch } = this.props;
		dispatch(updateCrumb());
	}

	async getData() {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.post({
				url: Endpoints.uriCommissions("search-by-type"),
				data: { type: "adjustments" }
			});

			if (response.ok) {
				const { movements = [] } = response.data.results;
				this.setState({ movements });
			} else {
				notification.error({
					message: strings.commissions.adjuncts,
					description: response?.data?.message || strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: strings.serverErrors.title,
				description: err as string,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	renderMovements() {
		const { movements = [] } = this.state;

		const columns: TableColumnsType<any> = [
			{
				title: strings.fields.date,
				dataIndex: "date",
				key: "date",
				render: (date: string) => DateTime.fromISO(date).toFormat("dd/MM/yyyy HH:mm"),
				defaultSortOrder: "descend",
				sorter: (a, b) => DateTime.fromISO(a.date).toMillis() - DateTime.fromISO(b.date).toMillis(),
				width: 150
			},
			{
				title: strings.products.notes,
				dataIndex: "notes",
				key: "notes",
				render: (notes: string) => notes || "-",
				sorter: (a, b) => a.notes?.localeCompare(b.notes)
			},
			{
				title: strings.settings.value,
				dataIndex: "balance",
				key: "balance",
				render: (balance: number) => <span className="CommissionValueRed">{formatPrice(balance)}</span>,
				sorter: (a, b) => a.balance - b.balance,
				width: 150
			}
		];

		return (
			<ContentWrapper>
				<div className="ScreenHeader">
					<div className="ScreenHeaderLeft">
						<Icon name="diagram" />
						<h2>{strings.commissions.adjuncts}</h2>
					</div>
				</div>
				<Table style={{ marginTop: 20 }} columns={columns} dataSource={movements} rowKey={(record) => record._id} />
			</ContentWrapper>
		);
	}

	render() {
		return (
			<div className="CommissionAdjuncts">
				<Helmet>
					<title>{strings.commissions.adjuncts}</title>
					<meta name="description" content="Description of adjuncts" />
				</Helmet>
				{this.renderMovements()}
			</div>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language,
	countries: state.countries
});

export default connect(mapStateToProps)(Adjustments);
