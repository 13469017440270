import React from "react";
import { compose, withProps, lifecycle } from "recompose";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";

const MapWithAMarkers = compose(
    withProps({
        googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyBfE6Xhz4-MtPOq6mHXHjxGsiG74cqIwcw",
        loadingElement: <div style={{ minHeight: 400, height: '100%', width: '100%' }} />,
        containerElement: <div style={{ minHeight: 400, height: '100%', width: '100%' }} />,
        mapElement: <div style={{ minHeight: 400, height: '100%', width: '100%' }} />,
    }),
    lifecycle({
        componentDidMount() {
            this.setState({
                zoomToMarkers: map => {
                    if (!map) return;
                    const bounds = new window.google.maps.LatLngBounds();
                    map.props.children.forEach((child) => {
                        if (child.type === Marker || child[0].type === Marker) {
                            const newChild = child[0] || child;
                            bounds.extend(new window.google.maps.LatLng(Number(newChild.props.position.lat), Number(newChild.props.position.lng)));
                        }
                    })
                    map.fitBounds(bounds);
                }
            })
        },
    }),
    withScriptjs,
    withGoogleMap
)(props =>
    <GoogleMap
        ref={props.zoomToMarkers}
        defaultZoom={5}
        defaultCenter={{ lat: 39.536297, lng: -8.2 }}
        options={{ maxZoom: 17, gestureHandling: "cooperative" }}
        onClick={e => {
            props.onClick({ coordinates: [Number.parseFloat(e.latLng.lng()), Number.parseFloat(e.latLng.lat())] });
        }}>
        {props.markers.map(marker => {
            return (
                <Marker
                    key={`marker_${marker._id}`}
                    onClick={() => props.onClick(marker)}
                    position={{
                        lat: marker.coordinates?.[1],
                        lng: marker.coordinates?.[0],
                    }}
                />
            );
        })}
    </GoogleMap>
);

class MapComponent extends React.Component {
    UNSAFE_componentWillMount() {
        this.setState({ markers: this.props.markers });
    }

    shouldComponentUpdate(nextProps) {
        const { markers } = this.state;

        if (JSON.stringify(markers) !== JSON.stringify(nextProps.markers)) {
            this.setState({ markers: nextProps.markers });
            return true;
        }

        return false;
    }

    render() {
        return (
            <MapWithAMarkers onClick={(marker) => this.props.onClick(marker)} markers={this.state.markers} />
        )
    }
}

export default MapComponent;