/**
 *
 * Sidebar
 *
 */

import React from "react";
import type { LanguageSchema } from "utils/types";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { SidebarMenu, UserMenu } from "components";
import { API, Endpoints } from "utils/api";
import { setLogout } from "store/actions";
import { localType } from "screens/App/routes";
import { adminSubDomains } from "screens/App/routes";
import { distributorSubDomains } from "screens/App/routes";
import Strings from "utils/strings";
import logo from "assets/images/logomark.png";
import logoWhite from "assets/images/logo_horizontal_white.png";
import { FileUnknownOutlined } from "@ant-design/icons";
import "./styles.scss";

export class Sidebar extends React.PureComponent<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			activeTab: "/dashboard"
		};

		this.selectTab = this.selectTab.bind(this);
		this.goTo = this.goTo.bind(this);
		this.goToHomepage = this.goToHomepage.bind(this);
	}

	selectTab(url: String) {
		const { activeTab } = this.state;

		this.setState({ activeTab: activeTab === url ? "" : url });
	}

	goTo(url: String) {
		const clientWidth = document.body.clientWidth;
		this.setState({ activeTab: url });
		this.props.onMobile(clientWidth < 992);
		this.props.dispatch(push(`/${url}`));
	}

	goToHomepage() {
		this.setState({ activeTab: "/dashboard" });
		this.props.dispatch(push("/"));
	}

	logout() {
		const { dispatch } = this.props;

		try {
			API.post({ url: Endpoints.uriLogout() });
		} catch (err: unknown) {
			console.log(err as string);
		}

		localStorage.removeItem("token");
		dispatch(setLogout());
	}

	renderDistributorMenus() {
		const { activeTab } = this.state;
		const { open, user } = this.props;
		const hostname = window.location.hostname?.split(".")?.[0];
		const isDistributors =
			((hostname === "localhost" || window.location.hostname === "192.168.1.36") && localType === "distributor") ||
			distributorSubDomains.includes(hostname);

		if (!isDistributors) return null;

		if (!user.partner?.confirmed && !user.partner?.pendingRegistration) {
			return (
				<SidebarMenu
					name={Strings.sidebar.dashboard}
					url="partner-kits"
					icon="speedometer"
					activeTab={activeTab}
					onClick={this.goTo}
					sidebarIsOpen={open}
				/>
			);
		}

		if (user.partner?.pendingRegistration) {
			return (
				<SidebarMenu
					name={Strings.sidebar.dashboard}
					url="partner-pending-registration"
					icon="speedometer"
					activeTab={activeTab}
					onClick={this.goTo}
					sidebarIsOpen={open}
				/>
			);
		}

		const MENUS = [
			{
				name: Strings.sidebar.dashboard,
				url: "partner-dashboard",
				icon: "speedometer"
			},
			{
				name: Strings.sidebar.wallet,
				url: "partner-wallet",
				icon: "wallet"
			},
			{
				name: Strings.sidebar.commissionsAndBonuses,
				url: "partner-commissions",
				icon: "organization",
				subMenus: [
					{
						name: Strings.commissions.sales,
						url: "partner-commissions/sales"
					},
					{
						name: Strings.commissions.newPartners,
						url: "partner-commissions/partners"
					},
					{
						name: Strings.commissions.repass,
						url: "partner-commissions/repass"
					},
					{
						name: Strings.commissions.annual,
						url: "partner-commissions/annual"
					},
					{
						name: Strings.commissions.binary,
						url: "partner-commissions/binary"
					},
					{
						name: Strings.commissions.depth,
						url: "partner-commissions/depth"
					},
					{
						name: Strings.commissions.diamond,
						url: "partner-commissions/diamond",
						disabled: true
					},
					{
						name: Strings.commissions.special,
						url: "partner-commissions/special"
					},
					{
						name: Strings.commissions.incentives,
						url: "partner-commissions/incentives"
					},
					{
						name: Strings.commissions.adjuncts,
						url: "partner-commissions/adjuncts"
					}
				]
			},
			{
				name: Strings.sidebar.orders,
				url: "partner-orders",
				icon: "box"
			},
			{
				name: Strings.sidebar.users,
				url: "partner-clients",
				icon: "users"
			},
			{
				name: Strings.sidebar.partners,
				url: "partner-partners",
				icon: "user"
			},
			{
				name: Strings.sidebar.crm,
				url: "crm",
				icon: "support-tickets"
			},
			{
				name: Strings.sidebar.partnerTrees,
				url: "trees",
				icon: "diagram",
				subMenus: [
					{
						name: Strings.sidebar.geoTree,
						url: "partner-genealogical-tree"
					},
					{
						name: Strings.sidebar.referralTree,
						url: "partner-sponsor-tree"
					}
				]
			},
			{
				name: Strings.settings.manuals,
				url: "partner-manuals",
				icon: "duplicate"
			},
			{
				name: Strings.settings.ethics,
				url: "partner-ethics",
				icon: "duplicate"
			},
			{
				name: Strings.sidebar.help,
				url: "partner-faqs",
				icon: <FileUnknownOutlined translate={null} />,
				iconType: "antd",
				subMenus: [
					{
						name: Strings.sidebar.faqs,
						url: "partner-faqs"
					},
					{
						name: Strings.settings.files,
						url: "partner-files"
					}
				]
			}
		];

		return MENUS.map((menu) => <SidebarMenu key={menu.url} {...menu} activeTab={activeTab} onClick={this.goTo} sidebarIsOpen={open} />);
	}

	renderAdminMenus() {
		const { activeTab } = this.state;
		const { open, user } = this.props;
		const isAdmin = (Boolean(user) && (user.role === "admin" || user.role === "sysadmin")) || false;
		const hostname = window.location.hostname?.split(".")?.[0];
		const isAdmins =
			((hostname === "localhost" || window.location.hostname === "192.168.1.36") && localType === "admin") ||
			adminSubDomains.includes(hostname);

		if (!isAdmins) return null;

		const MENUS = [
			{
				name: Strings.sidebar.dashboard,
				url: "dashboard",
				icon: "speedometer"
			},
			{
				name: Strings.products.catalog,
				url: "products",
				icon: "box",
				subMenus: [
					{
						name: Strings.products.title,
						url: "products"
					},
					{
						name: Strings.products.categories,
						url: "categories"
					},
					{
						name: Strings.products.segments,
						url: "segments"
					}
				]
			},
			{
				name: Strings.sidebar.marketing,
				url: "marketing",
				icon: "bell",
				subMenus: [
					{
						name: Strings.sidebar.banners,
						url: "banners"
					},
					{
						name: Strings.sidebar.highlightedSections,
						url: "highlighted-sections"
					}
				]
			},
			{
				name: Strings.sidebar.commissionsAndBonuses,
				url: "commissions",
				icon: "organization",
				subMenus: [
					{
						name: Strings.commissions.sales,
						url: "commissions/sales"
					},
					{
						name: Strings.commissions.newPartners,
						url: "commissions/partners"
					},
					{
						name: Strings.commissions.repass,
						url: "commissions/repass"
					},
					{
						name: Strings.commissions.annual,
						url: "commissions/annual"
					},
					{
						name: Strings.commissions.binary,
						url: "commissions/binary"
					},
					{
						name: Strings.commissions.depth,
						url: "commissions/depth"
					},
					{
						name: Strings.commissions.diamond,
						url: "commissions/diamond",
						disabled: true
					},
					{
						name: Strings.commissions.special,
						url: "commissions/special"
					},
					{
						name: Strings.commissions.incentives,
						url: "commissions/incentives"
					},
					{
						name: Strings.commissions.adjuncts,
						url: "commissions/adjuncts"
					}
				]
			},
			{
				name: Strings.wallet.wallets,
				url: "wallets",
				icon: "wallet"
			},
			{
				name: Strings.sidebar.orders,
				url: "orders",
				icon: "box"
			},
			{
				name: Strings.sidebar.users,
				url: "clients",
				icon: "users"
			},
			{
				name: Strings.sidebar.partners,
				url: "partners",
				icon: "partner",
				subMenus: [
					{
						name: Strings.fields.list,
						url: "partners"
					},
					{
						name: Strings.sidebar.crm,
						url: "crm"
					},
					{
						name: Strings.sidebar.geoTree,
						url: "genealogical-tree"
					},
					{
						name: Strings.sidebar.referralTree,
						url: "sponsor-tree"
					}
				]
			},
			{
				adminOnly: true,
				name: Strings.sidebar.staff,
				url: "staff",
				icon: "working-briefcase",
				subMenus: [
					{
						name: Strings.fields.list,
						url: "staff/list"
					},
					{
						name: Strings.sidebar.logs,
						url: "staff/logs"
					}
				]
			},
			{
				adminOnly: true,
				name: Strings.sidebar.settings,
				url: "settings",
				icon: "preferences"
			},
			{
				sysAdminOnly: true,
				name: Strings.sidebar.logs,
				url: "logs",
				icon: "duplicate"
			}
		];

		return MENUS.map((menu) => {
			const { adminOnly, sysAdminOnly } = menu;

			if (adminOnly && !isAdmin) return null;
			if (sysAdminOnly && user.role !== "sysadmin") return null;

			return <SidebarMenu key={menu.url} {...menu} activeTab={activeTab} onClick={this.goTo} sidebarIsOpen={open} />;
		});
	}

	render() {
		const { open, user } = this.props;

		if (!user) return null;

		return (
			<div className="SidebarWrapper">
				<div className={`SidebarContainer${open ? " open" : " closed"}`}>
					<div className="SidebarHome">
						<a className="SidebarLogoContainer" onClick={this.goToHomepage} href="/">
							<img className="Logo" alt="Logo" src={open ? logoWhite : logo} style={open ? { height: 55 } : { height: 30 }} />
						</a>
						<div className="SidebarSeparator" />
					</div>
					<div className="SidebarContent">
						<UserMenu
							sidebarIsOpen={open}
							subMenus={[
								{
									name: Strings.header.account,
									icon: "user"
								},
								{
									name: Strings.authentication.logout,
									action: "logout",
									icon: "logout"
								}
							]}
						/>
						<div style={{ margin: "10px 0" }} className="SidebarSeparator" />
						{this.renderDistributorMenus()}
						{this.renderAdminMenus()}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state: { settings: object; user: object; language: keyof LanguageSchema }) => ({
	settings: state.settings,
	user: state.user,
	language: state.language
});

export default connect(mapStateToProps)(Sidebar);
