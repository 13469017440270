import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { setTitle, setBreadcrumb, setLoader } from "store/actions";
import { Icon, Table } from "components";
import { Props, State } from "./types";
import { API, Endpoints } from "utils/api";
import { Col, Drawer, Row, notification, DatePicker, Select } from "antd";
import moment from "moment";
import { translate } from "utils/utils";
import strings from "utils/strings";
import { DateTime } from "luxon";
import pt from "antd/lib/date-picker/locale/pt_PT";
import en from "antd/lib/date-picker/locale/en_US";
import { push } from "connected-react-router";

const { RangePicker } = DatePicker;

const LOGTYPES = ["staff", "user", "order", "product"];

class StaffLogs extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		if (this.props.language === "pt") {
			moment.updateLocale("pt", {
				week: {
					dow: 1 /// Date offset
				}
			});
		} else {
			moment.updateLocale("en", {
				week: {
					dow: 1 /// Date offset
				}
			});
		}

		this.state = {
			staffLogs: [],

			page: 0,
			pageSize: 100,
			total: 0,
			columnSearch: null,
			globalSearch: "",
			filters: {
				startDate: moment().startOf("month"),
				endDate: moment().endOf("month")
			},
			tempFilters: {
				startDate: moment().startOf("month"),
				endDate: moment().endOf("month")
			},
			showDrawer: false
		};
	}

	componentDidMount() {
		const { dispatch } = this.props;

		dispatch(setTitle(strings.sidebar.staffLogs));
		dispatch(setBreadcrumb(null));

		this.getData();
	}

	async getData(pause?: boolean) {
		const { page, pageSize, columnSearch, globalSearch, filters } = this.state;
		const { dispatch } = this.props;

		setTimeout(
			async () => {
				if (columnSearch !== this.state.columnSearch || globalSearch !== this.state.globalSearch) return;
				dispatch(setLoader(true));

				const body = {
					filters: columnSearch
						? Object.keys(columnSearch).map((elem) =>
								columnSearch[elem] !== ""
									? {
											field: elem,
											query: columnSearch[elem]
									  }
									: {}
						  )
						: [],
					page: page,
					perPage: pageSize,
					search: globalSearch,
					startDate: filters?.startDate.toISOString(),
					endDate: filters?.endDate.toISOString()
				};

				try {
					const response = await API.post({
						url: Endpoints.uriSearchStaffLogs("search"),
						data: body
					});

					if (response.ok) {
						const { logs = [], total = 0 } = response.data.results;
						this.setState({ staffLogs: logs, total });
					} else {
						notification.error({
							message: strings.sidebar.commissions,
							description: response?.data?.message || strings.serverErrors.wentWrong,
							placement: "bottomRight",
							duration: 5
						});
					}
				} catch (err) {
					notification.error({
						message: strings.serverErrors.title,
						description: err as string,
						placement: "bottomRight",
						duration: 5
					});
				}

				dispatch(setLoader(false));
			},
			pause ? 1000 : 0
		);
	}

	getType(type: string) {
		switch (type) {
			case "staff":
				return strings.staffLogs.logTypes.staff;

			case "user":
				return strings.staffLogs.logTypes.user;

			case "order":
				return strings.staffLogs.logTypes.order;

			case "product":
				return strings.staffLogs.logTypes.product;

			default:
				return type;
		}
	}

	applyFilters() {
		const { tempFilters } = this.state;

		this.setState(
			(prevState: any) => ({
				filters: { ...prevState.filters, startDate: tempFilters.startDate, endDate: tempFilters.endDate },
				showDrawer: false
			}),
			() => this.getData()
		);
	}

	get pagination() {
		return {
			total: this.state.total,
			pageIndex: this.state.page,
			pageSize: this.state.pageSize,
			setPage: (page: number, size: number) => {
				this.setState({ page, pageSize: size }, () => this.getData());
			}
		};
	}

	get filtration() {
		const { globalSearch, columnSearch } = this.state;
		return {
			showGlobalSearch: true,
			showColumnSearch: true,
			defaultValues: { globalSearch, columnSearch },
			onGlobalSearch: (globalSearch: string) => {
				this.setState({ globalSearch, page: 0 }, () => this.getData());
			},
			onColumnSearch: (columnSearch: string) => {
				this.setState({ columnSearch, page: 0 }, () => this.getData());
			}
		};
	}

	renderDrawerContent() {
		const { tempFilters } = this.state;
		const { language } = this.props;

		return (
			<Row gutter={[20, 20]}>
				<Col xs={24}>
					<label className="InputLabel" htmlFor="period">
						{strings.dashboard.period}
					</label>
					<RangePicker
						id="period"
						style={{ width: "100%" }}
						locale={language === "pt" ? pt : en}
						allowEmpty={[false, false]}
						ranges={{
							[strings.ranges.today]: [moment(), moment()],
							[strings.ranges.thisMonth]: [moment().startOf("month"), moment().endOf("month")],
							[strings.ranges.lastMonth]: [
								moment().subtract(1, "month").startOf("month"),
								moment().subtract(1, "month").endOf("month")
							],
							[strings.ranges.thisYear]: [moment().startOf("year"), moment().endOf("month")],
							[strings.ranges.lastYear]: [
								moment().subtract(1, "year").startOf("year"),
								moment().subtract(1, "year").endOf("year")
							]
						}}
						placeholder={[strings.fields.startDate, strings.fields.endDate]}
						separator={
							<span className="__turn90">
								<Icon name="arrow-up" />
							</span>
						}
						value={[tempFilters?.startDate, tempFilters?.endDate]}
						onChange={(value: any) =>
							this.setState({ tempFilters: { ...tempFilters, startDate: value[0], endDate: value[1] } })
						}
						allowClear={false}
					/>
				</Col>
			</Row>
		);
	}

	renderDrawer() {
		const { showDrawer } = this.state;
		const { mobile } = this.props;

		return (
			<Drawer
				title={
					<div className="SidebarTitleContainer">
						<Icon name="organization" />
						<p>{strings.fields.selectFilters}</p>
					</div>
				}
				footer={
					<div className="SidebarFooterContainer">
						<button type="button" className="SidebarFooterButton --button-confirm" onClick={() => this.applyFilters()}>
							{strings.generic.confirm}
						</button>
						<button
							type="button"
							className="SidebarFooterButton --button-cancel"
							onClick={() => this.setState({ showDrawer: false, tempFilters: {} })}
						>
							{strings.generic.cancel}
						</button>
					</div>
				}
				placement="right"
				closable={false}
				onClose={() => this.setState({ showDrawer: false, tempFilters: {} })}
				visible={showDrawer}
				width={mobile ? "100%" : 600}
			>
				{this.renderDrawerContent()}
			</Drawer>
		);
	}

	render() {
		const { staffLogs, filters, columnSearch } = this.state;
		const { dispatch } = this.props;

		return (
			<React.Fragment>
				<Helmet>
					<title>{strings.sidebar.staffLogs}</title>
					<meta name="description" content="Description of Staff Logs" />
				</Helmet>

				<Table
					title={{
						icon: "working-briefcase",
						title: strings.sidebar.staffLogs
					}}
					data={staffLogs}
					headerOptions={[
						{
							icon: "filter",
							label: strings.generic.filters,
							onClick: () =>
								this.setState({
									showDrawer: true,
									tempFilters: {
										startDate: filters?.startDate,
										endDate: filters?.endDate
									}
								})
						}
					]}
					columns={[
						{
							Header: strings.fields.date,
							id: "date",
							accessor: (row: any) => row.date && DateTime.fromISO(row.date).toLocaleString(DateTime.DATE_SHORT),
							maxWidth: 150,
							Filter: () => null
						},
						{
							Header: strings.staffLogs.logTypes.staff,
							id: "staff",
							accessor: (row: any) => row.staff.name || "-"
						},
						{
							Header: strings.settings.type,
							id: "changeType",
							accessor: (row: any) => this.getType(row.changeType) || "-",
							Filter: () => {
								return (
									<Select
										onChange={(value: any) => {
											const filters = columnSearch || {};
											const exists: any = Object.keys(filters)?.find((elem: any) => elem.field === "changeType");

											if (value) {
												if (exists) {
													exists.query = value;
												} else {
													filters["changeType"] = value;
												}
											} else {
												delete filters["changeType"];
											}

											this.setState({ columnSearch: filters }, () => this.getData(false));
										}}
										style={{ width: "100%" }}
										placeholder={strings.staffLogs.changeType}
										allowClear
									>
										{LOGTYPES.map((elem) => (
											<Select.Option key={elem} value={elem}>
												{this.getType(elem)}
											</Select.Option>
										))}
									</Select>
								);
							}
						},
						{
							Header: strings.fields.recipient,
							id: "entity",
							accessor: (row: any) => {
								let returnedValue = "-";

								switch (row.changeType) {
									case "staff":
										returnedValue = row.user?.name || "-";
										break;

									case "user":
										returnedValue = row.user?.name || "-";
										break;

									case "order":
										returnedValue = row.order?.orderNumber ? "#" + row.order?.orderNumber : "-";
										break;

									case "product":
										returnedValue = translate(row.product?.name) || "-";
										break;
								}

								return returnedValue;
							}
						}
					]}
					actions={{
						view: (obj: any) => {
							let location;
							if (obj.changeType === "product") location = `/products/${obj.product?._id}`;
							else if (obj.changeType === "order") location = `/orders/${obj.order?._id}`;
							else if (obj.changeType === "user") location = `/partners/${obj.user?._id}`;

							return {
								onClick: () => {
									if (obj.changeType === "order") dispatch(push(`/orders/${obj.order?._id}`));
									else if (obj.changeType === "product") dispatch(push(`/products/${obj.product?._id}`));
									else if (obj.changeType === "user") dispatch(push(`/partners/${obj.user?._id}`));
								},
								location,
								disabled: obj.changeType === "staff"
							};
						}
					}}
					fullPage
					isSinglePage
					filterable
					paginated
					paginationApi={this.pagination}
					filtrationApi={this.filtration}
				/>
				{this.renderDrawer()}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language
});

export default connect(mapStateToProps)(StaffLogs);
