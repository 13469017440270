import React from "react";
import { connect } from "react-redux";
import { setLoader, setTitle } from "store/actions";
import { API, Endpoints } from "utils/api";
import { notification } from "antd";
import { translate } from "utils/utils";
import { Helmet } from "react-helmet";
import { Table } from "components";
import { push } from "connected-react-router";
import Strings from "utils/strings";

class Categories extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			categories: [],
		};
	}

	componentDidMount() {
		const { dispatch } = this.props;
		dispatch(setTitle(""));
		this.getData();
	}

	async getData() {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.get({
				url: Endpoints.uriCategories(),
			});

			if (response?.ok) {
				const { categories = [] } = response.data.results || {};
				this.setState({ categories });
			} else {
				notification.error({
					message: Strings.products.categories,
					description:
						response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5,
				});
			}
		} catch (err: unknown) {
			console.log(err as string);
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			});
		}

		dispatch(setLoader(false));
	}

	async toggleCategory(category: any) {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.patch({
				url: Endpoints.uriCategories(`${category._id}/status`),
				data: { isActive: !category.isActive },
			});

			if (response?.ok) {
				const { categories = [] } = response.data.results || {};
				this.setState({ categories });

				notification.success({
					message: Strings.products.categories,
					description:
						response?.data?.message || Strings.products.categoryUpdated,
					placement: "bottomRight",
					duration: 5,
				});
			} else {
				notification.error({
					message: Strings.products.categories,
					description:
						response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5,
				});
			}
		} catch (err: unknown) {
			console.log(err as string);
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			});
		}

		dispatch(setLoader(false));
	}

	onDrag = async () => {
		const { categories, hoverIndex } = this.state;
		const { dispatch } = this.props;

		const item = categories[hoverIndex];
		const itemId = item?._id;

		if (!itemId || item.pos === hoverIndex) return;

		dispatch(setLoader(true));

		try {
			const response = await API.patch({
				url: Endpoints.uriCategories(`${itemId}/position`),
				data: { pos: hoverIndex },
			});

			if (response.ok) {
				await this.getData();

				notification.success({
					message: Strings.products.categories,
					description:
						response?.data?.message || Strings.products.categoryUpdated,
					placement: "bottomRight",
					duration: 5,
				});
			} else {
				notification.error({
					message: Strings.products.categories,
					description: response.data?.message,
					placement: "bottomRight",
					duration: 5,
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			});
		}

		dispatch(setLoader(false));
	};

	async deleteCategory(id: string) {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.delete({
				url: Endpoints.uriCategories(id),
			});

			if (response?.ok) {
				const { categories = [] } = response.data.results || {};
				this.setState({ categories });

				notification.success({
					message: Strings.products.categories,
					description:
						response?.data?.message || Strings.products.categoryDeleted,
					placement: "bottomRight",
					duration: 5,
				});
			} else {
				notification.error({
					message: Strings.products.categories,
					description:
						response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5,
				});
			}
		} catch (err: unknown) {
			console.log(err as string);
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			});
		}

		dispatch(setLoader(false));
	}

	render() {
		const { categories = [] } = this.state;
		const { dispatch } = this.props;

		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.products.categories}</title>
					<meta
						name="description"
						content="Create, edit or delete categories"
					/>
				</Helmet>
				<Table
					title={{
						icon: "package",
						title: Strings.products.categories,
					}}
					data={categories}
					columns={[
						{
							Header: Strings.fields.image,
							id: "image",
							accessor: (row: any) => row.image,
							type: "image",
							maxWidth: 65,
						},
						{
							Header: Strings.fields.name,
							id: "name",
							accessor: (row: any) => translate(row.name) || "-",
						},
					]}
					filterable
					fullPage
					isSinglePage
					paginated={false}
					add={{
						tooltip: Strings.products.addCategory,
						onClick: () => dispatch(push("/categories/new")),
					}}
					actions={{
						edit: (obj: any) => ({
							onClick: () => dispatch(push(`/categories/${obj._id}`)),
							location: `/categories/${obj._id}`,
						}),
						toggle: (obj: any) => ({
							value: obj.isActive,
							onChange: () => this.toggleCategory(obj),
						}),
						remove: (obj: any) => ({
							onClick: () => this.deleteCategory(obj._id),
						}),
					}}
					draggable
					onDrag={async (list: any, dragIndex: any, hoverIndex: any) => {
						this.setState({ categories: list, dragIndex, hoverIndex });
					}}
					onDragEnd={this.onDrag}
				/>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language,
});

export default connect(mapStateToProps)(Categories);
