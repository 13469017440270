import React from "react";
import { push } from "connected-react-router";
import type { Props, State } from "./types";
import { connect } from "react-redux";
import { delayedDispatch, setBreadcrumb, setLoader, setTitle, updateCrumb } from "store/actions";
import { Helmet } from "react-helmet";
import { ContentWrapper, File as FileComponent } from "components";
import { Tabs, notification } from "antd";
import { FileAddOutlined, FileUnknownOutlined } from "@ant-design/icons";
import { API, Endpoints } from "utils/api";
import Strings from "utils/strings";
import "./styles.scss";

class HelpFiles extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			files: [],
			selectedTab: "product"
		};
	}

	componentDidMount(): void {
		const { dispatch } = this.props;

		dispatch(setTitle(""));

		this.breadcrumb();
		this.getData();
	}

	componentDidUpdate(): void {
		const { dispatch } = this.props;
		dispatch(updateCrumb());
	}

	async getData() {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.get({
				url: Endpoints.uriFiles()
			});

			if (response.ok) {
				const { files = [] } = response.data.results || {};
				this.setState({ files });
			} else {
				notification.error({
					message: Strings.settings.files,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err: unknown) {
			notification.error({
				message: Strings.serverErrors.title,
				description: err as string,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async deleteFile(id: string) {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.delete({
				url: Endpoints.uriFiles(id)
			});

			if (response.ok) {
				await this.getData();

				notification.success({
					message: Strings.settings.files,
					description: response?.data?.message || Strings.settings.fileDeleted,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.settings.files,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err: unknown) {
			notification.error({
				message: Strings.serverErrors.title,
				description: err as string,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	breadcrumb() {
		delayedDispatch(
			setBreadcrumb(() => {
				return {
					locations: [
						{
							text: Strings.sidebar.settings,
							route: "/settings",
							icon: "preferences"
						},
						{
							text: Strings.settings.files,
							icon: <FileUnknownOutlined translate={null} />,
							iconType: "antd"
						}
					]
				};
			})
		);
	}

	getString(topic: string) {
		switch (topic) {
			case "product":
				return Strings.settings.topicProduct;
			case "business":
				return Strings.settings.topicBusiness;
			case "other":
				return Strings.settings.other;
			default:
				return topic;
		}
	}

	renderGallery() {
		const { files: apiFiles = [], selectedTab } = this.state;
		const { dispatch } = this.props;

		return (
			<ContentWrapper>
				<Tabs
					defaultActiveKey="1"
					activeKey={selectedTab}
					onChange={(key: string) => this.setState({ selectedTab: key })}
					tabBarExtraContent={
						<button className="TabHeaderButton" onClick={() => dispatch(push("/settings/files/new"))}>
							<FileAddOutlined translate={null} />
							<p>{Strings.settings.addFile}</p>
						</button>
					}
				>
					<Tabs.TabPane tab={Strings.settings.topicProduct} key="product" />
					<Tabs.TabPane tab={Strings.settings.topicBusiness} key="business" />
					<Tabs.TabPane tab={Strings.settings.other} key="other" />
				</Tabs>
				{apiFiles
					.filter((arr) => arr?._id === selectedTab)
					.map((filesArr) => {
						const { files = [] } = filesArr!;

						if (files.length === 0) {
							return (
								<div className="FileLevelHeader">
									<h3>{Strings.settings.noFiles}</h3>
								</div>
							);
						}

						return (
							<div className="FileGrid">
								{files.map((file) => {
									return <FileComponent key={file._id} onDelete={() => this.deleteFile(file._id!)} {...file} />;
								})}
							</div>
						);
					})}
			</ContentWrapper>
		);
	}

	render() {
		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.settings.files}</title>
					<meta name="description" content="Set the level of partner in order for your partners to get files" />
				</Helmet>
				{this.renderGallery()}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: { language: string }) => ({
	language: state.language
});

export default connect(mapStateToProps)(HelpFiles);
