import React, { Component } from "react";
import { Col, Table, Row, notification } from "antd";
import { ContentWrapper, Icon, ProgressBarSteps } from "components";
import { DateTime } from "luxon";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { delayedDispatch, setBreadcrumb, setLoader, setTitle, updateCrumb } from "store/actions";
import { API, Endpoints } from "utils/api";
import strings from "utils/strings";
import { capitalize, formatPrice, getSuccessLevelBadge, xlsxSettings } from "utils/utils";
import moment from "moment";
import xlsx from "json-as-xlsx";

class Incentives extends Component<any, any> {
	searchTimeout: NodeJS.Timeout | undefined;

	constructor(props: any) {
		super(props);

		this.state = {
			data: [],
			startDate: moment().add({ months: -5 }).startOf("month"),
			endDate: moment().endOf("month"),
			partner: undefined,
			partnerOptions: [],
			partnerSearch: ""
		};
	}

	async componentDidMount(): Promise<void> {
		const { dispatch } = this.props;

		dispatch(setTitle(""));

		await this.getData();
		this.breadcrumb();
	}

	componentDidUpdate(): void {
		const { dispatch } = this.props;
		dispatch(updateCrumb());
	}

	async getData() {
		const { startDate, endDate, partner } = this.state;
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		const body = {
			startDate: startDate.toISOString(),
			endDate: endDate.toISOString(),
			type: "incentives",
			partner
		};

		try {
			const response = await API.post({
				url: Endpoints.uriCommissions("search-by-type"),
				data: body
			});

			if (response.ok) {
				const {
					commissions = [],
					partners = [],
					settings,
					eligible,
					partner,
					currentBonus,
					accumulatedTpv,
					movements = []
				} = response.data.results;

				this.setState({
					data: commissions,
					movements,
					partners,
					settings,
					eligible,
					partnerDetail: partner,
					currentBonus,
					accumulatedTpv
				});
			} else {
				notification.error({
					message: strings.commissions.incentives,
					description: response?.data?.message || strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: strings.serverErrors.title,
				description: err as string,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	handleSearch(partner: any) {
		this.setState({ partnerSearch: partner });

		if (partner.length < 3) return;

		if (this.searchTimeout) clearTimeout(this.searchTimeout);

		this.searchTimeout = setTimeout(async () => {
			this.getUsers();
		}, 500);
	}

	async getUsers() {
		const { partnerSearch } = this.state;

		try {
			const body = { search: partnerSearch, page: 0, perPage: 10 };

			const response = await API.post({
				url: Endpoints.uriPartners("search"),
				data: body
			});

			if (response.ok) {
				const { users = [] } = response.data.results || {};
				this.setState({ partnerOptions: users });
			} else {
				notification.error({
					message: strings.commissions.incentives,
					description: response?.data?.message || strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: strings.serverErrors.title,
				description: err as string,
				placement: "bottomRight",
				duration: 5
			});
		}
	}

	breadcrumb() {
		delayedDispatch(
			setBreadcrumb(() => {
				const { startDate, endDate, partnerOptions } = this.state;

				return {
					locations: [
						{
							text: strings.commissions.incentives,
							icon: "organization"
						}
					],
					actions: [
						{
							type: "select",
							text: strings.fields.partner,
							placeholder: strings.generic.searchByEmailOrName,
							options: partnerOptions.map((partner: any) => ({
								value: partner._id,
								text: `${partner.name} (${partner.email})`
							})),
							onChange: (partner: any) => {
								this.setState({ partner }, () => this.getData());
							},
							value: this.state.partner,
							showSearch: true,
							onSearch: (value: string) => this.handleSearch(value),
							separator: "right",
							style: { width: 300, height: 40 },
							allowClear: true
						},
						{
							type: "datePicker",
							text: strings.dashboard.period,
							dates: [startDate, endDate],
							monthly: true,
							onChange: this.handleSelect,
							className: "fixedPicker"
						}
					]
				};
			})
		);
	}

	handleSelect = (dates: any) => {
		const newDates = [];
		if (dates && dates[0]) {
			newDates.push(moment(dates[0]).startOf("day"));
		}

		if (dates && dates[1]) {
			newDates.push(moment(dates[1]).endOf("day"));
		}

		this.setState(
			{
				startDate: newDates[0],
				endDate: newDates[1]
			},
			() => this.getData()
		);
	};

	renderMovements() {
		const { partner } = this.state;

		if (partner) return this.renderPartnerMovements();
		return this.renderAllMovements();
	}

	exportAllData = () => {
		const { data: exportData = [] } = this.state;

		const columns = [
			{ label: "Data", value: (row: any) => DateTime.fromISO(row.date).toFormat("dd/MM/yyyy HH:mm") },
			{ label: "Parceiro", value: (row: any) => `${row.partner.name} (#${row.partner.userCode})` },
			{ label: "Nível de Sucesso", value: (row: any) => getSuccessLevelBadge(row.partner.successLevel) },
			{ label: "Descrição", value: "notes" },
			{ label: "Valor", value: (row: any) => formatPrice(row.value) },
			{
				label: "Data de Expiração",
				value: (row: any) => (row.expirationDate ? DateTime.fromISO(row.expirationDate).toFormat("dd/MM/yyyy HH:mm") : "-")
			}
		];

		const data = [
			{
				sheet: "Incentivos Extra",
				columns,
				content: exportData
			}
		];

		xlsx(data, xlsxSettings("Incentivos Extra"));
	};

	renderAllMovements() {
		const { data = [] } = this.state;
		const { countries } = this.props;

		return (
			<ContentWrapper>
				<div className="ScreenHeader --multi">
					<div className="ScreenHeaderLeft">
						<Icon name="diagram" />
						<h2>{strings.commissions.incentives}</h2>
					</div>
					<div className="ScreenHeaderRight">
						<button className="EthicAddButton" onClick={this.exportAllData}>
							<Icon name="print" />
						</button>
					</div>
				</div>
				<Table
					style={{ marginTop: 20 }}
					columns={[
						{
							title: strings.fields.date,
							dataIndex: "date",
							key: "date",
							render: (date: string) => DateTime.fromISO(date).toFormat("dd/MM/yyyy HH:mm"),
							defaultSortOrder: "descend",
							sorter: (a, b) => DateTime.fromISO(a.date).toMillis() - DateTime.fromISO(b.date).toMillis(),
							width: 150
						},
						{
							title: strings.fields.partner,
							dataIndex: "partner",
							key: "partner",
							render: (partner: any) => {
								if (!partner) return "-";
								const countryCallingCode = partner.phone?.substring(0, 4).replace("+", "");
								let country;

								if (countryCallingCode) {
									country = countries
										.find((elem: any) => elem.callingCodes.includes(countryCallingCode))
										?.alpha2Code?.toLowerCase();
								}

								return (
									<span className="TableRowAligned">
										{country ? (
											<div className="react-tel-input">
												<div className="selected-flag">
													<span className={`flag ${country}`} />
												</div>
											</div>
										) : null}
										{partner.name}
										{Boolean(partner?.userCode) ? ` (#${partner.userCode})` : ""}
									</span>
								);
							},
							sorter: (a, b) => a.partner.name?.localeCompare(b.partner.name)
						},
						{
							title: strings.users.partnerRanking,
							dataIndex: "actualSuccessLevel",
							key: "actualSuccessLevel",
							render: (_: string, record: any) => getSuccessLevelBadge(record?.partner?.successLevel) || "-",
							sorter: (a, b) => a.partner?.successLevel?.localeCompare(b.partner?.successLevel)
						},
						{
							title: strings.fields.description,
							dataIndex: "notes",
							key: "notes",
							render: (notes: string) => notes || "-",
							sorter: (a, b) => a.description?.localeCompare(b.description)
						},
						{
							title: strings.commissions.value,
							dataIndex: "value",
							key: "value",
							render: (value: number) => formatPrice(value),
							sorter: (a, b) => a.value - b.value
						},
						{
							title: strings.generic.expirationDate,
							dataIndex: "expirationDate",
							key: "expirationDate",
							render: (value: string) => (value ? DateTime.fromISO(value).toFormat("dd/MM/yyyy HH:mm") : "-"),
							align: "center",
							sorter: (a, b) => DateTime.fromISO(a._created).toMillis() - DateTime.fromISO(b._created).toMillis(),
							width: 150
						}
					]}
					dataSource={data}
					rowKey={(record) => record._id}
				/>
			</ContentWrapper>
		);
	}

	exportPartnerData = () => {
		const { movements = [], partnerDetail } = this.state;

		const columns = [
			{ label: "Data", value: (row: any) => DateTime.fromISO(row.date).toFormat("dd/MM/yyyy HH:mm") },
			{ label: "Descrição", value: "notes" },
			{ label: "Bónus", value: (row: any) => (row.value != null ? formatPrice(row.value) : capitalize(row.prize)) },
			{ label: "TPV Acumulado", value: "tpv" },
			{
				label: "Data de Expiração",
				value: (row: any) => (row.expirationDate ? DateTime.fromISO(row.expirationDate).toFormat("dd/MM/yyyy HH:mm") : "-")
			}
		];

		const data = [
			{
				sheet: `Incentivos Extra - ${partnerDetail.name}`,
				columns,
				content: movements
			}
		];

		xlsx(data, xlsxSettings(`Incentivos Extra - ${partnerDetail.name}`));
	};

	renderPartnerMovements() {
		const { movements = [] } = this.state;

		return (
			<ContentWrapper>
				<div className="ScreenHeader --multi">
					<div className="ScreenHeaderLeft">
						<Icon name="diagram" />
						<h2>{strings.commissions.incentives}</h2>
					</div>
					<div className="ScreenHeaderRight">
						<button className="EthicAddButton" onClick={this.exportPartnerData}>
							<Icon name="print" />
						</button>
					</div>
				</div>
				<Table
					style={{ marginTop: 20 }}
					columns={[
						{
							title: strings.fields.date,
							dataIndex: "date",
							key: "date",
							render: (date: string) => DateTime.fromISO(date).toFormat("dd/MM/yyyy HH:mm"),
							defaultSortOrder: "descend",
							sorter: (a, b) => DateTime.fromISO(a.date).toMillis() - DateTime.fromISO(b.date).toMillis(),
							width: 150
						},
						{
							title: strings.fields.description,
							dataIndex: "notes",
							key: "notes",
							render: (notes: string) => notes || "-",
							sorter: (a, b) => a.description?.localeCompare(b.description)
						},
						{
							title: strings.generic.bonus,
							dataIndex: "bonus",
							key: "bonus",
							render: (_: any, record: any) => {
								if (record.value != null) return formatPrice(record.value);
								return capitalize(record.prize);
							}
						},
						{
							title: strings.commissions.accumulatedTpv,
							dataIndex: "tpv",
							key: "tpv",
							render: (tpv: number) => tpv || 0
						},
						{
							title: strings.generic.expirationDate,
							dataIndex: "expirationDate",
							key: "expirationDate",
							render: (value: string) => (value ? DateTime.fromISO(value).toFormat("dd/MM/yyyy HH:mm") : "-"),
							align: "center",
							sorter: (a, b) => DateTime.fromISO(a.expirationDate).toMillis() - DateTime.fromISO(b.expirationDate).toMillis(),
							width: 150
						}
					]}
					dataSource={movements}
					rowKey={(record) => record._id}
				/>
			</ContentWrapper>
		);
	}

	renderPartners() {
		const { partners, partner } = this.state;
		const { countries } = this.props;

		if (partner) return null;

		return (
			<Col xs={24}>
				<ContentWrapper>
					<div className="ScreenHeader">
						<div className="ScreenHeaderLeft">
							<Icon name="diagram" />
							<h2>{strings.sidebar.partners}</h2>
						</div>
					</div>
					<Table
						style={{ marginTop: 20 }}
						columns={[
							{
								title: strings.fields.partner,
								dataIndex: "name",
								key: "name",
								render: (name: string, record: any) => {
									if (!name) return "-";
									const countryCallingCode = record.phone?.substring(0, 4).replace("+", "");
									let country;

									if (countryCallingCode) {
										country = countries
											.find((elem: any) => elem.callingCodes.includes(countryCallingCode))
											?.alpha2Code?.toLowerCase();
									}

									return (
										<span className="TableRowAligned">
											{country ? (
												<div className="react-tel-input">
													<div className="selected-flag">
														<span className={`flag ${country}`} />
													</div>
												</div>
											) : null}
											{record.name}
											{Boolean(record?.userCode) ? ` (#${record.userCode})` : ""}
										</span>
									);
								},
								sorter: (a, b) => a.name?.localeCompare(b.name)
							},
							{
								title: strings.users.personalVolumeAccumulated,
								dataIndex: "points",
								key: "points",
								render: (_: any, record: any) => record?.partner?.points?.accumulatedTpv || 0,
								sorter: (a, b) => a.partners?.points?.accumulatedTpv - b.partners?.points?.accumulatedTpv,
								width: 250
							}
						]}
						dataSource={partners}
						rowKey={(record) => record._id}
					/>
				</ContentWrapper>
			</Col>
		);
	}

	renderProgressBar() {
		const { currentBonus, accumulatedTpv } = this.state;

		const steps = [];
		for (const incentive of currentBonus.incentives) {
			steps.push({
				value: incentive.tpv,
				element: incentive.type === "money" ? <small>{formatPrice(incentive.bonus)}</small> : <small>{incentive.prize}</small>,
				topElement: <small>{incentive.tpv}</small>
			});
		}

		steps.sort((a, b) => a.value - b.value);

		if (!steps.length) return null;

		return <ProgressBarSteps min={0} steps={steps} value={accumulatedTpv || 0} />;
	}

	renderIncentive() {
		const { currentBonus, accumulatedTpv } = this.state;

		if (!currentBonus) return null;

		return (
			<ContentWrapper>
				<div className="ScreenHeader">
					<div className="ScreenHeaderLeft">
						<Icon name="points" />
						<h2>{strings.commissions.progressAdditional}</h2>
					</div>
				</div>
				<Row gutter={[20, 20]}>
					<Col xs={24}>{this.renderProgressBar()}</Col>
					<Col xs={24} md={6}>
						<div className="IconCardOuterContainer">
							<div className="IconCard">
								<div className="IconCardHeader">
									<div className="IconCardHeaderMask" />
									<div className="IconCardType">
										<Icon name="points" />
									</div>
								</div>
								<div className="IconCardBody">
									<span className="IconCardValue">{accumulatedTpv || 0}</span>
									<h3 className="IconCardDescription">{strings.commissions.accumulatedTpv}</h3>
								</div>
							</div>
						</div>
					</Col>
					<Col xs={24} md={8}>
						<table className="CommissionTpvTable">
							<thead>
								<tr>
									<th>{strings.commissions.accumulatedTpv}</th>
									<th>{strings.generic.bonus}</th>
								</tr>
							</thead>
							<tbody>
								{currentBonus?.incentives?.map((entry: any, index: number) => {
									const reached = accumulatedTpv >= entry.tpv;

									const style: React.CSSProperties = {};
									if (reached) {
										style.backgroundColor = "#00b5b033";
										style.fontWeight = 600;
										style.color = "#00b5b0";
									}

									return (
										<tr key={index} style={style}>
											<td>{entry.tpv}</td>
											<td>{entry.type === "money" ? formatPrice(entry.bonus) : entry.prize}</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</Col>
				</Row>
			</ContentWrapper>
		);
	}

	renderProgressBarTrip(bonus: any) {
		const { partnerDetail } = this.state;

		const steps = [];
		for (const incentive of bonus.bonuses) {
			steps.push({
				value: incentive.annual,
				element: <small>{incentive.prize}</small>,
				topElement: <small>{incentive.annual}</small>
			});
		}

		steps.sort((a, b) => a.value - b.value);

		if (!steps.length) return null;

		return <ProgressBarSteps min={0} steps={steps} value={partnerDetail?.partner?.points?.accumulatedTpv || 0} />;
	}

	renderTrip() {
		const { settings, partnerDetail } = this.state;
		const { bonuses } = settings || {};
		const groupTrip = bonuses?.groupTrip;

		if (!groupTrip) return null;

		return (
			<ContentWrapper>
				<div className="ScreenHeader">
					<div className="ScreenHeaderLeft">
						<Icon name="points" />
						<h2>{strings.commissions.progressTrip}</h2>
					</div>
				</div>
				<Row gutter={[20, 20]}>
					<Col xs={24}>{this.renderProgressBarTrip(groupTrip)}</Col>
					<Col xs={24} md={6}>
						<div className="IconCardOuterContainer">
							<div className="IconCard">
								<div className="IconCardHeader">
									<div className="IconCardHeaderMask" />
									<div className="IconCardType">
										<Icon name="points" />
									</div>
								</div>
								<div className="IconCardBody">
									<span className="IconCardValue">{partnerDetail?.partner?.points?.accumulatedTpv || 0}</span>
									<h3 className="IconCardDescription">{strings.commissions.accumulatedTpv}</h3>
								</div>
							</div>
						</div>
					</Col>
					<Col xs={24} md={8}>
						<table className="CommissionTpvTable">
							<thead>
								<tr>
									<th>{strings.commissions.accumulatedTpv}</th>
									<th>{strings.generic.bonus}</th>
								</tr>
							</thead>
							<tbody>
								{groupTrip?.bonuses?.map((entry: any, index: number) => {
									const reached = partnerDetail?.partner?.points?.accumulatedTpv >= entry.annual;

									const style: React.CSSProperties = {};
									if (reached) {
										style.backgroundColor = "#00b5b033";
										style.fontWeight = 600;
										style.color = "#00b5b0";
									}

									return (
										<tr key={index} style={style}>
											<td>{entry.annual}</td>
											<td>{entry.prize}</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</Col>
				</Row>
			</ContentWrapper>
		);
	}

	render() {
		const { eligible, partner, partnerDetail } = this.state;

		return (
			<div className="CommissionIncentives">
				<Helmet>
					<title>{strings.commissions.incentives}</title>
					<meta name="description" content="Description of incentives" />
				</Helmet>
				<div className="IconCards">
					<Row gutter={[20, 20]}>
						{Boolean(partner) && !eligible && (
							<Col xs={24}>
								<ContentWrapper>
									<div className="ScreenHeader">
										<div className="ScreenHeaderLeft">
											<Icon name="user" />
											<h2>{strings.commissions.eligibility}</h2>
										</div>
									</div>
									<p>
										<strong>
											{strings.commissions.actualRank}: {getSuccessLevelBadge(partnerDetail?.partner?.successLevel)}
										</strong>
									</p>
									<p className="CommissionsPartnersAttention">{strings.commissions.notEligible}</p>
								</ContentWrapper>
							</Col>
						)}
						{Boolean(partner) && (
							<React.Fragment>
								<Col xs={24}>{this.renderIncentive()}</Col>
								<Col xs={24}>{this.renderTrip()}</Col>
							</React.Fragment>
						)}
						{this.renderPartners()}
						<Col xs={24}>{this.renderMovements()}</Col>
					</Row>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language,
	countries: state.countries
});

export default connect(mapStateToProps)(Incentives);
